import { graphql, useStaticQuery } from "gatsby";
import {
  PrismicRecruitment,
  PrismicRecruitmentDataType,
  PrismicRecruitmentEdge,
} from "types/declarations";
import { useSelector } from "react-redux";
import { RootState } from "utils/store/store";
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews";

export const usePeopleData = (): PrismicRecruitmentDataType => {
  const { language } = useSelector((state: RootState) => state.i18n);
  const resultS = useStaticQuery(graphql`
    {
      allPrismicRecruitment {
        totalCount
        edges {
          node {
            lang
            _previewable
            data {
              people_image {
                alt
                url
              }
              people_header {
                raw
              }
              people_hashtags {
                hashtag {
                  raw
                }
              }
              people_description {
                raw
              }
            }
          }
        }
      }
    }
  `);
  const {data: result} = useMergePrismicPreviewData(resultS)
  return result.allPrismicRecruitment.edges
    .map((edge: PrismicRecruitmentEdge) => edge?.node)
    .filter((node: PrismicRecruitment) => node?.lang === language)
    .map((node: PrismicRecruitment) => node?.data)[0];
};
