import * as React from "react";
import { FC } from "react";
import styled from "styled-components";
import { Maybe, PrismicProductHomeDataBodyBrandCarouselSliceItem } from "types/declarations";

export interface BrandItemProps {
  item: Maybe<PrismicProductHomeDataBodyBrandCarouselSliceItem>;
  width: string;
  logoMargin: string;
}

const Image = styled.img`
  height: 50px;
`;

const BrandItem: FC<BrandItemProps> = ({ item, width, logoMargin }) => (
  <div style={{width: width, marginLeft: logoMargin}}>
    <Image src={item?.client_image?.url ?? ''} alt={item?.client_image?.alt || 'client logo'} />
  </div>
);

export default BrandItem;
