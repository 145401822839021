import React from "react";
import Breadcrumbs from "features/reports/items/breadcrumbs/Breadcrumbs";
import { useSelector } from "react-redux";
import { RootState } from "utils/store/store";
import { RichText } from "prismic-reactjs";
import { useReportPageData } from "features/reports/reportsService";
import { useLocale } from "locale/useLocale";
import './ReportsShowcase.scss';

const ReportsShowcase = (): JSX.Element => {
  const { language } = useSelector((state: RootState) => state.i18n);
  const data = useReportPageData();
  const locale = useLocale("Reports");

  return (
    <section className="reports-showcase">
      <div className="reports-showcase__top">
        {language === "pl" ? (
          <Breadcrumbs
            firstName="Dataplace"
            secondName="Wiedza i raporty"
            thirdName="Raporty branżowe"
          />
        ) : (
          <Breadcrumbs
            firstName="Dataplace"
            secondName="Resources"
            thirdName="Reports"
          />
        )}
      </div>

      <div className="reports-showcase__inner">
        <div className="reports-showcase__inner--left">
          <div>
            <div className="reports-showcase__inner--left--category">
              {locale.label}
            </div>
            <div className="reports-showcase__inner--left--title">
              <h1>{RichText.asText(data?.title?.raw || [])}</h1>
            </div>
          </div>
        </div>
        <div className="reports-showcase__inner--right">
          <img
            className="reports-showcase__inner--right-img"
            src={data?.image?.url ?? ""}
            alt={data?.image?.alt ?? ""}
          />
        </div>
      </div>
    </section>
  );
};

export default ReportsShowcase;
