import * as React from "react";
import "./ProductPurposes.scss";
import { Link } from "gatsby";
import BtnArrowRight from "assets/arrowbtn.svg";
import { RichText } from "prismic-reactjs";
import {
  ProductPageProps,
  useProductHomeData,
} from "features/product-page/product-homepage/productHomeService";
import ProductPurposesItem from "features/product-page/product-homepage/product-purposes/ProductPurposesItem";
import { FC } from "react";
import { getLinkFromTitle } from "features/product-page/productUtils";
import { useSelector } from "react-redux";
import { RootState } from "utils/store/store";


const ProductPurposes: FC<ProductPageProps> = (props: ProductPageProps) => {
  const data = useProductHomeData(props);
  const location = document.location.pathname
  const { language } = useSelector((state: RootState) => state.i18n);

  const getLink = (buttonText: string) => {
    if (buttonText.toLowerCase().includes("linkedin")) {
      return 'https://www.linkedin.com/company/placemepl/';
    } else if (buttonText.toLowerCase().includes("kontakt")) {
      return `/${language}/contact/`;
    } else if (buttonText.toLowerCase().includes("więcej")) {
      return `${language}/${getLinkFromTitle(location)}-benefits/`;
    } else {
      return `/${language}/contact/`;
    }
  }

  return (<>
    {RichText.asText(data?.usage_header?.raw || []).length > 0 && <section className="product-purposes">
      <div className="product-purposes__inner">
        <div className="product-purposes__inner--heading">
          <span>{RichText.asText(data?.usage_subheader?.raw || [])}</span>
          <p>{RichText.asText(data?.usage_header?.raw || [])}</p>
        </div>
        <section>
          {data?.usage_example?.map((item, index) => (
            <ProductPurposesItem key={index} item={item} />
          ))}
        </section>
        <div className="product-purposes__inner--readmore">
          {location !== '/monitoring/' && <Link to={getLink(RichText.asText(data?.usage_learn_more?.raw || []))}>
            {RichText.asText(data?.usage_learn_more?.raw || [])} <BtnArrowRight />
          </Link>}
        </div>
      </div>
    </section>}
    </>
  );
};

export default ProductPurposes;
