import { graphql, useStaticQuery } from "gatsby";
import {
  PrismicHomepage,
  PrismicHomepageDataType,
  PrismicHomepageEdge,
} from "types/declarations";
import { useSelector } from "react-redux";
import { RootState } from "utils/store/store";
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews";

export const useCtaData = (): PrismicHomepageDataType => {
  const { language } = useSelector((state: RootState) => state.i18n);
  const resultS = useStaticQuery(graphql`
    {
      allPrismicHomepage {
        totalCount
        edges {
          node {
            lang
            _previewable
            data {
              cta_header {
                raw
              }
              cta_button {
                raw
              }
              cta_subheader {
                raw
              }
              cta_section_cards {
                cta_section_card_title {
                  raw
                }
                cta_section_card_description {
                  raw
                }
                cta_section_card_summary {
                  raw
                }
                cta_section_card_image {
                  url
                  alt
                }
              }
            }
          }
        }
      }
    }
  `);
  const {data: result} = useMergePrismicPreviewData(resultS)
  return result.allPrismicHomepage.edges
    .map((edge: PrismicHomepageEdge) => edge.node)
    .filter((node: PrismicHomepage) => node.lang === language)
    .map((node: PrismicHomepage) => node.data)
    .find(
      // Find first non null value
      (data: PrismicHomepageDataType) =>
        data.cta_header?.raw && data.cta_button?.raw && data.cta_subheader?.raw
    );
};
