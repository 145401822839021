import * as React from 'react'
import Navbar from 'features/navbar/Navbar'
import LogoSvg from 'assets/logos/dataplaceai-color.svg'
import './Header.scss'
import { FC } from 'react'
import { Link } from 'gatsby'
import { useSelector } from 'react-redux'
import { RootState } from 'utils/store/store'
import SubNavbar from 'features/product-page/product-navbar/SubNavbar'
import { placemeUrls } from 'shared/constants'
import { usePathNamesIncludes } from 'utils/hooks/usePathNamesIncludes'

const Header: FC = () => {
  const { language } = useSelector((state: RootState) => state.i18n)
  const includesPlacemePaths = usePathNamesIncludes(placemeUrls)

  return (
    <header className="header">
      <div className="header__inner">
        <Link
          aria-label='dataplace home page'
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          to={"/" + language + "/"}>
          <LogoSvg width="190" height="30" />
        </Link>
        <Navbar />
      </div>
      {includesPlacemePaths ? <SubNavbar /> : null}
    </header>
  )
};

export default Header;
