import {
  BlogBanner,
  BlogPost,
  BlogPostDetails,
  GetBlogPostDetails,
  GetHome,
  GetSearchResults,
  SearchFilter,
  Tag,
  IPostSubscriptionForm,
  IPostSubscriptionFormBlogPost,
} from "features/blog/blogModel";
import { paginate } from "features/blog/pagination/paginationService";
import { useStaticQuery } from "gatsby";
import { graphql } from "gatsby";
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews";
import { useSelector } from "react-redux";
import {
  Maybe,
  PrismicBlog,
  PrismicBlogDataType,
  PrismicBlogEdge,
} from "types/declarations";
import { RootState } from "utils/store/store";

const compareTagsFilter = (post: BlogPost, filters: Array<SearchFilter>) => {
  const getAllIndex = filters.findIndex((tag: Tag) =>
    tag.value.toLowerCase().includes("wszystko")
  );
  if (
    (getAllIndex !== -1 && filters[getAllIndex].selected) ||
    filters.length === 0
  ) {
    return true;
  }

  let isTagPresent = false;
  post.tags.forEach((postTag: Tag) => {
    filters
      .filter((filter: SearchFilter) => filter.selected)
      .forEach((filter: SearchFilter) => {
        if (
          filter.value.toLowerCase().replace(".", "").replace(" ", "_") ===
          postTag.value.toLowerCase()
        ) {
          isTagPresent = true;
        }
      });
  });

  return isTagPresent;
};

const getHomeBlogPosts = ({
  filters,
  pagination,
  allBlogPosts,
}: GetHome): Array<BlogPost> => {
  const results = paginate(
    allBlogPosts.filter((post: BlogPost) => compareTagsFilter(post, filters)),
    pagination
  );
  return results;
};

const getSearchResults = ({
  allBlogPosts,
  query,
  filters,
  pagination,
}: GetSearchResults): Array<BlogPost> => {
  const results = paginate(
    allBlogPosts
      .filter((post: BlogPost) => compareTagsFilter(post, filters))
      .filter((post: BlogPost) =>
        post.title.toLowerCase().includes(query.toLowerCase())
      ),
    pagination
  );
  return results;
};

const getHomeBanners = (): Array<BlogBanner> => {
  return [];
};

const getBlogPostDetails = ({
  allBlogPosts,
  link,
}: GetBlogPostDetails): Maybe<BlogPostDetails> => {
  if (allBlogPosts) {
    const index = allBlogPosts.findIndex(
      (post: BlogPostDetails) => post.link === link
    );
    if (index !== -1) {
      return allBlogPosts[index];
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export const postSubscriptionForm = (
  props: IPostSubscriptionForm,
  lang: string,
  setInfoModalText: React.Dispatch<React.SetStateAction<string>>,
  setModalDisplay: React.Dispatch<React.SetStateAction<boolean>>
): void => {
  const getUrlByLanguage = (lang: string) => {
    switch (lang) {
      case "pl":
        return "https://api.hsforms.com/submissions/v3/integration/submit/20363741/51e3360b-a6f3-4908-b92b-f3584d643fc7";
      case "en-pl":
        return "https://api.hsforms.com/submissions/v3/integration/submit/20363741/cf6825bc-4601-4a7e-a4d7-2cd4bf6574a3";
      default:
        return "https://api.hsforms.com/submissions/v3/integration/submit/20363741/51e3360b-a6f3-4908-b92b-f3584d643fc7";
    }
  };

  const xhr = new XMLHttpRequest();
  const url = getUrlByLanguage(lang);

  // Example request JSON:
  const data = {
    fields: [
      {
        name: "email",
        value: props.email,
      },
      {
        name: "client_industry",
        value: props.industry,
      },
    ],

    legalConsentOptions: {
      // Include this object when GDPR options are enabled
      consent: {
        consentToProcess: true,
        text:
          "I agree to allow Example Company to store and process my personal data.",
        communications: [
          {
            value: true,
            subscriptionTypeId: 999,
            text:
              "I agree to receive marketing communications from Example Company.",
          },
        ],
      },
    },
  };

  const final_data = JSON.stringify(data);

  xhr.open("POST", url);
  // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
  xhr.setRequestHeader("Content-Type", "application/json");

  xhr.onreadystatechange = function () {
    if (xhr.readyState == 4 && xhr.status === 200) {
      if (lang === "pl") {
        setModalDisplay(true);
        setInfoModalText("Dziękujęmy za subskrypcję!");
      } else if (lang === "en-pl") {
        setModalDisplay(true);
        setInfoModalText("Thank you for subscription!");
      }
    } else if (
      xhr.readyState == 4 &&
      JSON.parse(xhr.responseText).errors[0].errorType === "INVALID_EMAIL"
    ) {
      if (lang === "pl") {
        setModalDisplay(true);
        setInfoModalText("Podany e-mail jest nieprawidłowy");
      } else if (lang === "en-pl") {
        setModalDisplay(true);
        setInfoModalText("The given e-mail address is invalid");
      }
    }
  };

  // Sends the request

  xhr.send(final_data);
};

export const postSubscriptionFormBlogPost = (
  props: IPostSubscriptionFormBlogPost,
  lang: string,
  setInfoModalText: React.Dispatch<React.SetStateAction<string>>,
  setModalDisplay: React.Dispatch<React.SetStateAction<boolean>>
): void => {
  const getUrlByLanguage = (lang: string) => {
    switch (lang) {
      case "pl":
        return "https://api.hsforms.com/submissions/v3/integration/submit/20363741/3ef99172-bba6-4a6b-a451-9e3c2165834c";
      case "en-pl":
        return "https://api.hsforms.com/submissions/v3/integration/submit/20363741/9e757fbb-ab75-4d98-b372-efe6c54df2e8";
      default:
        return "https://api.hsforms.com/submissions/v3/integration/submit/20363741/3ef99172-bba6-4a6b-a451-9e3c2165834c";
    }
  };

  const xhr = new XMLHttpRequest();
  const url = getUrlByLanguage(lang);

  // Example request JSON:
  const data = {
    fields: [
      {
        name: "email",
        value: props.email,
      },
    ],

    legalConsentOptions: {
      // Include this object when GDPR options are enabled
      consent: {
        consentToProcess: true,
        text:
          "I agree to allow Example Company to store and process my personal data.",
        communications: [
          {
            value: true,
            subscriptionTypeId: 999,
            text:
              "I agree to receive marketing communications from Example Company.",
          },
        ],
      },
    },
  };

  const final_data = JSON.stringify(data);

  xhr.open("POST", url);
  // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
  xhr.setRequestHeader("Content-Type", "application/json");

  xhr.onreadystatechange = function () {
    if (xhr.readyState == 4 && xhr.status === 200) {
      if (lang === "pl") {
        setInfoModalText("E-book został wysłany na podany adres e-mail");
        setModalDisplay(true);
      } else {
        setInfoModalText("Thank you for subscription!");
        setModalDisplay(true);
      }
    } else if (
      xhr.readyState == 4 &&
      JSON.parse(xhr.responseText).errors[0].errorType === "INVALID_EMAIL"
    ) {
      if (lang === "pl") {
        setInfoModalText("Podany e-mail jest nieprawidłowy");
        setModalDisplay(true);
      } else {
        setInfoModalText("The given e-mail address is invalid");
        setModalDisplay(true);
      }
    }
  };

  // Sends the request

  xhr.send(final_data);
};

export default {
  getHomeBlogPosts,
  getSearchResults,
  getHomeBanners,
  getBlogPostDetails,
};

export const useBlogData = (): PrismicBlogDataType => {
  const { language } = useSelector((state: RootState) => state.i18n);
  const resultS = useStaticQuery(graphql`
    {
      allPrismicBlog {
        edges {
          node {
            lang
            _previewable
            data {
              seo_title {
                text
              }
              seo_description {
                text
              }
              seo_canonical_url {
                url
              }
            }
          }
        }
      }
    }
  `);
  const {data: result} = useMergePrismicPreviewData(resultS)
  return result.allPrismicBlog.edges
    .map((edge: PrismicBlogEdge) => edge.node)
    .filter((node: PrismicBlog) => node.lang === language)
    .map((node: PrismicBlog) => node.data)[0];
};
