import { RichText } from "prismic-reactjs";
import * as React from "react";
import { useEffect, useState } from "react";
import { colors, productColors } from "shared/constants";
import styled, { css } from "styled-components";
import { PrismicProductHomeDataBodyAccessDataSlice, PrismicProductHomeDataBodyAccessDataSliceItems } from "types/declarations";
// import Button from "ui/ButtonComponent/Button";
import { useMediaQuery } from "utils/hooks/useMediaQuery";


const StyledSection = styled.section`
  background-color: ${productColors.white};
`;

const AccessDataWrapper = styled.div(() => {
  const { mobile, tablet } = useMediaQuery()

  return css`
    padding: 60px 84px;
    max-width: 1440px;
    margin: 0 auto;

    ${tablet && 'padding: 40px 25px'}
    ${mobile && 'padding: 40px 15px'}
  `
});

const Container = styled.div(() => {
  const { mobile, tablet } = useMediaQuery()

  return css`
    display: flex;
    flex-direction: ${mobile ? 'column' : 'row'};
    justify-content: space-between;
    ${tablet && css`margin-bottom: 30px;`}
  `
});

const Title = styled.h1(() => {
  const { tablet, mobile } = useMediaQuery()

  return css`
    font-size: 40px;
    line-height: 52px;
    max-width: 60%;
    color: ${colors.darkBlue};

    ${tablet && css`
      font-size: 37px;
      max-width: 100%;
    `}

    ${mobile && css`
      font-size: 30px;
      line-height: 40px;
      max-width: 100%;
    `}
  `
});

const Subtitle = styled.h2`
  font-size: 22px;
  line-height: 30px;
  font-weight: 400;
  color: ${colors.darkBlue};
`;

const ImageContainer = styled.div(() => {
  const { mobile } = useMediaQuery()

  return css`
    width: ${mobile ? '100%' : '50%'};
    display: flex;
    justify-content: ${mobile ? 'center' : 'flex-end'};
    align-items: center;
    margin-bottom: ${mobile ? '20px' : '0'};
  `
});

const TileTitle = styled.h3`
  font-size: 20px;
  line-height: 25px;
  margin-bottom: 5px;
  color: ${colors.darkBlue};
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const SlicesContainer = styled.div(() => {
  const { mobile } = useMediaQuery()

  return css`
    width: ${mobile ? '100%' : '50%'};
    position: relative;
    height: fit-content;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -2px;
      height: 100%;
      width: 4px;
      background-color: rgb(225, 221, 235);
      border-radius: 2px;
      z-index: 1;
    }
  `
});

interface ListElementProps {
  active?: boolean
}

const ListElement = styled.div<ListElementProps>(({ active }) => {
  const { mobile } = useMediaQuery();

  return css`
    display: flex;
    flex-direction: ${mobile ? 'column' : 'row'};
    align-items: ${mobile ? 'flex-start' : 'center'};
    padding: ${mobile ? '15px 5px 10px 5px' : '14px'};
    padding-right: ${mobile ? '5px' : '34px'};
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    cursor: pointer;
    position: relative;

    ${active && css`
      background-color: rgba(173, 170, 227, 0.2);
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: -2px;
        height: 100%;
        width: 4px;
        background-color: ${productColors.violet.dark};
        border-radius: 2px;
        z-index: 1;
      }
    `}
  `
});

const Description = styled.p`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 5px;
  color: ${colors.darkBlue};
  padding-bottom: 15px;
`;

const Icon = styled.img(() => {
  const { mobile } = useMediaQuery();

  return css`
    margin-left: ${mobile ? '10px' : '6px'};
    margin-right: ${mobile ? '10px' : '12px'};
    margin-bottom: ${mobile ? '10px' : '0'};
  `
});

// const StyledButton = styled(Button)(() => {
//   const { mobile } = useMediaQuery()

//   return css`
//     margin-top: ${mobile ? '30px' : '10px'};
//     font-size: 1rem;
//     max-height: 2.5rem;
//     ${mobile && css`width: '100%';`}
//   `
// });

const IconAndText = styled.div`
  display: flex;
`

const AccessData: React.FC<{ slice: PrismicProductHomeDataBodyAccessDataSlice }> = ({ slice }) => {
  const { mobile } = useMediaQuery();
  const [selectedItem, setSelectedItem] = useState<PrismicProductHomeDataBodyAccessDataSliceItems | null>(null);
  const { primary, items } = slice;

  useEffect(() => {
    if (items && items.length > 0) {
      setSelectedItem(items[0]);
    }
  }, [slice]);

  return (
    <StyledSection>
      <AccessDataWrapper>
        <Title>{RichText.asText(primary?.title?.richText)}</Title>
        <Subtitle>{RichText.asText(primary?.subtitle?.richText)}</Subtitle>
        <Container>
          <SlicesContainer>
            {items?.map((item, index) => (
              <ListElement key={index} onClick={() => setSelectedItem(item)} active={selectedItem === item}>
                {mobile && selectedItem == item 
                  ? (
                    <ImageContainer>
                      <Image 
                        src={item?.tile_image?.url || ''} 
                        alt={item?.tile_image?.alt || ''} 
                      />
                    </ImageContainer>
                  )
                  : null
                }
                <IconAndText>
                  <Icon src={selectedItem === item ? item?.tile_icon_active?.url || '' : item?.tile_icon?.url || ''} />
                  <div>
                    <TileTitle>
                      {RichText.asText(item?.title_title?.richText)}
                    </TileTitle>
                    {selectedItem === item 
                      ? (
                        <Description>{RichText.asText(item?.tile_description?.richText)}</Description> 
                      )
                      : null
                    }
                  </div>
                </IconAndText>
              </ListElement>
            ))}
          </SlicesContainer>
          {!mobile && selectedItem 
            ? (
              <ImageContainer>
                <Image 
                  src={selectedItem?.tile_image?.url || ''} 
                  alt={selectedItem?.tile_image?.alt || ''} 
                />
              </ImageContainer>
            )
            : null
          }
        </Container>
        {/* <a href={primary?.button_link?.raw.url}>
          <StyledButton reverse minWidth={mobile ? '100%' : 'none'}>
            {RichText.asText(primary?.button_text?.raw)}
          </StyledButton>
        </a> */}
      </AccessDataWrapper>
    </StyledSection>
  );
}

export default AccessData;
