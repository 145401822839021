import { GetRecruitmentPosts, RecruitmentPost } from './careerModel';
import { Maybe, PrismicRecruitment, PrismicRecruitmentDataType, PrismicRecruitmentEdge } from "types/declarations";
import { useStaticQuery } from 'gatsby';
import { graphql } from 'gatsby';
import { useSelector } from 'react-redux';
import { RootState } from 'utils/store/store';
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';


const getRecruitmentPost = ({
  allRecruitmentPosts,
  link,
}: GetRecruitmentPosts): Maybe<RecruitmentPost> => {
  if (allRecruitmentPosts) {
    const index = allRecruitmentPosts.findIndex(
      (post: RecruitmentPost) => post?.link === link
    );
    if (index !== -1) {
      return allRecruitmentPosts[index];
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export default {
  getRecruitmentPost
};

export const useCareerData = (): PrismicRecruitmentDataType => {
  const { language } = useSelector((state: RootState) => state.i18n);
  const resultS = useStaticQuery(graphql`
    {
      allPrismicRecruitment {
        edges {
          node {
            lang
            _previewable
            data {
              seo_title {
                text
              }
              seo_description {
                text
              }
              seo_canonical_url {
                url
              }
            }
          }
        }
      }
    }
  `);

  const {data: result} = useMergePrismicPreviewData(resultS)
  
  return result.allPrismicRecruitment.edges
    .map((edge: PrismicRecruitmentEdge) => edge?.node)
    .filter((node: PrismicRecruitment) => node?.lang === language)
    .map((node: PrismicRecruitment) => node?.data)[0];
};