import { navigate } from "gatsby";
import { RichText, RichTextBlock } from "prismic-reactjs";
import React, { useState } from "react";
import {useMediaQuery} from "utils/hooks/useMediaQuery";
import { useSelector } from "react-redux";
import Button from "ui/button/Button";
import HubspotFormModal from "ui/HubspotFormModal/HubspotFormModal";
import { RootState } from "utils/store/store";

interface IShowcase {
  title: RichTextBlock[] | undefined;
  label: RichTextBlock[] | undefined;
  button: RichTextBlock[] | undefined;
}

export const Showcase = ({ title, label, button }: IShowcase): JSX.Element => {
  const { language } = useSelector((state: RootState) => state.i18n);
  const {mobile} = useMediaQuery()
  
  const [showMeeting, setShowMeeting] = useState(false);

   // handlers for hubspot meeting modal
   const handleShowMeeting = () => {
    if (!showMeeting && !mobile) {
      setShowMeeting(true);
    }
    if (!showMeeting && mobile){
      navigate(`/${language}/contact/`);
    }
  };

  const handleCloseMeeting = () => {
    if (showMeeting) {
      setShowMeeting(false);
    }
  };

  return (
    <div className="showcase">
      <div className="showcase__left">
        <div className="showcase__left-title">
          <RichText render={title} />
        </div>
      </div>
      <div className="showcase__right">
        <div className="label">
          <RichText render={label} />
        </div>


        {language !== 'pl' && (
          <Button as="button" minWidth={180} arrowRight handleButtonClick={handleShowMeeting}>
            <RichText render={button} />
          </Button>
        )}

        {language === 'pl' && (
          <Button minWidth={180} arrowRight to={"/" + language + "/contact/"}>
            <RichText render={button} />
          </Button>
        )}
                
        {showMeeting && (
          <HubspotFormModal
            closeMeeting={handleCloseMeeting}
            left="25vw"
            show={showMeeting}
            top="8vh"
          />
        )}
      </div>
    </div>
  );
}
