import * as React from "react";
import "./ProductFuncInformation.scss";
import { FC } from "react";

export interface ProductFuncInformationProps {
  bgColor?: string;
  label?: string;
  text?: string;
}

const ProductFuncInformation: FC<ProductFuncInformationProps> = ({
  bgColor,
  label,
  text,
}: ProductFuncInformationProps) => {
  return (
    <section className="product-func-information" style={bgColor ? {backgroundColor: bgColor} : {backgroundColor: bgColor}}>
      <div className="product-func-information__inner">
        <div className="product-func-information__inner--heading">
          <span>{label}</span>
          <p>{text}</p>
        </div>
      </div>
    </section>
  );
};

export default ProductFuncInformation;

