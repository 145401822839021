import * as React from 'react';
import './Clients.scss';
import { RichText } from 'prismic-reactjs';
import { useClientsData } from 'features/clients/clientsService';
import ClientsGrid from 'features/clients/ClientsGrid';
import { useMediaQuery } from 'utils/hooks/useMediaQuery';
import Button from 'ui/ButtonComponent/Button';
import styled, { css } from 'styled-components';

const ClientsDiv = styled.div(() => {
  const { tablet, laptop, desktop } = useMediaQuery();
  return css`
    padding-bottom: 2rem;
    ${tablet &&
    css`
      padding-bottom: 3rem;
    `}
    ${(laptop || desktop) &&
    css`
      padding-bottom: 4rem;
    `}
  `;
});

const ClientsInnerDescriptionDiv = styled.div(() => {
  const { laptop, desktop } = useMediaQuery();
  return css`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1130px;
    padding-top: 1.25rem;
    margin: 0 auto;
    p {
      font-size: 1.5rem;
      text-align: center;
      color: #001c61;
      line-height: 3.25rem;
      font-weight: 700;
    }
    ${(laptop || desktop) &&
    css`
      padding-top: 0;
    `}
  `;
});

const ButtonDiv = styled.div(() => {
  const { laptop, desktop } = useMediaQuery();
  return css`
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;
    ${(laptop || desktop) &&
    css`
      margin-top: 2rem;
    `}
  `;
});

function Clients({ noDescription }: { noDescription?: boolean }) {
  const data = useClientsData();
  const { mobile, tablet } = useMediaQuery();

  return (
    <section className='clients'>
      <ClientsDiv>
        <div className='clients__inner'>
          <ClientsInnerDescriptionDiv>
            {!noDescription && (
              <p>{RichText.asText(data?.clients_header?.raw || [])}</p>
            )}
          </ClientsInnerDescriptionDiv>
          <ClientsGrid data={data} />
          <ButtonDiv>
            <Button
              to={RichText.asText(data.client_carousel_cta_button_url?.raw)}
              as='navLink'
              reverse={true}
              arrowRight={true}
              backgroundCol='transparent'
              border={mobile || tablet ? '1px solid transparent' : undefined}>
              {RichText.asText(data.client_carousel_cta_button_text?.raw)}
            </Button>
          </ButtonDiv>
        </div>
      </ClientsDiv>
    </section>
  );
}

export default Clients;
