import * as React from 'react';
import './Credentials.scss';
import { RichText } from 'prismic-reactjs';
import { useCredentials, useHomePage } from './credentialsService';
import Button from 'ui/button/Button';
import { useRef } from 'react';
import BtnArrowRight from 'assets/arrowbtn.svg';
import { useSelector } from 'react-redux';
import { RootState } from 'utils/store/store';
import styled, { css } from 'styled-components';
import { useMediaQuery } from 'utils/hooks/useMediaQuery';
import { PrismicCredentialsDataType } from 'types/declarations';

const ClientImageStyled = styled.img(() => {
  const { mobile } = useMediaQuery();
  return css`
    border-radius: 50%;
    width: 12rem;
    height: 12rem;

    ${mobile &&
    css`
      margin-right: 0;
      width: 7rem;
      height: 7rem;
    `}
  `;
});

const ClientImageWrapper = styled.div(() => {
  const { mobile, tablet } = useMediaQuery();
  return css`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    p {
      font-weight: normal;
      font-size: 12px;
      line-height: 23px;
      text-align: center;
      white-space: pre;
    }

    ${!mobile &&
    css`
      gap: 2rem;
      flex-direction: column;
      width: 35%;
      p {
        font-size: 20px;
        line-height: 33px;
        margin-bottom: 25px;
      }
    `}

    ${tablet &&
    css`
      p {
        font-size: 15px;
      }
    `}

    ${mobile &&
    css`
      justify-content: space-between;
    `}
  `;
});

const Box = styled.div(() => {
  const { mobile, tablet } = useMediaQuery();
  return css`
    background: #fff;
    box-shadow: 0px 0px 35px 0px rgba(36, 33, 84, 0.12);
    min-width: ${tablet ? 668 : 900}px;
    width: 90%;
    border-radius: 8px;
    display: flex;
    margin: 30px ${mobile || tablet ? '50px' : '5%'};
    max-height: ${!mobile ? '500px' : 'none'};
    padding: ${mobile || tablet ? 1.5 : 3}rem;
    gap: 1rem;
    scroll-snap-align: center;

    ${mobile &&
    css`
      min-width: 320px;
      flex-direction: column;
    `}
  `;
});

const Description = styled.div(() => {
  const { mobile, tablet } = useMediaQuery();
  return css`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
    width: ${mobile ? 100 : 65}%;
    padding: ${!mobile ? '25px 20px' : 0};
    .title {
      font-style: italic;
      font-weight: 600;
      font-size: ${mobile ? 15 : tablet ? 16 : 20}px;
      line-height: 29px;
      color: #001d62;
      white-space: pre-wrap;
    }
    .box-bottom {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      justify-content: center;
      align-items: center;
      a.button {
        color: #fff;
      }
      .client {
        img {
          max-height: 50px;
          max-width: 150px;
        }
      }
      a {
        width: fit-content;
        svg path {
          stroke: white;
        }
      }
    }
  `;
});

const ClientCompanyLogo = styled.img(() => {
  const { mobile } = useMediaQuery();
  return css`
    object-fit: contain;
    margin-right: 0;
    ${!mobile &&
    css`
      width: 15rem;
      height: 6rem;
    `}
  `;
});

const CredentialsInner = styled.div(() => {
  const { mobile, tablet } = useMediaQuery();
  return css`
    max-width: ${mobile || tablet ? 768 : 1000}px;
  `;
});

const CarouselControl = styled.div(() => {
  const { mobile, tablet } = useMediaQuery();
  return css`
    width: ${mobile || tablet ? 70 : 70}px !important;
  `;
});

const Credentials = (): JSX.Element => {
  // variables
  const data = useCredentials();
  const general_data = useHomePage();
  const scrollContainer = useRef() as React.MutableRefObject<HTMLDivElement>;
  const { language } = useSelector((state: RootState) => state.i18n);
  const [currentCardIndex, setCurrentCardIndex] = React.useState(0);
  const { mobile, tablet } = useMediaQuery();

  // functions
  const scrollToIndex = (index: number) => {
    if (currentCardIndex === index) {
      return;
    }
    scrollContainer.current.scrollLeft =
      index * scrollContainer.current.clientWidth;
  };

  const scrollLeft = () => {
    if (currentCardIndex === 0) {
      return;
    }
    scrollToIndex(currentCardIndex - 1);
  };

  const scrollRight = () => {
    if (!data || currentCardIndex === data?.length - 1) {
      return;
    }
    scrollToIndex(currentCardIndex + 1);
  };

  const CompanyLogo = ({ item }: { item: PrismicCredentialsDataType }) => (
    <ClientCompanyLogo
      src={item?.client_logo?.url ?? ''}
      alt={item?.client_logo?.alt ?? ''}
    />
  );

  return (
    <section className='credentials'>
      <CredentialsInner className='credentials__inner'>
        <h4>{RichText.asText(general_data?.credentials_main_title?.raw)}</h4>
        {data?.length > 0 && (
          <>
            <div className='carousel-container'>
              {!(mobile || tablet) && (
                <CarouselControl
                  onClick={scrollLeft}
                  className='carousel-control__left'>
                  <BtnArrowRight
                    style={{
                      display: currentCardIndex === 0 ? 'none' : 'block',
                    }}
                  />
                </CarouselControl>
              )}
              <div
                className='box-container'
                ref={scrollContainer}
                onScroll={() => {
                  const newIndex = Math.round(
                    scrollContainer.current.scrollLeft /
                      scrollContainer.current.clientWidth
                  );
                  if (newIndex === currentCardIndex) {
                    return;
                  }
                  setCurrentCardIndex(newIndex);
                }}>
                {data?.map((item, index) => (
                  <Box key={`${item.title}-${index}`}>
                    <ClientImageWrapper>
                      <ClientImageStyled
                        src={item?.image?.url ?? ''}
                        alt={item?.image?.alt ?? ''}
                      />
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <p>{RichText.asText(item?.client_name?.raw)}</p>
                        {mobile && <CompanyLogo item={item} />}
                      </div>
                    </ClientImageWrapper>
                    <Description>
                      <h5 className='title'>
                        {RichText.asText(item?.title?.raw || [])}
                      </h5>
                      <div className='box-bottom'>
                        {!mobile && <CompanyLogo item={item} />}
                        {item?.link?.text && (
                          <Button
                            arrowRight
                            filled
                            to={'/' + language + item?.link?.text ?? '' + '/'}
                            className='button'>
                            {RichText.asText(
                              general_data?.credentials_main_button?.raw
                            )}
                          </Button>
                        )}
                      </div>
                    </Description>
                  </Box>
                ))}
              </div>
              {!(mobile || tablet) && (
                <CarouselControl
                  onClick={scrollRight}
                  className='carousel-control__right'>
                  <BtnArrowRight
                    style={{
                      display:
                        currentCardIndex === data?.length - 1
                          ? 'none'
                          : 'block',
                    }}
                  />
                </CarouselControl>
              )}
            </div>
            <div className='carousel-indicators'>
              {data?.map((_, index) => (
                <div
                  key={index}
                  className={`indicator_wrapper ${
                    index === currentCardIndex ? 'active' : ''
                  }`}
                  onClick={() => scrollToIndex(index)}>
                  <div
                    className={`indicator ${
                      index === currentCardIndex ? 'active' : ''
                    }`}
                  />
                </div>
              ))}
            </div>
          </>
        )}
      </CredentialsInner>
    </section>
  );
};

export default Credentials;

