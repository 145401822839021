import * as React from "react";
import "./Button.scss";
import { FC } from "react";
import BtnArrowRight from "assets/arrowbtn.svg";
import { Link } from "gatsby";

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  filled?: boolean;
  arrowRight?: boolean;
  arrowDown?: boolean;
  children: React.ReactNode;
  minWidth?: number | string;
  as?: "button";
  className?: string;
  handleButtonClick?: () => void;
  linkStyle?: "noFill" | "fill";
  margin?: string;
  maxWidth?: number;
  to?: string;
  disabled?: boolean;
}

const Button: FC<ButtonProps> = ({
  filled,
  arrowRight,
  arrowDown,
  children,
  minWidth,
  as,
  linkStyle,
  margin,
  handleButtonClick,
  className,
  maxWidth,
  to, 
  disabled,
}: ButtonProps) => (
  <>
    {as !== "button" && (
      <Link
        className={filled ? "btn filled " + className : "btn " + className}
        to={to || '/'}
        style={{ minWidth: minWidth ? minWidth : "" }}>
        {children}
        {arrowRight && <BtnArrowRight className="btn__arrowRight" />}
      </Link>
    )}
    {as === "button" && (
      <button
        disabled={disabled}
        className={
          (linkStyle
            ? linkStyle === "noFill"
              ? "link-no-fill "
              : "link-fill "
            : filled
            ? "btn filled "
            : "btn ") + className
        }
        onClick={handleButtonClick}
        style={{ minWidth: minWidth ? minWidth : "", margin: margin ||'0 auto', cursor: 'pointer', maxWidth: maxWidth || 'auto'}}>
        {children}
        {arrowRight && <BtnArrowRight className="btn__arrowRight" />}
        {arrowDown && <BtnArrowRight className="btn__arrowDown" />}
      </button>
    )}
  </>
);

export default Button;
