import React from "react";
import "./Breadcrumbs.scss";
import BtnArrowRight from "assets/arrowbtn.svg";
import { Link } from "gatsby";
import { FC } from "react";
import { useSelector } from "react-redux";
import { RootState } from "utils/store/store";

export interface BreadcrumbsProps {
  firstName?: string;
  secondName?: string;
  thirdName?: string;
  firstLink?: string;
  secondLink?: string;
  thirdLink?: string;
}

const Breadcrumbs: FC<BreadcrumbsProps> = ({
  firstName,
  secondName,
  thirdName,
  secondLink,
}: BreadcrumbsProps) => {
  const { language } = useSelector((state: RootState) => state.i18n);

  return (
    <div className="breadcrumbs">
      <div className="breadcrumbs__element">
        <p>{firstName}</p>
      </div>
      {secondName ? (
        <div className="breadcrumbs__element">
          <BtnArrowRight />
          <Link to={"/" + language + '/' + secondLink}>
            <p>{secondName}</p>
          </Link>
        </div>
      ) : (
        ""
      )}
      {thirdName ? (
        <div className="breadcrumbs__element">
          <BtnArrowRight />
          <p>{thirdName}</p>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
export default Breadcrumbs;
