import React, { useState } from 'react'
import { PrismicProductHomeDataBodyPricingFormSlice } from './PricingFormTypes'
import { RichText } from 'prismic-reactjs'
import styled, { css } from 'styled-components'
import { colors, productColors } from 'shared/constants'
import SharedButton from 'ui/SharedButton/SharedButton'
import { useMediaQuery } from 'utils/hooks/useMediaQuery'
import Dropdown from 'components/shared-components/dropdown/Dropdown'

const Container = styled.div(() => {
  const { tablet, mobile } = useMediaQuery()

  return css`
    height: 100%;
    background-color: ${productColors.violet.light};
    padding: ${tablet ? '2rem 1.5rem' : mobile ? '1.5rem 1rem ' : '2.6rem'};
  `
})

const Title = styled.h1(() => css`
  line-height: 46px;
  font-size: 33px;
  margin-bottom: 2.6rem;
`) 

const Description = styled.p(() => css`
  line-height: 26px;
  font-size: 18px;
  margin-bottom: 3rem;
  font-weight: 400;
  color: ${colors.black};
`)

const DropdownsWrapper = styled.div(() => css`
  margin: 3rem 0;
`)

const PricingInfoSide: React.FC<{ slice: PrismicProductHomeDataBodyPricingFormSlice }> = ({ slice }) => {
  const [selectedItem, setSelectedItem] = useState<number | null>(null)
  const handleClickDropdown = (index: number) => setSelectedItem(index === selectedItem ? null : index)

  return (
    <Container>
      <Title>{RichText.asText(slice?.primary?.title?.richText)}</Title>
      <Description>{RichText.asText(slice?.primary?.description?.richText)}</Description>
      <SharedButton 
        buttonProps={{ 
          $bgColor: productColors.violet.dark, 
          $border: '1px solid transparent', 
          $width: 'fit-content'
        }}
        redirectsTo='https://app.dataplace.ai/auth/sign-up'>
        {RichText.asText(slice?.primary?.button_text?.richText)}
      </SharedButton>
      <DropdownsWrapper>
        {slice?.items?.map((item, index) => (
          <Dropdown selectedItem={selectedItem} handleClickDropdown={handleClickDropdown} index={index} item={item} />
        ))}
      </DropdownsWrapper>
    </Container>
  )
}

export default PricingInfoSide
