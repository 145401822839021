import * as React from "react";
import "./CaseStudies.scss";
import CaseStudyBox from "./CaseStudyBox";
import { RichText } from "prismic-reactjs";
import {
  PrismicHomepageDataType,
  PrismicProductBenefitsDataType,
  PrismicProductFunctionalityDataType,
  PrismicReportDataType,
} from "types/declarations";

const CaseStudies = ({
  data,
}: {
  data:
    | PrismicHomepageDataType
    | PrismicReportDataType
    | PrismicProductFunctionalityDataType
    | PrismicProductBenefitsDataType;
}): JSX.Element => {
  const studies = data?.case_studies;

  return (
    <>
      {studies && studies?.length > 0 ? (
        <section className="case-studies">
          <div className="case-studies__inner">
            <div className="case-studies__inner--subheader">
              <h3>{RichText.asText(data?.case_studies_text?.raw || [])}</h3>
            </div>
            <div className="case-studies__inner--grid">
              {studies?.map((value, index) => (
                <CaseStudyBox key={index} study={value} />
              ))}
            </div>
          </div>
        </section>
      ) : null}
    </>
  );
};

export default CaseStudies;
