import { graphql, useStaticQuery } from "gatsby";
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews";
import { useSelector } from "react-redux";
import {
  PrismicFaq,
  PrismicFaqDataType,
  PrismicFaqEdge,
} from "types/declarations";
import { RootState } from "utils/store/store";

export const useFaqData = (): PrismicFaqDataType => {
  const { language } = useSelector((state: RootState) => state.i18n);
  const resultS = useStaticQuery(graphql`
    {
      allPrismicFaq {
        totalCount
        edges {
          node {
            lang
            _previewable
            data {
              faq_header {
                raw
              }
              faq_point {
                header {
                  raw
                }
                text {
                  raw
                }
              }
            }
          }
        }
      }
    }
  `);
  const {data: result} = useMergePrismicPreviewData(resultS)
  return result.allPrismicFaq.edges
    .map((edge: PrismicFaqEdge) => edge.node)
    .filter((node: PrismicFaq) => node.lang === language)
    .map((node: PrismicFaq) => node.data)[0];
};