import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { IHubspotModalProps } from './@types/IHubspotModalProps.props'
import { IModalProps } from './@types/IModalProps.props'
import { HUBSPOT_MEETING_URL } from 'shared/constants'
import CloseBtn from 'components/shared-components/buttons/CloseBtn'

const Modal = styled.div<IModalProps>(({ show, top, left }) => `
  width: 750px;
  min-height: 700px;
  background: white;
  position: fixed;
  top: ${top || '5vh'};
  left: ${left || '25vw'};
  z-index: ${show ? '3000' : '-3000'};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  display: flex;
  padding-top: 1rem;

  @media (max-width: 850px){
    width:700px;
    left: 5vw;
  }
`)

const StyledIframe = styled.iframe`
  width: 100%;
  flex: 1;
`

export function HubspotMeetingModal({show, closeMeeting, top, left }: IHubspotModalProps): JSX.Element {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false)

  useEffect(() => {
    const loadScript = () => {
      const script = document.createElement('script')
      script.src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js'
      script.async = true
      script.type = 'text/javascript'
      document.body.appendChild(script)
      script.onload = () => setIsScriptLoaded(true)
    }
  
    if (show && !isScriptLoaded) loadScript()
  
    return () => {
      const script = document.querySelector(`script[src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"]`)

      if (!script) return
      document.body.removeChild(script)
      setIsScriptLoaded(false)
    }
  }, [show])

  return (
    <>
      {isScriptLoaded
        ? (
          <Modal left={left} show={show} top={top}>
            <CloseBtn handleClick={closeMeeting} />
            <StyledIframe
              src={HUBSPOT_MEETING_URL}
              style={{ border: 'none' }}
              title='hubspot'
              loading='lazy'
            />
          </Modal>
        )
        : null}
    </>
  )
}

export default HubspotMeetingModal
