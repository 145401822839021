import * as React from "react";
import "./Clients.scss";
import { FC } from "react";
import { Maybe, PrismicClientsDataClientImages } from "types/declarations";

export interface ClientsGridItemProps {
  item: Maybe<PrismicClientsDataClientImages>;
  width: string;
  logoMargin: string;
}

const ClientsGridItem: FC<ClientsGridItemProps> = ({
  item,
  width,
  logoMargin,
}: ClientsGridItemProps) => {
  return (
    <div className="clients-item" style={{width: width, marginLeft: logoMargin}}>
      <img
        className="clients-image"
        src={item?.client_image?.url ?? ""} alt="client logo" 
      />
    </div>
  );
};

export default ClientsGridItem;
