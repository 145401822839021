import { navigate } from 'gatsby'
import { RichText, RichTextBlock } from 'prismic-reactjs'
import React from 'react'

interface IPriceListBanner {
  title: RichTextBlock[] | undefined;
  description: RichTextBlock[] | undefined;
  button: RichTextBlock[] | undefined;
  image: {
    url: string;
    alt: string;
  };
}

export const PriceListBanner = ({title, description, button, image}: IPriceListBanner): JSX.Element => {
  return (
    <div className="pricelist-container__banner">
      <div className="pricelist-container__banner--inner">
        <div className="pricelist-container__banner--inner-left">
          <div className="pricelist-container__banner--inner-left___title"><RichText render={title} /></div>
          <div className="pricelist-container__banner--inner-left___description"><RichText render={description} /></div>
          <div className="pricelist-container__banner--inner-left___button" onClick={() => navigate('https://knowledge.dataplace.ai/knowledge')}><RichText render={button} /></div>
        </div>
        <div className="pricelist-container__banner--inner-right">
          <img src={image?.url} alt={image?.alt + ''} />
        </div>
      </div>
    </div>
  )
}
