import React, { MutableRefObject, useEffect, useRef } from "react";
import "./CareerPage.scss";
import CareerLayout from "containers/career-layout/CareerLayout";
import Seo from "utils/seo/Seo";
import Showcase from "./items/showcase/Showcase";
import Company from "./items/company/Company";
import People from "./items/people/People";
import Offers from "./items/offers/Offers";
import CvBanner from "./items/cv-banner/CvBanner";
import About from "./items/about/About";
// import Newsletter from "./items/newsletter/Newsletter";
import { useCareerData } from "./careerService";
import { useDispatch } from "react-redux";
import { Language, setLanguage } from "utils/i18n/i18nSlice";
import { checkForPopup } from "components/shared-components/popups/generic-popup/checkForPopupService";
import { GenericPopup } from "components/shared-components/popups/generic-popup/GenericPopup";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
const linkResolver = require('../../../../linkResolver.js');

function CareerPage() {
  const career = useCareerData();
  const dispatch = useDispatch();
  const displayPopup  = checkForPopup()?.page?.text?.split(';')?.filter(p => p !== '')?.includes('career');
  const divOffers = useRef() as MutableRefObject<HTMLDivElement>;

  const scrollToDiv = (ref: MutableRefObject<HTMLDivElement>) => {
  if (typeof window !== `undefined`) {
    ref.current.scrollIntoView({
          behavior: 'smooth'
        })
    }
  };

  useEffect(() => {
    if (typeof window !== `undefined`) {
      const url = location?.pathname?.split('/').filter(item => item !== '')
  
      const findLangInUrl = (): number => url.findIndex(item => item === 'en-pl' || 'pl' || 'fr-fr' || 'de-de');
      const urlLanguage = url[findLangInUrl()] as Language
      dispatch(setLanguage(urlLanguage));
    }
  }, []);

  return (
    <>
      <Seo
        title={career?.seo_title?.text}
        description={career?.seo_description?.text}
        canonicalUrl={career?.seo_canonical_url?.url}
      />
            
      { displayPopup && <GenericPopup /> }
      
      <CareerLayout>
        <Showcase click={()=> scrollToDiv(divOffers)}/>
        <Company />
        <div className="line-bg">
          <div className="line"></div>
        </div>
        <People />
        <Offers reference={divOffers}/>
        <CvBanner />
        <About />
        {/* <Newsletter /> */}
      </CareerLayout>
    </>
  );
};
export default withPrismicPreview(CareerPage, [
  {
    repositoryName: 'carcavelos',
    linkResolver: linkResolver.linkResolver,
  },
]);