import React, { FC } from "react";
import "./PostBox.scss";
import BtnArrowRight from "assets/arrowbtn.svg";
import {
  Maybe,
  PrismicSuccessStoryBodySidebarBoxPrimaryType,
} from "types/declarations";
import Placeme from "assets/placemeWhite.svg";
import Target from "assets/target-white.svg";
import Source from "assets/source-white.svg";
import { Link } from "gatsby";
import styled, { css } from "styled-components";
import { useSelector } from "react-redux";
import { RootState } from "utils/store/store";

const Div = styled.div(({ productColor }: { productColor: string }) => {
  return css`
    svg {
      width: 156px;
      height: auto;
      path {
        fill: ${productColor};
      }
    }
  `;
});

interface PostBoxProps {
  boxContent: Maybe<PrismicSuccessStoryBodySidebarBoxPrimaryType> | undefined;
}

const PostBox: FC<PostBoxProps> = ({ boxContent }: PostBoxProps) => {
  const { language } = useSelector((state: RootState) => state.i18n);

  const getProduct = (
    product: string
  ) => {
    if (product?.includes("placeme")) {
      return (
        <Div productColor="#362FB7">
          <Placeme />
        </Div>
      );
    }
    if (product?.includes("target")) {
      return (
        <Div productColor="#CC3C00">
          <Target />
        </Div>
      );
    }
    if (product?.includes("source")) {
      return (
        <Div productColor="#6830B2">
          <Source />
        </Div>
      );
    }
    return <Div productColor="#001D62">{product}</Div>;
  };

  return (
    <div className="ss-post-body__sidebar--box">
      <div className="ss-post-body__sidebar--box--logo">
        {getProduct(boxContent?.box_product?.raw[0]?.text)}
      </div>
      <h3 className="ss-post-body__sidebar--box--title">
        {boxContent?.box_title?.raw[0]?.text}
      </h3>
      <p className="ss-post-body__sidebar--box--description">
        {boxContent?.box_description?.raw[0]?.text}
      </p>
      <Link
        className="ss-post-body__sidebar--box--btn btn filled"
        to={`/${language}${boxContent?.box_button_link?.raw[0]?.text}`}>
        {boxContent?.box_button_label?.raw[0]?.text}
        <BtnArrowRight />
      </Link>
    </div>
  );
};
export default PostBox;
