import * as React from "react";
import "./CareerLayout.scss";
import { FC } from "react";
import Header from "components/shared-components/header/Header";
import Footer from "features/footer/Footer";
import RootContainer from "containers/RootContainer";

export interface CareerLayoutProps {
  children: React.ReactNode;
}

const CareerLayout: FC<CareerLayoutProps> = ({ children }: CareerLayoutProps) => {
  return (
    <RootContainer>
      <Header />
      <main className="main-career">{children}</main>
      <Footer />
    </RootContainer>
  );
};

export default CareerLayout;
