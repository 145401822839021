import { graphql, useStaticQuery } from "gatsby";
import {
  PrismicBlogPostInterestedBannerEdge
} from "types/declarations";
import { useSelector } from "react-redux";
import { RootState } from "utils/store/store";
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useInterestedData = (): any => {
  const { language } = useSelector((state: RootState) => state.i18n);
  const resultS = useStaticQuery(graphql`
  {
    allPrismicBlogPostInterestedBanner {
      totalCount
      edges {
        node {
          lang
            _previewable
            data {
            button_left {
              raw
            }
            button_right {
              raw
            }
            description {
              raw
            }
            heading {
              raw
            }
            subheader {
              raw
            }
          }
        }
      }
    }
  }`);
  const {data: result} = useMergePrismicPreviewData(resultS)

  return result.allPrismicBlogPostInterestedBanner.edges
    .filter((node: PrismicBlogPostInterestedBannerEdge ) => {
      if(language === 'pl') {
        return node.node.lang === language
      } else{
        return node.node.lang === 'en-pl'
      }
    })[0].node.data;
};