import React from 'react';
import { RichText, RichTextBlock } from 'prismic-reactjs';
import { navigate } from 'gatsby';

interface IFreeTrialBox {
  button: RichTextBlock[] | undefined;
  description: RichTextBlock[] | undefined;
}

export const FreeTrialBox = ({button, description}: IFreeTrialBox): JSX.Element => {
  return (
    <div className="free-trial-box">
      <div className="free-trial-box__button" onClick={() => navigate('https://app.dataplace.ai/auth/sign-up')}>
        <RichText render={button} />
      </div>
      <div className="free-trial-box__description">
        <RichText render={description} />
      </div>
    </div>
  )
}
