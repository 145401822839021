import * as React from "react";
import "./Awards.scss";
import { Maybe, PrismicHomepageDataAwardsImages } from "types/declarations";
import { FC } from "react";

export interface AwardsGridItemProps {
  award: Maybe<PrismicHomepageDataAwardsImages>;
}

const AwardsGridItem: FC<AwardsGridItemProps> = ({
  award,
}: AwardsGridItemProps) => {
  return (
    <div className="awards-grid-item">
      <img
        src={award?.awards_image?.url ?? ""}
        className="awards-grid-item__img" alt="awards logos" 
      />
    </div>
  );
};

export default AwardsGridItem;
