import React, { FC } from "react";
import "./Header.scss";
import { useSelector } from "react-redux";
import { RootState } from "utils/store/store";
import Breadcrumbs from "../../items/breadcrumbs/Breadcrumbs";
import Button from "ui/button/Button";
import { RichText } from "prismic-reactjs";
import { RecruitmentPost } from "../../careerModel";
import { Maybe } from "types/declarations";

interface HeaderProps {
  post: Maybe<RecruitmentPost> | undefined;
}

const Header: FC<HeaderProps> = ({ post }) => {
  const { language } = useSelector((state: RootState) => state.i18n);
  const mailLink = 'mailto:career@dataplace.ai?subject=Rerutacja';

  return (
    <div className="post-header">
      <div className="post-header__inner">
        <div className="post-header__inner--left">
          <div>
            <div className="post-header__inner--left--breadcrumbs">
              {language === "pl" ? (
                <Breadcrumbs
                  firstName="dataplace.ai"
                  secondName="Kariera"
                  secondLink="kariera"
                  thirdName={post?.title}
                />
              ) : (
                <Breadcrumbs
                  firstName="dataplace.ai"
                  secondName="Career"
                  secondLink="career"
                  thirdName={post?.title}
                />
              )}
            </div>
            {post?.tags?.map((tag, i) => <div className="offers__inner--container---item_tag" key={i}>
                  {tag?.value}
                </div>
            )}
            <h1 className="post-header__inner--left--title">{post?.title}</h1>
            <div className="post-header__inner--left--info">
              {post?.post_icons?.map((icon, i) => <div key={i} className="post-header__inner--left--info---icon">
                    <img
                      src={icon?.icon?.url ?? ""}
                      alt={icon?.icon?.alt ?? ""}
                    />
                    <p>{RichText.asText(icon?.label?.raw)}</p>
                  </div>
              )}
            </div>
            <a className="contact-button__text" href={mailLink}>
              <Button
                className="post-header__inner--left--button"
                as="button"
                minWidth={180}
                arrowRight
                filled>
                {language === "pl" ? "Aplikuj" : "Apply"}
              </Button>
            </a>
          </div>
        </div>
        <div className="post-header__inner--right">
          <img
            className="post-header__inner--right-img"
            src={post?.image?.url ?? ""}
            alt={post?.image?.alt ?? ""}
          />
        </div>
      </div>
    </div>
  );
};
export default Header;
