import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { IHubspotFormModal } from './@types/IHubspotFormModal'
import { IHubspotFormModalProps } from './@types/IHubspotFormModalProps.props'
import CloseBtn from 'components/shared-components/buttons/CloseBtn'

const Modal = styled.div<IHubspotFormModal>(({ show, top, left }) => `
  width: 550px;
  background: white;
  position: fixed;
  top: ${top || '10vh'};
  left: ${left || '25vw'};
  z-index: ${show ? '2000' : '-2000'};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  display: flex;
  padding-top: 1rem;
  
  @media (max-width: 850px) {
    width: 700px;
    left: 5vw;
  }
`)

const FormContainer = styled.div`
  position: relative;
  bottom: 20px;
  max-height: 700px;
`

declare global {
  interface Window {
    hbspt: {
      forms: {
        create: (options: { portalId: string, formId: string, target: string }) => void
      }
    }
  }
}

export function HubspotMeetingModal({ show, closeMeeting, top, left }: IHubspotFormModalProps): JSX.Element {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false)

  useEffect(() => {
    const loadScript = () => {
      const script = document.createElement('script')
      script.src = '//js.hsforms.net/forms/shell.js'
      script.async = true
      script.type = 'text/javascript'
      document.body.appendChild(script)

      script.onload = () => {
        if (!('hbspt' in window)) return
        window.hbspt.forms.create({
          portalId: '20363741',
          formId: 'c61c7789-c163-408b-817e-2c577b9d5475',
          target: '#form-container',
        })
        setIsScriptLoaded(true)
      }
    }

    if (show && !isScriptLoaded) loadScript()

    return () => {
      const script = document.querySelector(`script[src="//js.hsforms.net/forms/shell.js"]`)

      if (!script) return
      document.body.removeChild(script)
      setIsScriptLoaded(false)
    }
  }, [show])

  return (
    <>
      {show && isScriptLoaded
        ? (
          <Modal left={left} show={show} top={top}>
            <CloseBtn handleClick={closeMeeting} />
            <FormContainer id="form-container" />
          </Modal>
        )
        : null}
    </>
  )
}

export default HubspotMeetingModal
