import React from "react";
import "./PostDetails.scss";
import {Link} from "gatsby";
import BlogPostArrow from "assets/blog-post-arrow.svg";
import {
  Maybe,
  PrismicBlogPostBodyReportSliceInsidePostItemType,
} from "types/declarations";
import { RichText } from "prismic-reactjs";
import { v4 as uuid } from "uuid";
import { useSelector } from "react-redux";
import { RootState } from "utils/store/store";

export interface PostInsideBannerProps {
  banner: Maybe<PrismicBlogPostBodyReportSliceInsidePostItemType>
}

const PostInsideBanner = ({banner}: PostInsideBannerProps): JSX.Element => {
  const points = RichText.asText(banner?.points?.raw || []).split(';');
  const { language } = useSelector((state: RootState) => state.i18n);

  return (
    <div className="post-inside-banner">
      <div className="post-inside-banner__inner">
        <div className="left">{banner?.report_image?.url ? <img src={banner?.report_image?.url} alt="colorful graphic" />: null}</div>
        <div className="right">
          <p>{RichText.asText(banner?.report_title?.raw || [])}</p>
          <div>
            <ul>
              {points.map((point, i)=> point.length > 0 ? <li key={i + uuid()}><BlogPostArrow /> {point}</li> : null)}
            </ul>
          </div>
          {banner?.button_link ? <Link to={`/${language}/report/${RichText.asText(banner?.button_link?.raw || [])}/`}>{RichText.asText(banner?.button_text?.raw || [])}</Link>: null}
        </div>
      </div>
    </div>
  )
};

export default PostInsideBanner;
