import { RichText } from 'prismic-reactjs';
import React, { useState } from 'react';
import { productColors } from 'shared/constants';
import styled, { css } from 'styled-components';
import BtnArrowRight from "assets/arrowRight.svg";
import { PrismicProductHomeDataBodySuccessStoriesSlice } from 'types/declarations';
import Button from 'ui/ButtonComponent/Button';
import { useMediaQuery } from 'utils/hooks/useMediaQuery';
import Statistics from './components/Statistics';
import CarouselButtons from './components/CarouselButtons';
import CarouselItemContent from './components/CarouselItemContent';

const SectionWrapper = styled.section`
  background-color: ${productColors.violet.light};
`;

const SuccessStoriesWrapper = styled.div(() => {
  const { mobile, tablet } = useMediaQuery()
  
  return css`
    padding: 28px 84px;
    max-width: 1440px;
    margin: 0 auto;

    ${tablet && 'padding: 40px 25px'}
    ${mobile && 'padding: 25px 15px'}
  `
});

const CarouselContainer = styled.div`
  display: flex;
  overflow: hidden;
  width: 100%;
  position: relative;
`;

const CarouselItem = styled.div(() => {
  const { tablet, mobile } = useMediaQuery()

  return css`
    min-width: 100%;
    transition: transform 0.5s ease-in-out;
    display: flex;
    align-items: center;
    font-size: 24px;
    text-align: center;

    ${!mobile && css`justify-content: space-between;`}
    ${tablet || mobile ? css`flex-direction: column;` : css`flex-direction: row;`}
  `
});

const LinkWrapper = styled.div(() => {
  const { mobile } = useMediaQuery()

  return css`
    display: flex;
    align-items: center;
    justify-content: 'flex-start';
    width: 100%;
    text-decoration: none;

    ${mobile && css`
      flex-direction: column;
      align-items: flex-start;
    `}
  `
});

export const StyledButton = styled(Button)(() => {
  const { mobile } = useMediaQuery()
    
  return css`
    font-size: 1rem;
    background-color: inherit;
    color: ${productColors.violet.dark};

    ${mobile 
      ? css`
        margin-top: 0px;
        height: 50px;
        width: 100%;
        margin-bottom: 15px;
        max-height: unset;
      ` 
      : css`
        margin-top: 10px;
        max-height: 2.5rem;
      `
    }
    
    svg {
      position: relative;
      left: -8px;
      path {
        stroke: ${productColors.violet.dark};
      }
    }

    &:hover {
      background-color: ${productColors.white};
      color: ${productColors.violet.dark};
      svg {
        transform: translateX(10px);
        transition: transform 0.3s ease-in-out;
        path {
          stroke: ${productColors.violet.dark};
        }
      }
    }
  `
});

const SuccessStories: React.FC<{ slice: PrismicProductHomeDataBodySuccessStoriesSlice }> = ({ slice }) => {
  const { mobile, tablet } = useMediaQuery();
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const { items } = slice

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? (items?.length || 0) - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === (items?.length || 0) - 1 ? 0 : prevIndex + 1));
  };

  return (
    <SectionWrapper>
      <SuccessStoriesWrapper>
        <CarouselContainer>
          {items?.map((item, index) => (
            <CarouselItem
              key={index}
              style={{ transform: `translateX(-${currentIndex * 100}%)` }}
            >
              <CarouselItemContent 
                title={RichText.asText(item?.title?.richText)} 
                imgUrl={item?.company_logo?.url} 
                imgAlt={item?.company_logo?.alt} 
                subtitle={RichText.asText(item?.subtitle?.richText)} 
                description={RichText.render(item?.description?.richText)}
                author={RichText.asText(item?.author?.richText)}
                buttonText={RichText.asText(item?.button_text?.richText)}
                linkUrl={item?.button_link?.raw.url}
              />
              <Statistics item={item} />
              {tablet || mobile 
                ? (
                  <LinkWrapper>
                    <a href={item?.button_link?.raw.url}>
                      <StyledButton reverse minWidth={mobile ? '100%' : 'none'}>
                        {RichText.asText(item?.button_text?.richText)}
                        <BtnArrowRight />
                      </StyledButton>
                    </a>
                    <CarouselButtons handleNext={handleNext} handlePrev={handlePrev} />
                  </LinkWrapper>
                )
                : null
              }
            </CarouselItem>
            ))}
            {!mobile 
              ? (
                <CarouselButtons handleNext={handleNext} handlePrev={handlePrev} />
              )
              : null
            }
        </CarouselContainer>
      </SuccessStoriesWrapper>
    </SectionWrapper>
  );
};

export default SuccessStories;
