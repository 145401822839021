import * as React from 'react';
import './Showcase.scss';
import { RichText } from 'prismic-reactjs';
import Button from 'ui/button/Button';
import { useShowcaseData } from 'features/homepage/showcase/showcaseService';
import { useCallback, useEffect } from 'react';
import { navigate } from 'gatsby';
import { useSelector } from 'react-redux';
import { RootState } from 'utils/store/store';
import { useMediaQuery } from 'utils/hooks/useMediaQuery';

interface IShowcase {
  handleHubspot: () => void;
}

const Showcase = ({ handleHubspot }: IShowcase): JSX.Element => {
  const data = useShowcaseData();
  const { language } = useSelector((state: RootState) => state.i18n);

  const { mobile, tablet } = useMediaQuery();

  const onDemoClick = useCallback(() => {
    navigate('https://app.dataplace.ai/auth/sign-in');
  }, []);

  const redirectToNewPath = useCallback(() => {
    navigate(`/${language}/`);
  }, []);

  useEffect(() => {
    redirectToNewPath();
  }, []);

  return (
    <section className='showcase'>
      <div className='showcase__inner'>
        <div className='showcase__inner--left'>
          <div>
            <RichText render={data?.hero_title?.raw || []} />
            <RichText render={data?.hero_subtitle?.raw || []} />
            <Button
              className=''
              as='button'
              handleButtonClick={handleHubspot}
              minWidth={210}
              filled>
              {RichText.asText(data?.free_trial_button?.raw || [])}
            </Button>
          </div>
        </div>
        <div className='showcase__inner--right'>
          {/* <img
            className="showcase-img"
            src={data?.hero_image?.url ?? ""}
            alt={data?.hero_image?.alt ?? ""}
          /> */}
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <video
            width={mobile || tablet ? '468px' : '568px'}
            height={mobile ? '303px' : '453px'}
            autoPlay
            loop
            muted
            playsInline>
            <source
              src='https://dataplace-assets-new.s3.eu-central-1.amazonaws.com/storyboard-homepage5-3.mp4'
              type='video/mp4'
            />
          </video>
        </div>
      </div>
    </section>
  );
};

export default Showcase;
