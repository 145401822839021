import React from "react";
import "./ProductsLogos.scss";
import Placeme from "assets/placeme.svg";

const ProductsLogosWhite = (): JSX.Element => <div className="products-logos-bar">
  <div className="products-logos-bar__element">
    <Placeme height="24" />
  </div>
</div>;

export default ProductsLogosWhite;
