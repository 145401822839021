import * as React from 'react';
import './Technology.scss';
import { FC } from 'react';
import styled, { css } from 'styled-components';
import { useMediaQuery } from 'utils/hooks/useMediaQuery';
import { Maybe, PrismicHomepageDataTechnologyPoints } from 'types/declarations';
import { RichText } from 'prismic-reactjs';

export interface TechnologyGridItemProps {
  point: Maybe<PrismicHomepageDataTechnologyPoints>;
}

const TechnologyGridItemStyled = styled.div(() => {
  const { tablet } = useMediaQuery();
  return css`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    ${tablet &&
    css`
      flex-direction: unset;
      margin-top: 2.5rem;
      height: 6rem;
      width: 95%;
    `};
  `;
});

const TechnologyGridItemTopStyled = styled.div(() => {
  const { mobile, tablet } = useMediaQuery();
  return css`
    display: flex;
    flex-direction: column;
    align-items: center;
    &:only-child {
      margin-bottom: 8rem;
    }
    ${mobile &&
    css`
      height: 69%;
      &:only-child {
        margin: 0 0 1rem 9rem;
      }
    `};
    ${tablet &&
    css`
      width: 100%;
      margin-left: 5rem;
      &:only-child {
        margin: -4rem 7rem 2rem 0;
      }
    `};
  `;
});

const TechnologyPointImageStyled = styled.img(() => {
  const { mobile, tablet } = useMediaQuery();
  return css`
    margin-right: 0;
    ${mobile &&
    css`
      margin-top: 0.5rem;
    `};
    ${tablet &&
    css`
      margin-top: 3.5rem;
      &:only-child {
        margin-left: 4rem;
      }
    `};
  `;
});

const TechnologyGridTitleStyled = styled.p(() => {
  const { mobile } = useMediaQuery();
  return css`
    font-size: 20px;
    line-height: 33px;
    color: #001d62;
    text-align: center;
    max-height: 4rem;
    margin-top: 1.5rem;
    ${mobile &&
    css`
      max-width: 100%;
      margin-top: 1rem;
    `};
  `;
});

const TechnologyGridDescriptionStyled = styled.div(() => {
  const { mobile, tablet, laptop, desktop } = useMediaQuery();
  return css`
    font-size: 19px;
    line-height: 33px;
    color: #001d62;
    text-align: center;
    width: 170%;
    ${mobile &&
    css`
      font-size: 1.1rem;
      width: 115%;
      padding: 0.6rem;
    `};
    ${tablet &&
    css`
      text-align: left;
      width: 160%;
      font-size: 1.1rem;
      padding: 1.2rem;
      margin-left: 4rem;
    `};
    ${laptop &&
    css`
      font-size: 1.1rem;
      padding: 0.1rem 1rem;
    `};

    ${(laptop || desktop) &&
    css`
      white-space: pre-wrap;
    `}
  `;
});

const TechnologyGridItem: FC<TechnologyGridItemProps> = ({
  point,
}: TechnologyGridItemProps) => {
  const { mobile, tablet } = useMediaQuery();
  const title = RichText.asText(point?.technology_point_title?.raw);
  const description = RichText.asText(point?.technology_point?.raw);
  return (
    <TechnologyGridItemStyled>
      <TechnologyGridItemTopStyled>
        <TechnologyPointImageStyled
          src={
            mobile || tablet
              ? point?.technology_point_image_mobile?.url
              : point?.technology_point_image?.url
          }
          alt='Technology'
        />
        {title && (
          <TechnologyGridTitleStyled>{title}</TechnologyGridTitleStyled>
        )}
      </TechnologyGridItemTopStyled>
      {description && (
        <TechnologyGridDescriptionStyled>
          {description}
        </TechnologyGridDescriptionStyled>
      )}
    </TechnologyGridItemStyled>
  );
};

export default TechnologyGridItem;
