import * as React from "react";
import "./Interested.scss";
import Button from "ui/button/Button";
import { useInterestedData } from './interestedService'
import { RichText } from "prismic-reactjs";

interface ICta {
  handleHubspot?: () => void;
}
const InterestedBanner = ({ handleHubspot }: ICta): JSX.Element => {
  const data = useInterestedData();

  return (
    <section className="interested">
      <div className="interested__inner">
        <div className="interested__inner--left">
          <h3>{RichText.asText(data?.heading?.raw || [])}</h3>
          <p>{RichText.asText(data?.description?.raw || [])}</p>
          <Button handleButtonClick={handleHubspot} linkStyle='fill' minWidth={190} maxWidth={210} arrowRight as="button">
            {RichText.asText(data?.button_right?.raw || [])}
          </Button>
        </div>
        <div className="interested__inner--right">
          <img src="https://carcavelos.cdn.prismic.io/carcavelos/58af16ba-9095-45ea-b984-f69c9f9e4415_Group.svg" alt="icon" />
        </div>
      </div>
    </section>
  );
};

export default InterestedBanner;