import { graphql, useStaticQuery } from "gatsby";
import {
  PrismicHomepage,
  PrismicHomepageDataType,
  PrismicHomepageEdge,
} from "types/declarations";
import { useSelector } from "react-redux";
import { RootState } from "utils/store/store";
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews";

export const useIntroductionData = (): PrismicHomepageDataType => {
  const { language } = useSelector((state: RootState) => state.i18n);
  const resultS = useStaticQuery(graphql`
    {
      allPrismicHomepage {
        totalCount
        edges {
          node {
            lang
            _previewable
            data {
              products_small {
                raw
              }
              products_text {
                raw
              }
              company_mission_text {
                raw
              }
              hero_cards {
                hero_card_color
                hero_card_subtitle {
                  raw
                }
                hero_card_title {
                  raw
                }
                product_tab_description {
                  raw
                }
                product_tab_button_1_text {
                  raw
                }
                product_tab_button_1_link {
                  raw
                }
                product_tab_button_2_text {
                  raw
                }
                product_tab_button_2_link {
                  raw
                }
                product_tab_image {
                  url
                  alt
                }
                product_tab_logo {
                  url
                  alt
                }
              }
              products_section_title {
                raw
              }
              products_section_heading {
                raw
              }
            }
          }
        }
      }
    }
  `);
  const {data: result} = useMergePrismicPreviewData(resultS)
  return result.allPrismicHomepage.edges
    .map((edge: PrismicHomepageEdge) => edge.node)
    .filter((node: PrismicHomepage) => node.lang === language)
    .map((node: PrismicHomepage) => node.data)
    .find(
      // Find first non null value
      (data: PrismicHomepageDataType) => data.company_mission_text?.raw
    );
};
