import { useLocale } from "locale/useLocale";
import { RichTextBlock, RichText } from "prismic-reactjs";
import React, { useState } from "react";
import Close from "../../../../../../assets/close.svg";

interface IPackageBox {
  title: RichTextBlock[] | never[];
  description: RichTextBlock[] | never[];
  includedAnalyzes?: RichTextBlock[] | never[];
  analyzesLabel?: RichTextBlock[] | never[];
  availableAnalyzes: RichTextBlock[] | never[];
  analyzesModal?: RichTextBlock[] | never[];
  modalButton?: RichTextBlock[] | never[];
  isPopular: boolean | null | undefined;
  hasImg?: boolean;
}

export const PackageBox = ({
  title,
  description,
  analyzesLabel,
  availableAnalyzes,
  analyzesModal,
  modalButton,
  isPopular,
  hasImg,
}: IPackageBox): JSX.Element => {
  const locale = useLocale("Pricelist");

  const [modal, setModal] = useState(false);

  return (
    <div className="package">
      <div className="d-md-flex">
        <div>
          <div className="title">
            <span>{locale?.package}</span><RichText render={title} />
            {isPopular && <div className="popular">{locale?.popular}</div>}
          </div>

          {description && (
            <div className="description">
              <RichText render={description} />
            </div>
          )}
        </div>
        <div>
          {analyzesLabel && (
            <div className="analyzes-label">
              <RichText render={analyzesLabel} />
            </div>
          )}
          <div className="available-analyzes">
            <RichText render={availableAnalyzes} />
          </div>
          {modalButton && (
            <div className="modal-button d-lg-none" onClick={() => setModal(true)}>
              <RichText render={modalButton} />
            </div>
          )}
        </div>
      </div>

      {modalButton && (
        <div className="modal-button d-md-none" onClick={() => setModal(true)}>
          <RichText render={modalButton} />
        </div>
      )}

      {modal && (
        <div className="modal">
          <div>
            <h4 className="modal-title">{locale.modalTitle}</h4>
            <Close width="20" height="20" className="close"  onClick={() => setModal(false)}/>
          </div>
          <RichText render={analyzesModal} />
        </div>
      )}

      {hasImg && (
        <img
          src="https://carcavelos.cdn.prismic.io/carcavelos/d72768c5-54f6-4b36-8e2b-bc768bd835ab_Group+6699.svg"
          alt="essential package"
          width="185"
          className="d-lg-none"
        />
      )}
    </div>
  );
};
