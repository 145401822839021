import React from 'react'
import styled, { css } from 'styled-components'
import Close from 'assets/close.svg'
import MessageIcon from 'assets/message.svg'
import { colors } from 'shared/constants'

const ModalWrapper = styled.div(() => css`
  background-color: ${colors.white};
  position: fixed;
  border-radius: 8px;
  top: 25vh;
  left: calc(50vw - 22.5vw);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  width:45vw;
  z-index: 500;
  box-shadow:  0px 0px 35px rgba(36, 33, 84, 0.12);

  @media (max-width: 900px) {
    width: 95vw;
    left: 2vw;
  }
`)

const CloseWrapper = styled.div(() => css`
  cursor: pointer;
  z-index: 500;
`)

const StyledMessageIcon = styled(MessageIcon)(() => css`
  position: relative;
  bottom: 20px;
  max-width: 300px;
`)

const StyledClose = styled(Close)(() => css`
  path {
    stroke: ${colors.darkBlue};
  }
  width: 30px;
  height: 20px;
`)

const ModalContent = styled.div(() => css`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`)

const Message = styled.p(() => css`
  width: 65%;
  text-align: center;
  padding: 0 20px;
  font-size: 16px;
`)

interface FormPopupProps {
  message: string;
  closePopup: () => void
}

const FormPopup = ({ closePopup, message }: FormPopupProps) => {

  return (
    <ModalWrapper>
      <CloseWrapper onClick={closePopup}>
        <StyledClose width='20' height='20' />
      </CloseWrapper>
      <ModalContent>
        <Message>{message}</Message>
        <StyledMessageIcon width='361' height='286' />
      </ModalContent>
    </ModalWrapper>
  )
}

export default FormPopup
