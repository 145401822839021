import { RichText } from 'prismic-reactjs'
import React, { ForwardedRef, forwardRef } from 'react'
import { HUBSPOT_MEETING_URL, colors } from 'shared/constants'
import styled, { css } from 'styled-components'
import { PrismicProductHomeDataBodyCalendarSlice } from 'types/declarations'
import { useMediaQuery } from 'utils/hooks/useMediaQuery'

const StyledSection = styled.section`
  background-color: ${colors.background};
  scroll-margin-top: 4.5rem;
`

const StyledMainDiv = styled.div(() => {
  const { laptop, desktop, tablet, mobile } = useMediaQuery()

  return css`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    padding: ${tablet ? '55px 25px' : mobile ? '30px 15px' : '60px 84px'};
    max-width: 1440px;
    gap: ${(desktop || laptop) ? '2rem' : '1rem'};
  `
})

const StyledTitle = styled.h2(() => {
  const { tablet, mobile } = useMediaQuery()

  return css`
    font-weight: 700;
    font-size: ${tablet ? '2.25rem' : mobile ? '1.875rem' : '2.5rem'};
    line-height: ${mobile ? '2.5rem' : '3.25rem'};
    text-align: center;
    margin: 0;
    color: ${colors.darkBlue};
    ${tablet && css`max-width: 90%;`}
  `
})

const StyledDescription = styled.p(() => {
  const { mobile } = useMediaQuery()

  return css`
    font-weight: 400;
    font-size: ${mobile ? '1.25rem' : '1.5rem'};
    line-height: ${mobile ? '1.75rem' : '2rem'};
    text-align: center;
    margin: 0;
    color: ${colors.darkBlue};
  `
})

const StyledIframeContainerDiv = styled.div`
  width: 100%;
`

const StyledIframe = styled.iframe`
  width: 100%;
  height: 700px;
  border: none;
  margin: 0;
`

interface CalendarProps {
  slice: PrismicProductHomeDataBodyCalendarSlice
}

const Calendar = forwardRef(({ slice }: CalendarProps, ref: ForwardedRef<HTMLElement>) => {
  const { primary } = slice

  return (
    <StyledSection ref={ref}>
      <StyledMainDiv>
        <StyledTitle>{RichText.asText(primary?.title?.richText)}</StyledTitle>
        <StyledDescription>{RichText.asText(primary?.description?.richText)}</StyledDescription>
        <StyledIframeContainerDiv>
          <StyledIframe src={HUBSPOT_MEETING_URL} />
        </StyledIframeContainerDiv>
      </StyledMainDiv>
    </StyledSection>
  )
})

export default Calendar
