import React, { useCallback, useEffect, useMemo, useState } from "react"
import styled, { css } from "styled-components"
import backgroundDesktop from 'images/popup/popup_main_ebook.jpg'
import backgroundMobile from 'images/popup/popup_main_ebook_mobile.jpg'
import Popup from "reactjs-popup"
import Close from "assets/close.svg"
import { useMediaQuery } from "utils/hooks/useMediaQuery"

interface HandlesMobile {
  $mobile?: boolean
}

const StyledImage = styled.img<HandlesMobile>(({ $mobile }) => css`
  margin: 0;
  border-radius: 1rem;
  ${$mobile
    ? css`
      max-height: 90vh;
      max-width: 90vw;
    `
    : css`
      width: 70vw;
    `
  }
`)

const StyledButton = styled.button<HandlesMobile>(({ $mobile }) => css`
  position: absolute;
  background-color: #fff;
  border: none;
  border-radius: 0.5rem;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  bottom: ${$mobile ? '12%' : '14%'};
  left: ${$mobile ? '10%' : '8.5%'};
  width: ${$mobile ? '80%' : '28%'};
  height: ${$mobile ? '7%' : '10%'};
  font-size: ${$mobile ? '3vh' : '1.5vw'};
  color: black;
`)

const StyledCloseIcon = styled(Close)(({ $mobile }) => css`
  path {
    stroke: #fff;
  }
  position: absolute;
  top: ${$mobile ? '0%' : '2%'};
  right: ${$mobile ? '4%' : '3%'};
  width: ${$mobile ? '10%' : '6%'};
  height: ${$mobile ? '9%' : '10%'};
  cursor: pointer;
`)

const StyledAnchor = styled.a`
  text-decoration: none;
  outline-style: none;
  box-shadow: none;
  border-color: transparent;
  color: black;
`

function EbookPopup() {
  const [open, setOpen] = useState(false)
  const { mobile, tablet } = useMediaQuery()

  const isMobile = useMemo(() => mobile || tablet, [mobile, tablet])
  const bgImage = useMemo(() => isMobile ? backgroundMobile : backgroundDesktop, [isMobile])

  useEffect(() => {
    setTimeout(() => {
      setOpen(true)
    }, 10000)
  }, [])

  const close = useCallback(() => setOpen(false), [])

  return (
    <Popup open={open} onClose={close} modal closeOnDocumentClick={false}>
      <StyledCloseIcon onClick={close} $mobile={isMobile} />
      <StyledImage src={bgImage} $mobile={isMobile} />
      <StyledAnchor href='https://dataplace.ai/pl/report/ebook-location-intelligence-w-biznesie?utm_source=eBook&utm_medium=popup&utm_id=eBook_locationintelligence' target="_blank">
        <StyledButton onClick={close} $mobile={isMobile}>Pobierz teraz!</StyledButton>
      </StyledAnchor>
    </Popup>
  )
}

export default EbookPopup
