import { useStaticQuery } from "gatsby";
import { graphql } from "gatsby";
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews";
import { useSelector } from "react-redux";
import { PrismicGenericPopupDataType, PrismicGenericPopupEdge, PrismicGenericPopup } from "types/declarations";
import { RootState } from "utils/store/store";

export const checkForPopup = (): PrismicGenericPopupDataType => {
  const { language } = useSelector((state: RootState) => state.i18n);

  const resultS = useStaticQuery(graphql`
    {
      allPrismicGenericPopup {
        edges {
          node {
            lang
            _previewable
            data {
              page {
                text
              }
            }
          }
        }
      }
    }
  `);

  const {data: result} = useMergePrismicPreviewData(resultS)
  return result.allPrismicGenericPopup.edges
    .map((edge: PrismicGenericPopupEdge) => edge?.node)
    .filter((node: PrismicGenericPopup) => node?.lang === language)
    .map((node: PrismicGenericPopup) => node?.data)[0];
};