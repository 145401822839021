import * as React from "react";
import "./ProductBenefitsIntroduction.scss";
import ProductBenefitsIntroductionGridBox from "features/product-page/product-benefits/product-benefits-introduction/product-benefits-introduction-grid-box/ProductBenefitsIntroductionGridBox";
import ProductBenefitsIntroductionItem from "features/product-page/product-benefits/product-benefits-introduction/product-benefits-introduction-item/ProductBenefitsIntroductionItem";
import { useProductBenefitsData } from "features/product-page/product-benefits/productBenefitsService";
import { RichText } from "prismic-reactjs";
import { ProductPageProps } from "features/product-page/product-homepage/productHomeService";
import { FC } from "react";
import Button from "ui/button/Button";

const ProductBenefitsIntroduction: FC<ProductPageProps> = (
  props: ProductPageProps
) => {
  const data = useProductBenefitsData(props);
  return (
    <section className="product-benefits-introduction">
      <div className="product-benefits-introduction__inner">
        <ProductBenefitsIntroductionGridBox data={data} />
        <span className="product-benefits-introduction__inner--line" />
        <p className="product-benefits-introduction__inner--description">
          {RichText.asText(data?.benefits_header?.raw || [])}
        </p>
        <div className="product-benefits-introduction__inner--items">
          {data?.benefits_sections?.map((item, index) => (
            <ProductBenefitsIntroductionItem
              key={index}
              description={item?.text?.raw}
              buttonText={RichText.asText(item?.button?.raw || [])}
              img={item?.image?.url}
              link={RichText.asText(item?.link?.raw || [])}
            />
          ))}
        </div>
        <div className="product-benefits-introduction__inner--info">
          <p>{RichText.asText(data?.benefits_cta_header?.raw || [])}</p>
          <Button as='button' linkStyle='noFill' handleButtonClick={props.handleHubspot} minWidth={300} arrowRight>
            {RichText.asText(data?.benefits_cta_button?.raw || [])}
          </Button>
        </div>
      </div>
    </section>
  );
};

export default ProductBenefitsIntroduction;
