import * as React from "react";
import "./People.scss";
import { RichText } from "prismic-reactjs";
import { usePeopleData } from "./peopleService";

const People = (): JSX.Element => {
  const people = usePeopleData();

  return (
    <section className="people">
      <div className="people__inner">
        <div className="people__inner--left">
          <div className="people__inner--left--title">
            <h2>{RichText.asText(people?.people_header?.raw || [])}</h2>
          </div>
          <div className="people__inner--left--description">
            <p>{RichText.asText(people?.people_description?.raw || [])}</p>
          </div>
          <div className="people__inner--left--hashtags">
            {people?.people_hashtags?.map((item, index) => <div className="people__inner--left--hashtags---hashtag" key={index}>
                  <p>{RichText.asText(item?.hashtag?.raw || [])}</p>
                </div>
            )}
          </div>
        </div>
        <div className="people__inner--right">
          <img src={people?.people_image?.url ?? ""} alt={people?.people_image?.alt ?? ""} />
        </div>
      </div>
    </section>
  );
};

export default People;
