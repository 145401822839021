import React from 'react'
import styled from 'styled-components'

const CloseButton = styled.button`
  position: relative;
  left: 45%;
  background: transparent;
  border: none;
  cursor: pointer;
`

const StyledSpan = styled.span`
  display: inline-block;
  width: 32px;
  height: 32px;
  opacity: 0.3;

  :hover {
    opacity: 1;
  }

  ::before, ::after {
    position: absolute;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: #00194A;
  }

  ::before {
    transform: rotate(45deg);
  }

  ::after {
    transform: rotate(-45deg);
  }
`

interface CloseBtnProps {
  handleClick: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined
}

const CloseBtn = ({ handleClick }: CloseBtnProps) => (
  <CloseButton onClick={handleClick}>
    <StyledSpan aria-hidden='true' />
  </CloseButton>
)

export default CloseBtn
