import React, { ReactNode } from 'react'
import { OVERLAY_STYLE, colors } from 'shared/constants'
import styled from 'styled-components'
import CloseIcon from 'assets/close.svg'
import Popup from 'reactjs-popup'

const StyledContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 90vw;
  max-height: 90vh;
  gap: 0.5rem;
`

const StyledCloseButtonDiv = styled.div`
  align-self: flex-end;
  justify-self: flex-start;
  cursor: pointer;
  svg {
    path {
      stroke: ${colors.white};
    }
  }
`

interface VideoModalProps {
  children: ReactNode
  onCloseButton(): void
  shouldDisplay: boolean
}

function VideoModal({ children, onCloseButton, shouldDisplay }: VideoModalProps) {
  return (
    shouldDisplay
      ? (
        <Popup 
          open={shouldDisplay}
          modal
          closeOnDocumentClick={false}
          overlayStyle={OVERLAY_STYLE}
        >
          <StyledContentDiv>
            <StyledCloseButtonDiv onClick={onCloseButton}>
              <CloseIcon />
            </StyledCloseButtonDiv>
            {children}
          </StyledContentDiv>
        </Popup>
      )
      : null
  )
}

export default VideoModal
