import React from "react";
import styled from "styled-components";

const ColorSpanWrapper = styled.div`
  white-space: pre-wrap;
  span {
    display: inline-block;
    font-style: normal;
    font-weight: 600;
    font-size: 1.1rem;
    line-height: 1.5rem;
  }
`;

export function ColorSpan({ text }: { text: string }) {
  const regex = /<#([0-9a-f]{6})>(.*?)<\/>/gi;

  const matches = text.matchAll(regex);

  let lastIndex = 0;
  const elements: JSX.Element[] = [];

  for (const match of matches) {
    const [fullMatch, hexValue, innerText] = match;

    const startIndex = match.index ?? 0;
    const endIndex = startIndex + fullMatch.length;

    if (startIndex > lastIndex) {
      // add non-matching text before this match
      elements.push(
        <span key={`non-matching-${lastIndex}-${startIndex}`}>
          {text.slice(lastIndex, startIndex)}
        </span>
      );
    }
    // add matching text with color background
    elements.push(
      <span
        key={`matching-${startIndex}-${endIndex}`}
        style={{ color: `#${hexValue}` }}>
        {innerText}
      </span>
    );
    lastIndex = endIndex;
  }

  if (lastIndex < text.length) {
    // add any remaining non-matching text
    elements.push(
      <span key={`non-matching-${lastIndex}-${text.length}`}>
        {text.slice(lastIndex)}
      </span>
    );
  }

  return <ColorSpanWrapper>{elements}</ColorSpanWrapper>;
}
