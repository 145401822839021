import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface NotFoundState {
  lastPage: string;
}

const initialState = {
  lastPage: "",
} as NotFoundState;

const notFoundSlice = createSlice({
  name: "notFound",
  initialState,
  reducers: {
    setLastPage(state, action: PayloadAction<string>) {
      state.lastPage = action.payload;
    },
  },
});

export const { setLastPage } = notFoundSlice.actions;
export default notFoundSlice.reducer;
