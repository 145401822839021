import * as React from 'react';
import './OfflineAnalytics.scss';
import { RichText } from 'prismic-reactjs';
import { useOfflineAnalyticsData } from 'features/homepage/offline-analytics/offlineAnalyticsService';
import { useMediaQuery } from 'utils/hooks/useMediaQuery';
import styled, { css } from 'styled-components';
import { HUBSPOT_MEETING_URL } from 'shared/constants';

const HeaderSpanStyled = styled.span(() => {
  return css`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 670px;
    margin: 0 auto;
    font-size: 16px;
    line-height: 52px;
    letter-spacing: 2px;
    color: #001c61;
    text-transform: uppercase;
    padding: 1.5rem;
  `;
});

const HeaderParagraphStyled = styled.p(() => {
  const { mobile, tablet } = useMediaQuery();
  return css`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    font-size: 2rem;
    line-height: 52px;
    text-align: center;
    color: #001c61;
    font-weight: 600;
    ${mobile &&
    css`
      font-size: 1.5rem;
      line-height: 2.5rem;
      width: 50%;
    `};
    ${tablet &&
    css`
      font-size: 1.5rem;
      line-height: 2.5rem;
      width: 90%;
    `};
  `;
});

const InfoParagraphStyled = styled.p(() => {
  const { mobile, tablet } = useMediaQuery();
  return css`
    font-size: 18px;
    line-height: 33px;
    color: #001d62;
    flex-direction: column;
    margin: 2rem auto 0 auto;
    text-align: center;
    padding-bottom: 2.5rem;
    ${mobile &&
    css`
      width: 50%;
      margin-bottom: 0;
      font-weight: 450;
    `};
    ${tablet &&
    css`
      width: 100%;
      margin-bottom: 0;
      font-weight: 450;
    `};
  `;
});

const CalendarContainerStyled = styled.div(() => {
  const { mobile, tablet } = useMediaQuery();
  return css`
    display: flex;
    ${(mobile || tablet) &&
    css`
      flex-direction: column;
    `};
  `;
});

const CalendarContainerRightStyled = styled.div(() => {
  const { mobile, tablet } = useMediaQuery();
  return css`
    flex: 5;
    width: 55%;
    ${mobile &&
    css`
      width: 50%;
      display: flex;
      margin-left: 10.5rem;
      justify-content: center;
    `};
    ${tablet &&
    css`
      width: 100%;
      display: flex;
      justify-content: center;
    `};
  `;
});

const OfflineAnalytics = (): JSX.Element => {
  const data = useOfflineAnalyticsData();
  const { mobile, tablet } = useMediaQuery();
  return (
    <section className='offline-analytics'>
      <div className='offline-analytics__inner'>
        <div>
          <HeaderSpanStyled>
            {RichText.asText(data?.analytics_header?.raw || [])}
          </HeaderSpanStyled>
          <HeaderParagraphStyled>
            {RichText.asText(data?.analytics_subtitle?.raw || [])}
          </HeaderParagraphStyled>
          <InfoParagraphStyled>
            {RichText.asText(data?.analytics_info?.raw || [])}
          </InfoParagraphStyled>
          <CalendarContainerStyled>
            <CalendarContainerRightStyled>
              <iframe
                height='700'
                src={HUBSPOT_MEETING_URL}
                style={{ border: 'none' }}
                title='hubspot'
                width={mobile ? '500' : tablet ? '700' : '800'}
              />
            </CalendarContainerRightStyled>
          </CalendarContainerStyled>
        </div>
      </div>
    </section>
  );
};

export default OfflineAnalytics;
