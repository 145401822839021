import * as React from "react";
import "./ProductLearnMore.scss";
import { Link } from "gatsby";
import BtnArrowRight from "assets/arrowbtn.svg";
import { RichText } from "prismic-reactjs";
import {
  ProductPageProps,
  useProductHomeData,
} from "features/product-page/product-homepage/productHomeService";
import { FC, useCallback } from "react";
import { useSelector } from "react-redux";
import { RootState } from "utils/store/store";

const ProductLearnMore: FC<ProductPageProps> = (props: ProductPageProps) => {
  const data = useProductHomeData(props);
  const name= props.productName
  const { language } = useSelector((state: RootState) => state.i18n);

  const getLink = useCallback(() => {
    if (name?.includes("placeme")) {
      return `/${language}/placeme-functionalities/`
    } else if (name.includes("target")) {
      return `/${language}/target-functionalities/`
    } else if (name.includes("source")) {
      return `/${language}/source-functionalities/`
    } else {
      return `/${language}/placeme-functionalities/`
    }
  }, [name]);

  return (
    <section className="product-learn-more">
      <div className="product-learn-more__inner">
        <p className="product-learn-more__inner--text">
          {RichText.asText(data?.learn_more_header?.raw || [])}
        </p>
        <Link className="product-learn-more__inner--btn" to={getLink() + '/'}>
          {RichText.asText(data?.learn_more_button?.raw || [])} <BtnArrowRight />
        </Link>
      </div>
    </section>
  );
};

export default ProductLearnMore;
