import { PricingFormType } from "components/slices/pricing-form-slice/PricingFormSide"
import { FORM_FIELDS } from "../constants"

interface PostPricingFormProps {
  props: PricingFormType,
  lang: string,
  setModalDisplay: React.Dispatch<React.SetStateAction<boolean>>
}

export const postPricingForm = ({ props, lang, setModalDisplay }: PostPricingFormProps) => {
const { email, message, phone, firstname, lastname, company } = props
const getUrlByLanguage = (lang: string) => {
  switch (lang) {
    case 'pl':
      return 'https://api.hsforms.com/submissions/v3/integration/submit/20363741/ebbc07fe-9ef2-4499-9431-c14647928ef0'
    case 'en-pl':
      return 'https://api.hsforms.com/submissions/v3/integration/submit/20363741/93b74a3f-b623-45ea-bd46-d40ddc05a639'
    default:
      return 'https://api.hsforms.com/submissions/v3/integration/submit/20363741/ebbc07fe-9ef2-4499-9431-c14647928ef0'
  }
}
  const xhr = new XMLHttpRequest()
  const url = getUrlByLanguage(lang)
  const data = {
    fields: [
      {
      name: FORM_FIELDS.EMAIL,
      value: email
      },
      {
      name: FORM_FIELDS.MESSAGE,
      value: message
      },
      {
      name: FORM_FIELDS.FIRST_NAME,
      value: firstname
      },
      {
      name: FORM_FIELDS.LAST_NAME,
      value: lastname
      },
      {
      name: FORM_FIELDS.PHONE,
      value: phone
      },
      {
        name: FORM_FIELDS.COMPANY,
        value: company
      },
    ],
    legalConsentOptions: {
      consent: {
        consentToProcess: true,
        text: 'I agree to allow Example Company to store and process my personal data.',
        communications: [
          {
            value: true,
            subscriptionTypeId: 999,
            text: 'I agree to receive marketing communications from Example Company.'
          }
        ]
      }
    }
  }
  
  const final_data = JSON.stringify(data)
   
  xhr.open('POST', url)
  xhr.setRequestHeader('Content-Type', 'application/json')
  xhr.onreadystatechange = function() {
    if(xhr.readyState == 4 && xhr.status === 200) {
      setModalDisplay(true)
    }
  }
  
  xhr.send(final_data)
}
