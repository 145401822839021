import * as React from 'react'
import './FooterMenu.scss'
import { Link } from 'gatsby'
import { RootState } from 'utils/store/store'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { useMediaQuery } from 'utils/hooks/useMediaQuery'
import { RichText } from 'prismic-reactjs'
import { useFooterData } from '../footerService'
import { SIGN_IN_URL, SIGN_UP_URL, productColors } from 'shared/constants'
import LockIcon from 'assets/lock.svg'

const FooterMenuDiv = styled.div(() => {
  const { tablet } = useMediaQuery()

  return css`
    display: flex;
    gap: ${tablet ? '1rem' : '2rem'};
    flex-wrap: wrap;
  `
})

interface FooterMenuListProps {
  $marginLeft?: string
  $padding?: string
}

const FooterMenuList = styled.ul<FooterMenuListProps>(({ $marginLeft, $padding }) => {
  const { tablet } = useMediaQuery()

  return css`
    min-width: 210px;
    list-style: none;
    margin-left: ${$marginLeft || '0'};
    ${$padding && css`padding: ${$padding};`}
    ${tablet && css`min-width: 170px;`}
    margin-bottom: 0;
  `
})

const FooterMenuListHead = styled.li`
  font-weight: 400 !important;
  font-size: 0.875rem;
  line-height: 1rem;
  margin-bottom: 1.25rem;
  color: ${productColors.dark};
  text-transform: uppercase;
`

const StyledLi = styled.li`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.25rem;
  color: ${productColors.dark};
  margin-bottom: 1.12rem;
  text-decoration: underline;
  cursor: pointer;
`

const StyledLink = styled(Link)`
  color: ${productColors.dark};
`

function FooterMenu() {
  const data = useFooterData()
  const { mobile } = useMediaQuery()
  const { language } = useSelector((state: RootState) => state.i18n)

  return (
    <FooterMenuDiv>
      <FooterMenuList>
        <FooterMenuListHead>
          {RichText.asText(data.footer_products?.richText)}
        </FooterMenuListHead>
        <StyledLi>
          <StyledLink to={`/${language}/placeme/`}>
            {RichText.asText(data.footer_product_placeme?.richText)}
          </StyledLink>
        </StyledLi>
        <StyledLi>
          <StyledLink to={`/${language}/source/`}>
          {RichText.asText(data.footer_product_source?.richText)}
          </StyledLink>
        </StyledLi>
        <StyledLi>
          <StyledLink to={`/${language}/target/`}>
          {RichText.asText(data.footer_product_target?.richText)}
          </StyledLink>
        </StyledLi>
      </FooterMenuList>
      <FooterMenuList>
        <FooterMenuListHead>
          {RichText.asText(data?.footer_resources?.raw)}
        </FooterMenuListHead>
        <StyledLi>
          <StyledLink to={`/${language}/blog/`}>
            {RichText.asText(data?.footer_blog?.raw)}
          </StyledLink>
        </StyledLi>
        {language === 'pl'
          ? (
            <StyledLi>
              <StyledLink to={`/${language}/report/`}>
                {RichText.asText(data?.footer_industry_reports?.raw)}
              </StyledLink>
            </StyledLi>
          )
          : null}
      </FooterMenuList>
      <FooterMenuList>
        <FooterMenuListHead>
          {RichText.asText(data?.footer_other?.raw)}
        </FooterMenuListHead>
        <StyledLi>
          <StyledLink to={`/${language}/success-stories/`}>
            {RichText.asText(data?.footer_customer_stories?.raw)}
          </StyledLink>
        </StyledLi>
        <StyledLi>
          <StyledLink to={`/${language}/career/`}>
            {RichText.asText(data?.footer_recruitment?.raw)}
          </StyledLink>
        </StyledLi>
      </FooterMenuList>
      <FooterMenuList $padding={mobile ? '0' : '2.25rem 0 0'}>
        <StyledLi>
          <StyledLink to={`/${language}/contact/`}>
            {RichText.asText(data.footer_contact?.raw)}
          </StyledLink>
        </StyledLi>
        <StyledLi>
          <StyledLink to={SIGN_UP_URL}>
            {RichText.asText(data.footer_register?.richText)}
          </StyledLink>
        </StyledLi>
      </FooterMenuList>
      <FooterMenuList $padding={mobile ? '0' : '2.25rem 0 0'}>
        <StyledLi>
          <LockIcon />
          <StyledLink to={SIGN_IN_URL}>
            {RichText.asText(data.footer_log_in?.richText)}
          </StyledLink>
        </StyledLi>
      </FooterMenuList>
    </FooterMenuDiv>
  )
}

export default FooterMenu
