import "./SuccessStoryPost.scss";
import React, { FC } from "react";
import { Link } from "gatsby";
import BtnArrowRight from "assets/arrowbtn.svg";
import { useDispatch } from "react-redux";
import { SuccessStory } from "features/success-stories/successStoriesModel";
import { getStoryPostDetails } from "features/success-stories/successStoriesSlice";
import Tags from "../tags/Tags";
import { useLocale } from "locale/useLocale";
export interface SuccessStoryPostProps {
  post: SuccessStory;
}

const SuccessStoryPost: FC<SuccessStoryPostProps> = ({
  post,
}: SuccessStoryPostProps) => {
  const dispatch = useDispatch();
  const locale = useLocale("SuccessStory");

  const link = post?.link;

  return (
    <Link
      to={`${link}/`}
      onClick={() => dispatch(getStoryPostDetails({ link }))}
      className="success-story-post">
      <Tags tags={post?.tags} />
      <div>
        <div className="success-story-post__top">
          <div className="success-story-post__top--logo">
            <img
              src={post?.client_logo?.url ?? ""}
              alt={post?.client_logo?.alt ?? ""}
            />
          </div>
          <div className="success-story-post__top--category">
            {post?.client_industry}
          </div>
        </div>
        <div className="success-story-post__labels">
          {post?.labels?.text?.trim().split(";").filter(point => point !== '').map((label, index) => <div className="success-story-post__labels--label" key={index}>
                {label}
              </div>)}
        </div>
        <h3 className="success-story-post__title">{post?.title}</h3>
        <p className="success-story-post__description">{post?.description}</p>
        <div className="success-story-post__link">
          {locale?.readMore}
          <BtnArrowRight />
        </div>
      </div>
    </Link>
  );
};

export default SuccessStoryPost;
