import * as React from "react";
import "./IntroductionBox.scss";
import { FC, useCallback } from "react";
import { Maybe, PrismicStructuredTextType } from "types/declarations";
import { RichText } from "prismic-reactjs";
import Placeme from "images/placeme-location-contact.svg";
import Target from "images/placeme-target-contact.svg";
import Source from "images/placeme-source-contact.svg";
import styled, { css } from "styled-components";

const Wrapper = styled.div<{ selected: boolean; bgColor: string }>(
  ({ selected, bgColor }) => css`
    &:after {
      width: 100%;
      height: 3px;
      background-color: ${bgColor};
      display: ${selected ? "block" : "none"};
      justify-self: center;
      align-self: center;
      content: "";
      position: relative;
      transform: none;
    }
  `
);


const StyledButton = styled.button<{ selected: boolean, bgColor: string }>(
  ({ selected, bgColor }) => css`
    outline: inherit;
    border: none;
    display: flex;
    height: 10rem;
    width: 18rem;
    flex-direction: column;
    background-color: ${bgColor};
    ${!selected &&
    css`
    cursor: pointer;
      &:hover {
        transform: scale(1.1);
        transition: transform 0.3s ease-in-out;
      }
    `}
  `
);

export interface IntroductionBoxProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  title: string;
  description?: Maybe<PrismicStructuredTextType>;
  bgColor: string;
  selected: boolean;
}

const IntroductionBox: FC<IntroductionBoxProps> = ({
  title,
  description,
  bgColor,
  onClick,
  selected,
}: IntroductionBoxProps) => {
  const getIcon = useCallback(() => {
    if (title?.includes("placeme")) {
      return <img style={{ width: "14px" }} src={Placeme} alt="product logo" />;
    } else if (title?.includes("target")) {
      return <img style={{width: '14px'}} src={Target} alt="product logo"/>;
    } else {
      return <img style={{ width: "14px" }} src={Source} alt="product logo" />;
    }
  }, [title]);

  return (
    <Wrapper selected={selected} bgColor={bgColor}>
      <StyledButton
        selected={selected}
        className="introduction-box"
        bgColor={bgColor}
        onClick={onClick}>
        <p className="introduction-box__link">
          <h3 className="introduction-box__title">
            {getIcon()}
            {title}
          </h3>
          <div className="introduction-box__description">
            <RichText render={description?.raw || []} />
          </div>
        </p>
      </StyledButton>
    </Wrapper>
  );
};

export default IntroductionBox;
