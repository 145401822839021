import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "utils/store/store";
import BlogItem from "features/blog/items/posts/BlogItem";
import "./SearchResults.scss";
import {clearSearch} from '../../blogSlice'

const SearchResults = ({handleClear}: {handleClear?: (isCleared : boolean)=> void }): JSX.Element => {
  const dispatch = useDispatch()
  const { searchResults, searchQuery, allBlogPosts } = useSelector(
    (state: RootState) => state.blog
  );
  const possiblyInteresting = allBlogPosts.filter((_, index) => {
    return index <3
  })

  const deleteSearch = () => {
    dispatch(clearSearch())
    if(handleClear){
    handleClear(true)
    }
  }

  const deleteNoResults = () => {
    if(handleClear){
      handleClear(true)
      }
  }

  return (
    <>
       {searchResults.length > 0 && <p>{searchResults.length} wyników dla frazy <span>{searchQuery}</span><span onClick={deleteSearch}> x</span></p>}
    <div className="search-results">
      {searchResults.map(result => (
        <BlogItem key={result.id} item={result} />
      ))}
      </div>
      {searchResults.length === 0 && (
        <div className='column-wrapper'>
          <div className='no-results'><p>Brak wyników wyszukiwania dla frazy <span>{searchQuery}</span><span onClick={deleteNoResults}>x</span></p></div>
          <h2>Może Cię zainteresować</h2>
          <div className='row-wrapper' >
            {possiblyInteresting.map(result => (
              <BlogItem key={result.id} item={result} />
            ))}
          </div>
        </div>
    )}
    
    </>
  );
};
export default SearchResults;
