import * as React from 'react';
import './IntroductionGridBox.scss';
import { FC } from 'react';
import { RichText } from 'prismic-reactjs';
import { PrismicHomepageDataType } from 'types/declarations';
import IntroductionBox from 'features/homepage/introduciton/introduction-box/IntroductionBox';
import styled, { css } from 'styled-components';
import {
  SectionHeading,
  SectionTitle,
} from 'features/homepage/shared-components/section';
import Button from 'ui/button/Button';
import { useMediaQuery } from 'utils/hooks/useMediaQuery';
import IntroductionBoxMobile from '../introduction-box-mobile/IntroductionBoxMobile';
import { ColorSpan } from '../color-span';

const Wrapper = styled.div(() => {
  const { mobile } = useMediaQuery();
  return css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem 0;
    background-color: #fbf7f4;

    ${mobile &&
    css`
      padding: 1.5rem 0 3rem 0;
    `}
  `;
});
const Container = styled.div(() => {
  const { laptop, desktop } = useMediaQuery();
  return css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    ${(laptop || desktop) &&
    css`
      margin-top: 8rem;
      div:first-child {
        gap: 3rem;
      }
    `}
  `;
});

const ProductSection = styled.div`
  width: 80%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 3rem;
`;

const ProductTabLogo = styled.img`
  max-width: 15rem;
  height: 3rem;
  object-fit: contain;
`;

const ProductDescriptionWrapper = styled.div(() => {
  const { laptop, desktop } = useMediaQuery();
  return css`
    width: 52rem;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    gap: 3rem;
    color: #002163;

    ${(laptop||desktop) && css`
      min-height: 340px;
    `}
  `;
});

const DescriptionButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  a {
    flex: auto;
  }
`;

const ProductTabImage = styled.img(() => {
  const { desktop, laptop } = useMediaQuery();
  return css`
    object-fit: contain;
    ${laptop &&
    css`
      width: 30rem;
      height: 22rem;
    `}
    ${desktop &&
    css`
      width: 40rem;
      height: 32rem;
    `}
  `;
});
export interface IntroductionGridBoxProps {
  data: PrismicHomepageDataType;
}

const IntroductionGridBox: FC<IntroductionGridBoxProps> = ({
  data,
}: IntroductionGridBoxProps) => {
  const [selectedProductIndex, setSelectedProductIndex] = React.useState(0);
  const selectedProduct = data.hero_cards?.at(selectedProductIndex);
  const { mobile, tablet } = useMediaQuery();

  function handleMobileSetSelected(index: number) {
    if (index == selectedProductIndex) {
      index = -1;
    }
    setSelectedProductIndex(index);
  }

  return (
    <Wrapper>
      <SectionTitle>
        {RichText.asText(data.products_section_title?.raw)}
      </SectionTitle>
      <SectionHeading>
        {RichText.asText(data.products_section_heading?.raw)}
      </SectionHeading>
      <Container>
        {mobile || tablet ? (
          data.hero_cards?.map((card, index) => (
            <IntroductionBoxMobile
              card={card}
              key={index}
              title={RichText.asText(card?.hero_card_title?.raw || [])}
              description={card?.hero_card_subtitle}
              bgColor={card?.hero_card_color ?? ''}
              onClick={() => handleMobileSetSelected(index)}
              selected={selectedProductIndex === index}
            />
          ))
        ) : (
          <>
            <div className='introduction__grid'>
              {data.hero_cards?.map((card, index) => (
                <>
                  <IntroductionBox
                    key={index}
                    title={RichText.asText(card?.hero_card_title?.raw || [])}
                    description={card?.hero_card_subtitle}
                    bgColor={card?.hero_card_color ?? ''}
                    onClick={() => setSelectedProductIndex(index)}
                    selected={selectedProductIndex === index}
                  />
                </>
              ))}
            </div>

            <ProductSection>
              <div style={{ width: '45vw', maxWidth: '45rem' }}>
                <ProductTabImage
                  src={selectedProduct?.product_tab_image?.url || undefined}
                  alt={selectedProduct?.product_tab_image?.alt || undefined}
                />
              </div>
              <ProductDescriptionWrapper>
                <ProductTabLogo
                  src={selectedProduct?.product_tab_logo?.url || undefined}
                  alt={selectedProduct?.product_tab_logo?.alt || undefined}
                />
                <ColorSpan
                  text={RichText.asText(
                    selectedProduct?.product_tab_description?.raw
                  )}
                />
                <DescriptionButtonsWrapper>
                  {RichText.asText(
                    selectedProduct?.product_tab_button_1_link?.raw
                  ) && (
                    <Button
                      to={RichText.asText(
                        selectedProduct?.product_tab_button_1_link?.raw
                      )}
                      filled>
                      {RichText.asText(
                        selectedProduct?.product_tab_button_1_text?.raw
                      )}
                    </Button>
                  )}
                  {RichText.asText(
                    selectedProduct?.product_tab_button_2_link?.raw
                  ) && (
                    <Button
                      minWidth='12rem'
                      to={RichText.asText(
                        selectedProduct?.product_tab_button_2_link?.raw
                      )}
                      arrowRight>
                      {RichText.asText(
                        selectedProduct?.product_tab_button_2_text?.raw
                      )}
                    </Button>
                  )}
                </DescriptionButtonsWrapper>
              </ProductDescriptionWrapper>
            </ProductSection>
          </>
        )}
      </Container>
    </Wrapper>
  );
};

export default IntroductionGridBox;
