import React from 'react'
import styled, { css } from 'styled-components'
import { ITagProps } from './@types/ITag.props'
import { TagWrapperProps } from './@types/TagWrapper.props'

const TagWrapper = styled.div<TagWrapperProps>(({
  color, borderColor, padding, fontColor, fontSize, fontWeight, textTransform
}) => {
  return css`
    color: ${fontColor|| 'inherit'};
    padding:${padding || '0.375rem 0.625rem'};
    border-radius: 4rem;
    background-color: ${color};
    border: ${borderColor ? `2px solid ${borderColor}` : 'none'};
    display: flex;
    width: min-content;
    flex-wrap: nowrap;
    white-space: nowrap;
    font-size: ${fontSize || '0.75rem'};
    font-weight:${fontWeight|| '400'};
    letter-spacing: 1px;
    text-transform: ${textTransform || 'none'};
  `
})

 const Tag: React.FC<ITagProps> = ({
  children, color, borderColor, padding, fontColor, fontSize, fontWeight, textTransform
}) => {
  return (
    <TagWrapper
      borderColor={borderColor}
      color={color}
      padding={padding}
      fontColor={fontColor}
      fontSize={fontSize}
      fontWeight={fontWeight}
      textTransform={textTransform}
    >
      {children}
    </TagWrapper>

  )
}
export default Tag