import React, { useState } from 'react'
import { colors } from 'shared/constants'
import styled, { css } from 'styled-components'
import { Maybe, PrismicProductHomeDataBodyReviewsSlice, PrismicProductHomeDataBodyReviewsSliceItem } from 'types/declarations'
import { useMediaQuery } from 'utils/hooks/useMediaQuery'
import Navigation from './components/Navigation'
import CustomerReview from './components/CustomerReview'

const StyledSection = styled.section`
  background-color: ${colors.white};
`

const StyledMainDiv = styled.div(() => {
  const { laptop, desktop, tablet, mobile } = useMediaQuery()

  return css`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    padding: ${tablet ? '30px 25px' : mobile ? '30px 15px' : '60px 84px'};
    max-width: 1440px;
    gap: ${(desktop || laptop) ? '2rem' : '1rem'};
  `
})

interface ReviewsProps {
  slice: PrismicProductHomeDataBodyReviewsSlice
}

function Reviews({ slice }: ReviewsProps) {
  const { primary, items } = slice
  const [selectedReview, setSelectedReview] = useState<Maybe<PrismicProductHomeDataBodyReviewsSliceItem> | undefined>(items?.[0])

  return (
    <StyledSection>
      <StyledMainDiv>
        <CustomerReview primary={primary} selectedReview={selectedReview} />
        <Navigation items={items} selectedReview={selectedReview} setSelectedReview={setSelectedReview} />
      </StyledMainDiv>
    </StyledSection>
  )
}

export default Reviews
