import { FC } from "react";
import * as React from "react";
import TrailSign from "images/trail-sign-outline.svg";
import ProductLocationContent from "images/product-location-content.png";
import "./ContentSections.scss";
import { ContentSectionsDTO } from "ui/content-sections/contentSectionsModel";

export interface ContentSectionsProps {
  data: ContentSectionsDTO;
}

const ContentSections: FC<ContentSectionsProps> = ({
  data,
}: ContentSectionsProps) => { 
  return (
    <section className="product-content" style={data.styles}>
      <div className="product-content__inner">
        {data.sections.map((section, index) => {
          const key = `${index}-${data.uuid}`;
          if (index % 2 === 0) {
            return (
              <div key={key} className="product-content__inner--box-text-left">
                <div className="left">
                  <p>{section.header}</p>
                  <span>{section.subheader}</span>
                  <div className="left-content-items">
                    {section.descriptions.map((description, index) => (
                      <div
                        key={index}
                        style={index === 0 ? section.styles : {}}
                        className={
                          index === 0
                            ? "left-content-item"
                            : "left-content-bottom"
                        }>
                        <img src={TrailSign} alt="" />
                        <p>{description}</p>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="right">
                  <img src={section.image?.url || ProductLocationContent} alt="" />
                </div>
              </div>
            );
          } else {
            return (
              <div key={key} className="product-content__inner--box-text-right">
                <div className="left">
                  <img src={section.image?.url || ProductLocationContent} alt="" />
                </div>
                <div className="right">
                  <p>{section.header}</p>
                  <span>{section.subheader}</span>
                  <div className="right-content-items">
                    {section.descriptions.map((description, index) => (
                      <div
                        key={index}
                        style={index === 0 ? section.styles : {}}
                        className={
                          index === 0
                            ? "right-content-item"
                            : "right-content-bottom"
                        }>
                        <img src={TrailSign} alt="" />
                        <p>{description}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            );
          }
        })}
      </div>
    </section>
  );
};

export default ContentSections;
