import React, { useCallback, useMemo, useState } from 'react'
import { colors, productColors } from 'shared/constants'
import styled, { css } from 'styled-components'
import { PrismicProductHomeDataBodyPricingFormSlice } from './PricingFormTypes'
import { RichText } from 'prismic-reactjs'
import { useMediaQuery } from 'utils/hooks/useMediaQuery'
import { useLocale } from 'locale/useLocale'
import { emailRegex } from 'utils/constants'
import { useSelector } from 'react-redux'
import { RootState } from 'utils/store/store'
import FormPopup from 'components/shared-components/popups/form-popup/FormPopup'
import { postPricingForm } from 'features/pricing/utils/postPricingForm'
import { FORM_FIELDS } from 'features/pricing/constants'

const FormWrapper = styled.div(() => {
  const { tablet, mobile } = useMediaQuery()

  return css`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colors.white};
    padding: ${tablet ? '2rem 1rem' : mobile ? '1.5rem 1rem ' : '2.6rem'};
  `
})

const StyledForm = styled.form(() => {
  const { mobile } = useMediaQuery()

  return css`
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    ${!mobile && css`width: 60%;`}
  `
})

const StyledInput = styled.input(() => css`
  margin-bottom: 2.5rem;
  height: 2.8rem;
  padding: 0.75rem;
  border: 1px solid ${colors.gray.light2};
  border-radius: 5px;
  width: 100%;
  outline: none;
  ::placeholder {
    color: ${colors.gray.dark};
  }
  &:not(:placeholder-shown):invalid {
    border: 1px solid ${colors.red};
  }
  &:hover {
    border: 1px solid ${colors.gray.light};
  }
`)

interface SubmitButtonProps {
  disabled: boolean
}

const SubmitButton = styled.button<SubmitButtonProps>(({ disabled }) => css`
  background-color: ${productColors.violet.dark};
  color: ${colors.white};
  padding: 0.5rem 0.75rem;
  min-width: 7.5rem;
  min-height: 2.75rem;
  font-size: 1rem;
  font-weight: 400;
  border-radius: 0.375rem;
  border: 1px solid transparent; 
  width: fit-content;
  box-sizing: border-box;
  margin-top: 0.5rem;
  cursor: pointer;

  ${disabled && css`
    pointer-events: none;
    opacity: 0.2;
  `}

  :hover {
    color: ${colors.main};
    background-color: ${colors.white};
    border: 1px solid ${colors.main};
  }
`)

const FormTitle = styled.p(() => css`
  color: ${colors.black};
  font-weight: 500;
  margin-bottom: 2.5rem;
`)

const initialFormState = {
  firstname: '',
  lastname: '',
  email: '',
  phone: '',
  message: '',
  company: ''
}

export interface PricingFormType  {
  firstname: string,
  lastname: string,
  email: string,
  phone: string,
  message: string,
  company: string
}

const PricingFormSide: React.FC<{ slice: PrismicProductHomeDataBodyPricingFormSlice }> = ({ slice }) => {
  const locale = useLocale('Pricing')
  const { language } = useSelector((state: RootState) => state.i18n)
  const [pricingFormState, setPricingFormState] = useState<PricingFormType>(initialFormState)
  const { firstname, lastname, email, phone, message, company } = pricingFormState
  const [infoModalDisplay, setModalDisplay] = useState(false)

  const setFormValue = useCallback((key: string, value: string) => {
    setPricingFormState((prevState) => ({
      ...prevState,
      [key]: value,
    }))
  }, [pricingFormState])

  const formDisabled = useMemo(() => {
    return !firstname || !lastname || !email || !phone || !message || !company || !emailRegex.test(email)
  }, [lastname, firstname, email, phone, message, company])

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    if (formDisabled) return
    postPricingForm({ 
      props: pricingFormState, 
      lang: language, 
      setModalDisplay: setModalDisplay
    })
  }

  return (
    <FormWrapper>
      {infoModalDisplay 
        ? <FormPopup message={locale?.formPopupMessage} closePopup={() => setModalDisplay(false)} />
        : null 
      }
      <StyledForm onSubmit={handleSubmit}>
        <FormTitle>{RichText.asText(slice?.primary?.form_description?.richText)}</FormTitle>
        <StyledInput
          type='text'
          required
          placeholder={`*${locale?.name}`}
          value={firstname}
          onChange={(e) => setFormValue(FORM_FIELDS.FIRST_NAME, e.target.value)}
        />
        <StyledInput
          type='text'
          required
          placeholder={`*${locale?.surname}`}
          value={lastname}
          onChange={(e) => setFormValue(FORM_FIELDS.LAST_NAME, e.target.value)}
        />
         <StyledInput
          type='text'
          required
          placeholder={`*${locale?.company}`}
          value={company}
          onChange={(e) => setFormValue(FORM_FIELDS.COMPANY, e.target.value)}
        />
        <StyledInput
          type='email'
          pattern='[^@\s]+@[^@\s]+\.[^@\s]+'
          required
          placeholder={`*${locale?.email}`}
          value={email}
          onChange={(e) => setFormValue(FORM_FIELDS.EMAIL, e.target.value)}
        />
        <StyledInput 
          type='tel'
          required
          placeholder={`*${locale?.phoneNumber}`}
          value={phone}
          onChange={(e) => setFormValue(FORM_FIELDS.PHONE, e.target.value)}
        />
        <StyledInput
          type='text'
          required placeholder={`*${locale?.industry}`}
          value={message}
          onChange={(e) => setFormValue(FORM_FIELDS.MESSAGE, e.target.value)} 
        />
        <SubmitButton disabled={formDisabled} type='submit'>{RichText.asText(slice?.primary?.submit_button_text?.richText)}</SubmitButton>
      </StyledForm>
    </FormWrapper>
  )
}

export default PricingFormSide
