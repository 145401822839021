import { graphql, useStaticQuery } from "gatsby";
import {
  PrismicClients,
  PrismicClientsDataType,
  PrismicClientsEdge,
} from "types/declarations";
import { useSelector } from "react-redux";
import { RootState } from "utils/store/store";
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews";

export const useClientsData = (): PrismicClientsDataType => {
  const { language } = useSelector((state: RootState) => state.i18n);
  const resultS = useStaticQuery(graphql`
    {
      allPrismicClients {
        totalCount
        edges {
          node {
            lang
            _previewable
            data {
              clients_header {
                raw
              }
              clients_subtitle {
                raw
              }
              client_images {
                client_image {
                  url
                  alt
                }
              }
              client_carousel_cta_button_text {
                raw
              }
              client_carousel_cta_button_url {
                raw
              }
            }
          }
        }
      }
    }
  `);
  const {data: result} = useMergePrismicPreviewData(resultS)
  return result.allPrismicClients.edges
    .map((edge: PrismicClientsEdge) => edge.node)
    .filter((node: PrismicClients) => node.lang === language)
    .map((node: PrismicClients) => node.data)[0];
};
