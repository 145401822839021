import * as React from 'react'
import MobileHamburger from 'assets/navbar-mobile.svg'
import MobileHamburgerClose from 'assets/mobile-hamburger-close.svg'
import LogoSvg from 'assets/logos/dataplaceai-color.svg'
import { Link } from 'gatsby'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'utils/store/store'
import { useLocale } from 'locale/useLocale'
import LanguageSwitcher from 'utils/i18n/switcher/LanguageSwitcher'
import FooterGridBox from 'features/footer/footer-grid-box/FooterGridBox'
import MobileNavbarItem from './MobileNavbarItem'
import styled, { css } from 'styled-components'

const NavElementWrapper = styled.div`
  padding: 14px 0;
  margin: 0;
`

const StyledUl = styled.ul`
  padding: 14px 0;
  margin: 0;
`

interface ExpandedMenuProps {
  $isOpen: boolean
}

const ExpandedMenu = styled.div<ExpandedMenuProps>(({ $isOpen }) => `
  display: none;
  flex-direction: column;
  padding: 14px 0 0 0;

  ${$isOpen && css`display: flex;`}
`)

interface StyledLinkProps {
  $isActive?: boolean
  $nested?: boolean
}

const StyledLink = styled(Link)<StyledLinkProps>(({ $isActive, $nested }) => css`
  text-decoration: none;
  font-family: 'Inter';
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #001d62;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${$nested && css`padding: 14px 20px 14px 20px;`}

  ${$isActive && css`
    text-decoration: underline !important;
    text-underline-offset: 0.15rem;`
  }
`)

const MobileMenuBottom = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 20px 20px 25px;
`

interface MobileMenuWrapperProps {
  $isActive?: boolean
}

const MobileMenuWrapper = styled.div<MobileMenuWrapperProps>(({ $isActive }) => css`
  transform: translateX(150%);
  transition: transform 0.3s ease-in-out;
  position: fixed;
  overflow-y: auto;
  background-color: #fff;
  border-radius: 8px;
  width: 100%;
  left: 0%;
  top: 0%;
  box-shadow: 0px 0px 35px rgba(36, 33, 84, 0.12);
  
  @media (max-width: 768px) {
    height: 100%;
    padding-bottom: 50px;
  }

  ${$isActive && css`
    transform: translateX(0);
    transition: transform 0.3s ease-in-out;
  `}
`)

const MobileMenuTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px 20px;
  border-bottom: 1px solid #E9E7E9;

  @media (max-width: 650px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const StyledLogoSvg = styled(LogoSvg)`
  height: 40px;
  width: 190px;
`

const StyledMobileHamburgerClose = styled(MobileHamburgerClose)`
  width: 45px;
  height: 45px;
  margin-right: -7.5px;

  @media (max-width: 650px) {
    position: absolute;
    right: 10px;
    top: 15px;
  }
`

const TopButtons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: 1px solid #E9E7E9;
  padding: 2rem 0.7rem;
  gap: 0.5rem;
`

interface StyledButtonLinkProps {
  $first?: boolean
  $last?: boolean
}

const StyledButtonLink = styled.a<StyledButtonLinkProps>(({ $first, $last }) => css`
  width: calc(50% - 9px);
  min-width: fit-content;
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
  padding: 16px 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #001D62;
  filter: drop-shadow(0px 0px 9px rgba(0, 33, 99, 0.145023));
  border-radius: 5px;
  text-decoration: none;

  @media (max-width: 560px) {
    padding: 10px 19px;
    font-size: 16px;
    line-height: 19px;
  }

  ${$first && css`
    color: #001D62;
    background-color: transparent;
    transition-property: color, background-color;
    transition: 0.3s ease-in-out;
  `}

  ${$last && css`
    color: #fff;
    background-color: #001d62;
  `}
`)

const StyledMobileHamburger = styled(MobileHamburger)`
  height: 45px;
  width: 45px;
  margin-right: -7.5px;
`

const LanguageSwitcherWrapper = styled.div`
  margin: 0;
  padding: 32px 0 20px 0;
  display: flex;
  justify-content: flex-start;
  border-top: 1px solid #e0e0e4;
`

const MobileMenu: FC = () => {
  const [open, setOpen] = React.useState(false)
  const [openProducts, setOpenProducts] = React.useState(false)
  const [openResource, setOpenResource] = React.useState(false)
  const { language } = useSelector((state: RootState) => state.i18n)
  const locale = useLocale('MobileMenu')

  const checkIfProductPage = () => {
    return (
      location?.pathname?.includes('placeme') ||
      location?.pathname?.includes('target') ||
      location?.pathname?.includes('source')
    );
  };

  return (
    <>
      <StyledMobileHamburger onClick={() => setOpen(!open)}/>
      <MobileMenuWrapper $isActive={open}>
        <MobileMenuTop>
          <Link aria-label='dataplace home page' to={'/' + language + '/'}>
            <StyledLogoSvg />
          </Link>
          <StyledMobileHamburgerClose onClick={() => setOpen(!open)} />
        </MobileMenuTop>
        <TopButtons>
          <StyledButtonLink $first href='https://app.dataplace.ai/auth/sign-in'>{locale?.logIn}</StyledButtonLink>
          <StyledButtonLink $last href='https://app.dataplace.ai/auth/sign-up'>{locale?.getStarted}</StyledButtonLink>
        </TopButtons>
        <MobileMenuBottom className='mobile-menu__bottom'>
          <StyledUl>
            <MobileNavbarItem
              onClick={() => setOpenProducts(!openProducts)}
              showDropdownArrow
              isActive={checkIfProductPage()}
              isOpen={openProducts}
            >
              {locale?.products}
            </MobileNavbarItem>
            <ExpandedMenu $isOpen={openProducts}>
              <FooterGridBox isBig={false} />
            </ExpandedMenu>
          </StyledUl>
          {(language === 'pl' || language === 'en-pl')
            ? (
              <NavElementWrapper>
                <StyledLink to={`/${language}/success-stories/`} $isActive={location?.pathname?.includes('success-stories')}>
                  {language === 'pl' ? 'Historie klientów' : 'Success Stories'}
                </StyledLink>
              </NavElementWrapper>
            )
            : null}
          <StyledUl>
            <MobileNavbarItem
              onClick={() => setOpenResource(!openResource)}
              showDropdownArrow
              isActive={location?.pathname?.includes('blog') || location?.pathname?.includes('report')}
              isOpen={openResource}
            >
              {locale?.resources}
            </MobileNavbarItem>
            <ExpandedMenu $isOpen={openResource}>
              <StyledLink $nested to={`/${language}/blog/`}>{locale?.blog}</StyledLink>
              {language === 'pl'
                ? (
                  <StyledLink $nested to={`/${language}/report/`}>{locale?.raports}</StyledLink>
                )
                : null}
            </ExpandedMenu>
          </StyledUl>
          <NavElementWrapper>
            <StyledLink to={`/${language}/contact/`} $isActive={location?.pathname?.includes('contact')}>
              {locale?.contactPage}
            </StyledLink>
          </NavElementWrapper>
          <LanguageSwitcherWrapper>
            <LanguageSwitcher />
          </LanguageSwitcherWrapper>
        </MobileMenuBottom>
      </MobileMenuWrapper>
    </>
  )
}

export default MobileMenu
