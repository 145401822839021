import * as React from "react";
import { Link } from "gatsby";
import "./Layout.scss";
import { FC } from "react";
import Header from "components/shared-components/header/Header";
import Footer from "features/footer/Footer";
import RootContainer from "containers/RootContainer";
import CookieConsent from 'react-cookie-consent';
import { RootState } from "utils/store/store";
import { useSelector } from 'react-redux'
import Microdata from "utils/seo/Microdata";
import styled, { css } from "styled-components";

const StyledMain = styled.main<{ backgroundColor?: string }>(({ backgroundColor }) => css`
  ${backgroundColor && css`background-color: ${backgroundColor};`}
`)

export interface LayoutProps {
  children: React.ReactNode;
  backgroundColor?: string
}

const Layout: FC<LayoutProps> = ({ children, backgroundColor }: LayoutProps) => {
  const { language } = useSelector((state: RootState) => state.i18n);

  return (
    <RootContainer>
      <Header />
      <StyledMain backgroundColor={backgroundColor}>{children}</StyledMain>
      <Footer />
      {language === 'pl' ?
        <CookieConsent
          disableStyles
          buttonClasses='cookie-button'
          containerClasses='cookie-container'
          location="bottom"
          buttonText="Rozumiem"
          cookieName="gatsby-gdpr-google-tagmanager">
          Ta strona korzysta z cookies. Szczegóły znajdziesz na stronie <Link to={`${language}/privacy-policy/`}>Polityka Prywatności</Link>.
        </CookieConsent> :
        <CookieConsent
          disableStyles
          buttonClasses='cookie-button'
          containerClasses='cookie-container'
          location="bottom"
          buttonText="I understand"
          cookieName="gatsby-gdpr-google-analytics">
          This site is using cookies. Details can be found on the <Link to={`${language}/privacy-policy/`}> Privacy Policy</Link> page.
        </CookieConsent>}
      <Microdata />
    </RootContainer>
  );
};

export default Layout;
