import * as React from "react";
import "./Introduction.scss";
import { useIntroductionData } from "features/homepage/introduciton/introductionService";
import IntroductionGridBox from "features/homepage/introduciton/introduction-grid-box/IntroductionGridBox";

const Introduction = (): JSX.Element => {
  const data = useIntroductionData();

  return (
      <IntroductionGridBox data={data} />
  );
};

export default Introduction;
