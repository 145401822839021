// import { Link } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import React, { MutableRefObject, useRef } from 'react';
import "./About.scss";
import { useAboutData } from './aboutService';
// import Instagram from "assets/instagram.svg";
import BtnArrowRight from "assets/arrowbtn.svg";
// import { useLocale } from 'locale/useLocale';

const About = (): JSX.Element => {
  // const locale: Record<string, string | Record <string, string>> = useLocale("Career");
  const about = useAboutData();
  const scrollContainer = useRef() as MutableRefObject<HTMLDivElement>;

  const scroll = (scrollOffset: number) => {
    scrollContainer.current.scrollLeft += scrollOffset;
  };

  return (
    <section className="about">
      <div className="about__inner">
        <div className="about__inner--left">
          <div className="about__inner--left--title">
            <h2>{RichText.asText(about?.about_title?.raw || [])}</h2>
          </div>
          <div className="about__inner--left--description">
            <p>{RichText.asText(about?.about_description?.raw || [])}</p>
          </div>
          {/* <div className="about__inner--left--social">
            <div className="about__inner--left--social---label">
              <Instagram />
              {locale?.instagram}
            </div>
            <Link className="about__inner--left--social---link" to={RichText.asText(about?.about_link?.raw) ?? ''}>
              <p>{RichText.asText(about?.about_instagram?.raw)}</p>
            </Link>
          </div> */}
        </div>
        <div className="about__inner--right">
          <img src={about?.about_image?.url ?? ""} alt={about?.about_image?.alt ?? ""} />
        </div>
      </div>
      <div className="about__carousel">
        <div onClick={() => scroll(-400)} className="about__carousel--control about__carousel--control---left">
          <BtnArrowRight />
        </div>
        <div className="about__carousel--images" ref={scrollContainer}>
          {about?.about_gallery?.map((img, index) => <img key={index} src={img?.gallery_item?.url ?? ""} alt={img?.gallery_item?.alt ?? ""} />)}
        </div>
        <div onClick={() => scroll(400)} className="about__carousel--control about__carousel--control---right">
          <BtnArrowRight />
        </div>
      </div>
    </section>
  )
}
export default About;
