import ProductLayout from "containers/productLayout/ProductLayout";
import Seo from "utils/seo/Seo";
import ProductShowcase from "features/product-page/product-homepage/product-showcase/ProductShowcase";
import ProductIntroduction from "features/product-page/product-homepage/product-introduction/ProductIntroduction";
import ProductContent from "features/product-page/product-homepage/product-content/ProductContent";
import Clients from "features/clients/Clients";
import ProductLearnMore from "features/product-page/product-homepage/product-learn-more/ProductLearnMore";
import ProductPurposes from "features/product-page/product-homepage/product-purposes/ProductPurposes";
import ProductCta from "features/product-page/product-homepage/product-cta/ProductCta";
import Faq from "features/faq/Faq";
import React, { useEffect, useState } from "react";
import { ProductPageProps, useProductHomeData } from "features/product-page/product-homepage/productHomeService";
import HubspotMeetingModal from 'ui/HubspotMeetingModal/HubspotMeetingModal'
import { navigate, PageProps } from 'gatsby';
import {useMediaQuery} from "utils/hooks/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "utils/store/store";
import { Language, setLanguage } from "utils/i18n/i18nSlice";
import { checkForPopup } from "components/shared-components/popups/generic-popup/checkForPopupService";
import { GenericPopup } from "components/shared-components/popups/generic-popup/GenericPopup";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
const linkResolver = require('../../../../../linkResolver.js');

function ProductHome(props: PageProps) {
  const name = (props.pageContext as ProductPageProps).productName;
  const { language } = useSelector((state: RootState) => state.i18n);
  const productData = useProductHomeData(props.pageContext as ProductPageProps);
  const dispatch = useDispatch();
  const displayPopup  = checkForPopup()?.page?.text?.split(';')?.filter(p => p !== '')?.includes('product_home');
  
  const [showMeeting, setShowMeeting] = useState(false);

  const {mobile} = useMediaQuery()

  const handleShowMeeting = () => {
    if (!showMeeting && !mobile) {
      setShowMeeting(true);
    } else {
      navigate(`/${language}/contact/`)
    }
  };
  const handleCloseMeeting = () => {
    if (showMeeting) {
      setShowMeeting(false);
    }
  };

  useEffect(() => {
    if (typeof window !== `undefined`) {
      const url = location?.pathname?.split('/').filter(item => item !== '')
  
      const findLangInUrl = (): number => url.findIndex(item => item === 'en-pl' || 'pl' || 'fr-fr' || 'de-de');
      const urlLanguage = url[findLangInUrl()] as Language
      dispatch(setLanguage(urlLanguage));
    }
  }, []);

  return (
    <ProductLayout handleHubspot={handleShowMeeting} productName={name}>
      <Seo title={productData?.seo_title?.text} description={productData?.seo_description?.text} />

      { displayPopup && <GenericPopup /> }

      {showMeeting && <HubspotMeetingModal
        closeMeeting={handleCloseMeeting}
        left='25vw'
        show={showMeeting}
        top='8vh'
      />}
      <ProductShowcase handleHubspot={handleShowMeeting} productName={name} />
      <ProductIntroduction productName={name} />
      <ProductContent productName={name} />
      <Clients />
      {name === 'placeme' && <ProductLearnMore productName={name} />}
      <ProductPurposes productName={name} />
      <ProductCta handleHubspot={handleShowMeeting} productName={name} />
      {(language === 'pl' || language === 'en-pl') && <Faq />}
    </ProductLayout>
  );
};

export default withPrismicPreview(ProductHome, [
  {
    repositoryName: 'carcavelos',
    linkResolver: linkResolver.linkResolver,
  },
]);
