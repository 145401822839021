import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { languages } from 'shared/constants';

export type Language = (typeof languages)[keyof typeof languages];

export interface I18nState {
  language: Language;
}

export const detectLanguage = createAsyncThunk(
  'i18n/detectLanguage',
  async (_, thunkAPI) => {
    let language: Language = languages.en
    if (typeof window === 'undefined') return thunkAPI.dispatch(setLanguage(language));
    const windowLanguage = window.navigator.language;
    if (windowLanguage.includes('pl')) {
      language = languages.pl;
    }
    else if (windowLanguage.includes('de')) {
      language = languages.de;
    }
    else if (windowLanguage.includes('fr')) {
      language = languages.fr;
    }
    return thunkAPI.dispatch(setLanguage(language))
  }
);

const initialState = {
  language: languages.pl,
} as I18nState;

const i18nSlice = createSlice({
  name: 'i18n',
  initialState,
  reducers: {
    setLanguage(state, action: PayloadAction<Language>) {
      state.language = action.payload;
    },
  },
});

export const { setLanguage } = i18nSlice.actions;
export default i18nSlice.reducer;
