import * as React from "react";
import "./Showcase.scss";
import { useSelector } from "react-redux";
import { RootState } from "utils/store/store";
import Breadcrumbs from "features/success-stories/items/breadcrumbs/Breadcrumbs";
import { useSSData } from "features/success-stories/successStoriesService";

const Showcase = (): JSX.Element => {
  const { language } = useSelector((state: RootState) => state.i18n);
  const ss = useSSData();

  return (
    <section className="success-story-showcase">
      {language === "pl" ? (
        <Breadcrumbs
          firstName="Klienci"
          secondName="Historie klientów"
          secondLink="success-stories"
        />
      ) : (
        <Breadcrumbs
          firstName="Clients"
          secondName="Success stories"
          secondLink="success-stories"
        />
      )}
      <div className="success-story-showcase__inner">
        <div className="success-story-showcase__inner--left">
          <div>
            <p className="success-story-showcase__subtitle">{ss?.subtitle?.text}</p>
            <h1 className="success-story-showcase__title">{ss?.title?.text}</h1>
          </div>
        </div>
        <div className="success-story-showcase__inner--right">
          <img src={ss?.image?.url ?? ""} alt={ss?.image?.alt ?? "colorful graphic"} />
        </div>
      </div>
    </section>
  );
};

export default Showcase;
