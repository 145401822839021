import React from "react";
import "./Breadcrumbs.scss";
import BtnArrowRight from "assets/arrowbtn.svg";
import { Link } from "gatsby";
import { FC } from "react";
import { useSelector } from "react-redux";
import { RootState } from "utils/store/store";

export interface BreadcrumbsProps {
  first: string;
  firstLink: string;
  second?: string;
  secondLink?: string;
  third?: string;
}

const Breadcrumbs: FC<BreadcrumbsProps> = ({
  first,
  firstLink,
  second,
  secondLink,
  third
}: BreadcrumbsProps) => {
  const { language } = useSelector((state: RootState) => state.i18n);

  return (
    <div className="breadcrumbs">
      <Link to={'/' + language + '/' + firstLink  + '/'}><p>{first}</p></Link>
      {second ? <Link to={'/' + language + '/' + secondLink  + '/'}><p className={!third ? "last" : ""}><BtnArrowRight/> {second}</p></Link> : ''}
      {third ? <p className="last"><BtnArrowRight/> {third}</p> : ''}
    </div>
  );
};
export default Breadcrumbs;
