import * as React from "react";
import "./CaseStudies.scss";
import BtnArrowRight from "assets/arrowbtn.svg";
import { Link } from "gatsby";
import {
  PrismicHomepageCaseStudiesGroupType,
  PrismicProductBenefitsCaseStudiesGroupType,
  PrismicProductFunctionalityCaseStudiesGroupType,
  PrismicReportCaseStudiesGroupType,
} from "types/declarations";
import { FC } from "react";
import { RichText } from "prismic-reactjs";
import Arrow from "images/arrow-right.svg";
import { useSelector } from "react-redux";
import { RootState } from "utils/store/store";

export interface CaseStudyBoxProps {
  study: PrismicHomepageCaseStudiesGroupType | PrismicReportCaseStudiesGroupType | PrismicProductFunctionalityCaseStudiesGroupType | PrismicProductBenefitsCaseStudiesGroupType | null;
}

const CaseStudyBox: FC<CaseStudyBoxProps> = ({ study }: CaseStudyBoxProps) => {
  const points = RichText.asText(study?.case_study_description?.raw || []).trim().split(';').filter(point => point !== '');
  const { language } = useSelector((state: RootState) => state.i18n);

  return (
    <div className="case-study-box">
      <div style={{ position: "relative" }}>
        <div className="case-study-box__tag">{RichText.asText(study?.case_study_tag?.raw || [])}</div>
      </div>
      <img
        src={study?.case_study_image?.url ?? ""}
        className="case-study-box__img" alt="product logo" 
      />
      <div className="case-study-box__inner">
        <div className="case-study-box__inner__top">
          <h4 className="case-study-box__inner__top--title">
            {RichText.asText(study?.case_study_header?.raw || [])}
          </h4>
          <div className="case-study-box__inner__top--description">
            {points?.map((point, index) => (
              <p
                key={index}
                style={{
                  margin: "10px 0",
                  display: "flex",
                  alignItems: "center",
                }}>
                <img src={Arrow} alt="arrow" /> {point}
              </p>
            ))}
          </div>
        </div>
        <Link className="case-study-box__inner--link" to={`/${language}/${RichText.asText(study?.case_study_link?.raw || [])}/`}>
          {RichText.asText(study?.case_study_button?.raw || [])}
          <BtnArrowRight />
        </Link>
      </div>
    </div>
  );
};

export default CaseStudyBox;
