import * as React from 'react';
import './FooterBox.scss';
import { Link } from 'gatsby';
import Placeme from 'images/placeme-location-contact.svg';
import Target from 'images/placeme-target-contact.svg';
import Source from 'images/placeme-source-contact.svg';
import { useSelector } from 'react-redux';
import { RootState } from 'utils/store/store';
import { RichText } from 'prismic-reactjs';
import { useFooterData } from '../footerService';

export interface FooterBoxProps {
  title: string;
  bgColor: string;
  href: string;
  isBig: boolean;
  iconLocation?: boolean;
  iconNavigator?: boolean;
  iconMarketing?: boolean;
  iconNetwork?: boolean;
  iconData?: boolean;
}

function FooterBox({
  title,
  bgColor,
  href,
  iconLocation,
  iconNavigator,
  iconData,
  isBig,
}: FooterBoxProps) {
  const data = useFooterData();
  const isComingSoon = false;
  const { language } = useSelector((state: RootState) => state.i18n);

  return (
    <Link
      to={'/' + language + href + '/'}
      className='footer-box footer-box__link'
      aria-disabled={isComingSoon}
      style={{
        backgroundColor: bgColor ? bgColor : '#362FB7',
        opacity: isComingSoon ? '0.4' : '',
        minHeight: isBig ? '52px' : '46px',
        justifyContent: 'center',
      }}>
      {isComingSoon && (
        <div style={{ position: 'absolute' }}>
          <div
            style={{
              position: 'relative',
              color: 'white',
              fontSize: '26px',
              transform: 'rotate(15deg)',
              textTransform: 'uppercase',
            }}>
            {RichText.asText(data?.footer_coming_soon?.raw)}
          </div>
        </div>
      )}

      <div className='footer-box__title'>
        {iconLocation && <img src={Placeme} alt='product logo' />}
        {iconNavigator && <img src={Target} alt='product logo' />}
        {iconData && <img src={Source} alt='product logo' />}
        {title}
      </div>
    </Link>
  );
}

export default FooterBox;
