import React, { useCallback } from "react";
import "./LanguageSwitcherDropdown.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "utils/store/store";
import { Language, setLanguage } from "utils/i18n/i18nSlice";
import NavbarItem from "features/navbar/NavbarItem";
import NavbarArrowBottom from "assets/navbar-arrow-bottom.svg";
import PL from "assets/flags/pl.svg";
import EN from "assets/flags/gb.svg";
import { navigate } from "gatsby";

const LanguageSwitcherDropdown = (): JSX.Element => {
  const dispatch = useDispatch();
  const { language } = useSelector((state: RootState) => state.i18n);
  const url = location?.pathname?.split('/').filter(item => item !== '');
  
  const findLangInUrl = (): number => url.findIndex(item => item === 'en-pl' || 'pl' || 'fr-fr' || 'de-de');

  const setLangBasedOnUrl = (lang: string) => {
    url.splice(findLangInUrl(), 1, lang);
    navigate(`/${url.join('/')}`);
  }

  const onLanguageClick = useCallback((language: Language) => {
    dispatch(setLanguage(language));
    setLangBasedOnUrl(language);
  }, []);

  const checkIfRestrictedPage = (language: string) => {
    if (language === "en") {
      return (
        location?.pathname?.includes("career") ||
        location?.pathname?.includes("report") 
      );
    } else return null;
  };

  const displayLanguage = (lang: string): JSX.Element | null => {
    if (lang === "pl") {
      return (
        <span className="lang-icon">
          <PL /> PL
        </span>
      );
    }
    if (lang === "en-pl") {
      return (
        <span className="lang-icon">
          <EN /> EN
        </span>
      );
    }
    return null;
  };

  return (
    <NavbarItem showDropdownArrow className="language-switch">
      {displayLanguage(language)}
      <div className="dropdown-content">
        {checkIfRestrictedPage("pl") ? null : (
          <>
            <div
              onClick={() => onLanguageClick("pl")}
              className={`switcher-language
            ${language === "pl" ? "switcher-language__selected" : ""}`}>
              <div
                className={`switcher-language-arrow
                ${
                  language === "pl" ? " switcher-language-arrow--active" : ""
                }`}>
                <NavbarArrowBottom />
              </div>
              <span className="lang-icon">
                <PL /> PL
              </span>
            </div>
          </>
        )}
        {checkIfRestrictedPage("en") ? null : (
          <>
            <div
              onClick={() => onLanguageClick("en-pl")}
              className={`switcher-language
            ${language === "en-pl" ? "switcher-language__selected" : ""}`}>
              <div
                className={`switcher-language-arrow
                ${
                  language === "en-pl" ? " switcher-language-arrow--active" : ""
                }`}>
                <NavbarArrowBottom />
              </div>
              <span className="lang-icon">
                <EN /> EN
              </span>
            </div>
          </>
        )}
      </div>
    </NavbarItem>
  );
};

export default LanguageSwitcherDropdown;
