import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import "./Select.scss";
import Placeme from "images/placeme-location-contact.svg";
import Target from "images/placeme-target-contact.svg";
import Source from "images/placeme-source-contact.svg";
import Dot from "assets/navbar-arrow-bottom.svg";
import { useLocale } from "locale/useLocale";

export interface SelectProps {
  options: string[];
  index?: number;
  defaultValue: string;
  onChange: (value: string) => void;
  containerStyles?: Record<string, string>;
}

const Select: FC<SelectProps> = ({
  options,
  index,
  onChange,
  defaultValue,
  containerStyles,
}: SelectProps) => {
  const containerRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const [focus, setFocus] = useState<boolean>(false);
  const [chosen, setChosen] = useState<boolean>(false);
  const [currentValue, setCurrentValue] = useState<string | undefined>(
    defaultValue
  );
  const locale = useLocale("Select");

  useEffect(() => {
    setCurrentValue(defaultValue);
  }, [defaultValue])

  useEffect(()=>{
  setCurrentValue(defaultValue)
  },[defaultValue])

  const onOpen = useCallback(() => {
    setFocus(true);
  }, []);

  const onOptionClick = useCallback(
    (option: string) => {
      setFocus(false);
      setChosen(true);
      setCurrentValue(option);
      onChange(option);
    },
    [onChange]
  );

  const handleMouseClick = useCallback(
    (e) => {
      if (containerRef.current?.contains(e.target)) {
        if (chosen) {
          setFocus(false);
          setChosen(false);
        } else {
          setFocus(true);
        }
      } else {
        setChosen(false);
        setFocus(false);
      }
    },
    [containerRef, chosen]
  );

  const getProduct = useCallback((value: string) => {
    let color;
    let icon;
    if (value.includes("placeme")) {
      color = "#362fb7";
      icon = Placeme;
    } else if (value.includes("source")) {
      color = "#6830b2";
      icon = Source;
    } else if (value.includes("target")) {
      color = "#cc3c00";
      icon = Target;
    } else {
      return value;
    }

    return (
      <div
        className="placeme-product-option"
        style={{ backgroundColor: color }}>
        <div className="placeme-product-option__img">
          <img src={icon} alt="product icon" />
        </div>
        <div>{value}</div>
      </div>
    );
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleMouseClick);
    return () => {
      document.removeEventListener("mousedown", handleMouseClick);
    };
  }, [handleMouseClick]);

  return (
    <div
      className="select-container"
      onClick={onOpen}
      ref={containerRef}
      style={{
        zIndex: index || 0,
        ...containerStyles,
      }}>
      <div className="select-input">
        {getProduct(currentValue ?? (locale?.choose))}
        <div className="select-dot">
          <Dot />
        </div>
      </div>
      {focus && !chosen && (
        <div className="select-options-container">
          {options?.map((option: string, index: number) => {
              return (
                <div
                  onClick={() => onOptionClick(option)}
                  className="select-option"
                  key={index}>
                  {getProduct(option)}
                </div>
              );
          })}
        </div>
      )}
    </div>
  );
};
export default Select;
