import React, { useCallback } from "react";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "@reach/router";
import { toggleFilter } from "features/blog/blogSlice";
import { SearchFilter } from "features/blog/blogModel";
import { navigate } from "gatsby";
import { RootState } from "utils/store/store";

export interface FilterProps {
  filter: SearchFilter;
}

const Filter: FC<FilterProps> = ({ filter }: FilterProps) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { language } = useSelector((state: RootState) => state.i18n);

  const onFilterClick = useCallback(() => {
    if (filter.link) {
      navigate(`/${filter.link}`);
    } else {
      dispatch(toggleFilter(filter));
    }
  }, [filter, dispatch]);

  if (location.pathname.includes("blog")) {
    return (
      <span
        className="filter"
        onClick={onFilterClick}
        style={{
          backgroundColor: filter.selected ? "#001D62" : "transparent",
          color: filter.selected ? "#fff" : "#001D62",
        }}>
        <img src={filter.selected ? filter.iconSelected : filter.icon} alt="selected" />
        <span>{language==='pl'? filter.value : (filter.valueEn ? filter.valueEn : filter.value)}</span>
      </span>
    );
  } else {
    return (
      <span
        className={filter.selected ? "filter selected" : "filter"}
        onClick={onFilterClick}>
        <span>{language==='pl'? filter.value : (filter.valueEn ? filter.valueEn : filter.value)}</span>
      </span>
    );
  }
};
export default Filter;
