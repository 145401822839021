import * as React from "react";
import "./ProductCta.scss";
import { RichText } from "prismic-reactjs";
import {
  ProductPageProps,
  useProductHomeData,
} from "features/product-page/product-homepage/productHomeService";
import Button from "ui/button/Button";

interface IProductCta extends ProductPageProps {
  handleHubspot?: () => void;
}

const ProductCta = (props: IProductCta): JSX.Element => {
  const data = useProductHomeData(props);
  const { handleHubspot } = props;
  return (
    <section className="product-cta">
      <div className="product-cta__inner">
        <div>
          <h3 className="product-cta-heading">
            {RichText.asText(data?.product_cta_header?.raw || [])}
          </h3>
          <p className="product-cta-text">
            {RichText.asText(data?.product_cta_description?.raw || [])}
          </p>
          <Button
            as="button"
            linkStyle="noFill"
            handleButtonClick={handleHubspot}
            minWidth={300}
            arrowRight>
            {RichText.asText(data?.product_cta_button?.raw || [])}
          </Button>
        </div>
      </div>
    </section>
  );
};

export default ProductCta;
