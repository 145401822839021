import { graphql, useStaticQuery } from 'gatsby';
import { useSelector } from 'react-redux';
import {
  PrismicCredentialsDataType,
  PrismicHomepage,
  PrismicHomepageDataType,
  PrismicHomepageEdge,
  PrismicCredentialsEdge,
  PrismicCredentials,
} from 'types/declarations';
import { RootState } from '../../../utils/store/store';
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';

export const useCredentials = (): PrismicCredentialsDataType[] => {
  const { language } = useSelector((state: RootState) => state.i18n);
  const resultS = useStaticQuery(graphql`
    {
      allPrismicHomepage {
        edges {
          node {
            lang
            _previewable
            data {
              credentials_main_title {
                raw
              }
              credentials_main_button {
                raw
              }
            }
          }
        }
      }
      allPrismicCredentials {
        edges {
          node {
            lang
            _previewable
            data {
              link {
                text
              }
              image {
                url
                alt
              }
              title {
                raw
              }
              client_logo {
                url
                alt
              }
              client_name {
                raw
              }
            }
          }
        }
      }
    }
  `);
  const {data: result} = useMergePrismicPreviewData(resultS)
  return result.allPrismicCredentials.edges
    .map((edge: PrismicCredentialsEdge) => edge.node)
    .filter((node: PrismicCredentials) => node.lang === language)
    .map((node: PrismicCredentials) => node.data);
};
export const useHomePage = (): PrismicHomepageDataType => {
  const { language } = useSelector((state: RootState) => state.i18n);
  const resultS = useStaticQuery(graphql`
    {
      allPrismicHomepage {
        edges {
          node {
            lang
            _previewable
            data {
              credentials_main_title {
                raw
              }
              credentials_main_button {
                raw
              }
            }
          }
        }
      }
      allPrismicCredentials {
        edges {
          node {
            lang
            _previewable
            data {
              link {
                text
              }
              image {
                url
                alt
              }
              title {
                raw
              }
              client_logo {
                url
                alt
              }
              client_name {
                raw
              }
            }
          }
        }
      }
    }
  `);
  const {data: result} = useMergePrismicPreviewData(resultS)
  return result.allPrismicHomepage.edges
    .map((edge: PrismicHomepageEdge) => edge.node)
    .filter((node: PrismicHomepage) => node.lang === language)
    .map((node: PrismicHomepage) => node.data)[0];
};
