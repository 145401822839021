import BlogLayout from "containers/blog-layout/BlogLayout";
import React, { useEffect } from "react";
import ContactForm from "features/contact/form/ContactForm";
import "./Contact.scss";
import Phone from "assets/phone.svg";
import Mail from "assets/mail.svg";
import Poland from "images/poland.svg";
import Portugal from "images/portugal.svg";
import { useContactData } from "features/contact/contactService";
import { RichText } from "prismic-reactjs";
import Seo from "utils/seo/Seo";
import Clients from "features/clients/Clients";
import Credentials from "features/homepage/credentials/Credentials";
import { useLocale } from "locale/useLocale";
import { Language, setLanguage } from "utils/i18n/i18nSlice";
import { useDispatch } from "react-redux";
import { checkForPopup } from "components/shared-components/popups/generic-popup/checkForPopupService";
import { GenericPopup } from "components/shared-components/popups/generic-popup/GenericPopup";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
const linkResolver = require('../../../../linkResolver.js');

const ContactPage = (): JSX.Element => {
  const contact = useContactData();
  const locale = useLocale("Contact");
  const mailLink = `mailto:${contact?.email?.text}`;
  const phoneLink = `tel:${contact?.phone_number?.text}`;
  const dispatch = useDispatch();
  const displayPopup  = checkForPopup()?.page?.text?.split(';')?.filter(p => p !== '')?.includes('contact');

  useEffect(() => {
    if (typeof window !== `undefined`) {
      const url = location?.pathname?.split('/').filter(item => item !== '')
  
      const findLangInUrl = (): number => url.findIndex(item => item === 'en-pl' || 'pl' || 'fr-fr' || 'de-de');
      const urlLanguage = url[findLangInUrl()] as Language
      dispatch(setLanguage(urlLanguage));
    }
  }, [])

  return (
    <>
      <Seo
        title={contact?.seo_title?.text}
        description={contact?.seo_description?.text}
        canonicalUrl={contact?.seo_canonical_url?.url}
      />
      
      { displayPopup && <GenericPopup /> }

      <BlogLayout>
        <div className="contact-container">
          <div className="contact-container__inner">
            <div className="contact-container__inner--wrap">
              <div className="contact-container__hero">
                <div className="contact-container__hero__header-flex">
                  <div className="contact-container__hero__header-flex__mobile">
                    <RichText render={contact?.header?.raw || []} />
                  </div>
                  <div className='flex-container'>
                    <img src={contact?.image?.url ?? ""} alt={contact?.image?.alt ?? ""} />
                    <div>
                      <div className="contact-container__hero__header-flex__header">
                        <RichText render={contact?.header?.raw || []} />
                      </div>
                      <div className="contact-container__hero__header-flex__description">
                        <RichText render={contact?.subheader?.raw || []} />
                      </div>
                    </div>
                  </div>
                  <div className="contact-info-container">
                    <div className="contact-info-flex">
                      {locale.callUs}
                      <div className="contact-button">
                        <Phone className="contact-button-img" />
                        <a className="contact-button__text" href={phoneLink}>
                          {RichText.asText(contact?.phone_number?.raw || [])}
                        </a>
                      </div>
                    </div>
                    <div className="contact-info-flex">
                      {locale.messageUs}
                      <div className="contact-button">
                        <Mail className="contact-button-img" />
                        <a className="contact-button__text" href={mailLink}>
                          {RichText.asText(contact?.email?.raw || [])}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
        <Credentials styles={{'marginTop': '100px'}} />
        <Clients />
        <div className="contact-info-addresses contact-info-container">
          <div className="contact-info-addresses__inner">
            <div className="contact-info-flex">
              <img className="contact-info-flex__img" src={Poland} alt="polish flag"/>
              <div className="contact-info-flex__text">
                {RichText.asText(contact?.first_address?.raw || [])}
              </div>
            </div>
            <div className="contact-info-flex">
              <img className="contact-info-flex__img" src={Portugal} alt="portuguese flag"/>
              <div className="contact-info-flex__text">
                {RichText.asText(contact?.second_address?.raw || [])}
              </div>
            </div>
          </div>
        </div>
      </BlogLayout>
    </>
  );
};
export default withPrismicPreview(ContactPage, [
  {
    repositoryName: 'carcavelos',
    linkResolver: linkResolver.linkResolver,
  },
]);