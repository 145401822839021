import { useEffect, useRef } from 'react';
import { useLocation } from '@reach/router';

const useScrollToRef = () => {
  const { pathname } = useLocation();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ref.current?.scrollIntoView();
  }, [pathname]);

  return ref;
};

export default useScrollToRef;
