import * as React from "react";
import "./ProductBenefitsIntroductionBox.scss";
import { FC } from "react";
import BenefitsIntroImage from "images/product-benefits-introduction-box.png";
import { RichText, RichTextBlock } from "prismic-reactjs";

export interface IntroductionBoxProps {
  description?: RichTextBlock[];
  imgSrc?: string | null;
}

const ProductBenefitsIntroductionBox: FC<IntroductionBoxProps> = ({
  description,
  imgSrc,
}: IntroductionBoxProps) => <div className="product-benefits-introduction-box">
      <div className="product-benefits-introduction-box__link">
      <img src={imgSrc || BenefitsIntroImage} alt="" />
      <div className="product-benefits-introduction-box__description">
        <RichText render={description ?? []} />
      </div>
    </div>
</div>

export default ProductBenefitsIntroductionBox;
