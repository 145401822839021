import React, { useCallback } from "react";
import "./Banners.scss";
import Button from "ui/button/Button";
import { navigate } from "gatsby";
import { useSelector } from "react-redux";
import { RootState } from "utils/store/store";

export interface CtaBannerProps {
  color: string;
  textColor: string;
}

const CtaBanner = (): JSX.Element => {
  const { language } = useSelector((state: RootState) => state.i18n);
  const onDemoClick = useCallback(() => {
    navigate(`/${language}/contact/`);
  }, []);
  
  return (
    <div className="cta-banner-blog">
      <div className="cta-banner-blog__inner">
        <span
          className="cta-banner-blog__inner--label">
         {language==='pl' ? "Masz pytanie?" : "Have more questions?"}
        </span>
        <p className="cta-banner-blog__inner--text" >
       {language==='pl' ? "Zadzwoń lub umów się na rozmowę z analitykiem ": "Make an appointment with an analyst and learn more about dataplace.ai products."}
        </p>
        <Button
          className='cta-banner-blog__inner--button'
          as="button"
          handleButtonClick={onDemoClick}
          minWidth={180}
          arrowRight>
         {language==='pl' ? "Porozmawiajmy": "Let's talk"}
        </Button>
      </div>
    </div>
  );
};

export default CtaBanner;
