import { Link } from 'gatsby';
import { Maybe } from 'graphql/jsutils/Maybe';
import { RichText } from 'prismic-reactjs';
import React from 'react';
import { PrismicProductPricelistDataPackageBox } from 'types/declarations';
import { PackageBox } from './PackageBox';
import styled, { css } from 'styled-components';
import { useMediaQuery } from 'utils/hooks/useMediaQuery';

const ButtonStyled = styled.div(() => {
  const { mobile, tablet } = useMediaQuery();
  return css`
    width: 80%;
    align-self: center;
    ${(mobile || tablet) && css`
      width: 100%;
      a {
        width: 100%;
      }
    `}
  `;
});

const ButtonStyled2 = styled.div(() => {
  const { mobile, tablet } = useMediaQuery();
  return css`
    width: 80%;
    a {
      width: 80%;
    }
    align-self: center;
    ${(mobile || tablet) && css`
      width: 100%;
      a {
        width: 100%;
      }
    `}
  `;
});

const BoxStyled = styled.div(() => {
  const { mobile, tablet } = useMediaQuery();
  return css`
    width: 100%;
    ${!mobile && !tablet &&
    css`
      width: min-content;
    `}
  `;
});

const PackagesStyled = styled.div(() => {
  const { mobile, tablet } = useMediaQuery();
  return css`
    ${(mobile || tablet) &&
    css`
      align-items: center;
    `}
  `;
});

interface IPackages {
  packages: Maybe<Maybe<PrismicProductPricelistDataPackageBox>[]> | undefined;
  handleShowMeeting: () => void;
}

export const Packages = ({
  packages,
  handleShowMeeting,
}: IPackages): JSX.Element => {
  const freePackage = packages?.[0];
  const paidPackages = packages?.slice(1);

  return (
    <PackagesStyled className='packages'>
      <BoxStyled className='box'>
        <div className='box-left'>
          <PackageBox
            title={freePackage?.box_title?.raw || []}
            description={freePackage?.box_description?.raw || []}
            availableAnalyzes={freePackage?.available_analyzes?.raw || []}
            isPopular={freePackage?.is_popular || false}
            hasImg
          />
          <img
            src='https://carcavelos.cdn.prismic.io/carcavelos/d72768c5-54f6-4b36-8e2b-bc768bd835ab_Group+6699.svg'
            alt='essential package'
            width='185'
            className='d-md-none'
          />
        </div>
        <ButtonStyled className='button'>
          <Link to='https://app.dataplace.ai/auth/sign-up'>
            <RichText render={freePackage?.box_button?.raw || []} />
          </Link>
        </ButtonStyled>
      </BoxStyled>

      <BoxStyled className='box'>
        <div className='box-right'>
          {paidPackages?.map((data, i: number) => (
            <PackageBox
              key={i}
              title={data?.box_title?.raw || []}
              description={data?.box_description?.raw || []}
              analyzesLabel={data?.available_analyzes_label?.raw || []}
              availableAnalyzes={data?.available_analyzes?.raw || []}
              analyzesModal={data?.available_analyzes_modal?.raw || []}
              modalButton={data?.box_modal_button?.raw || []}
              isPopular={data?.is_popular}
            />
          ))}
        </div>
        <ButtonStyled2 className='button big'>
          <a onClick={handleShowMeeting}>
            <RichText render={paidPackages?.[0]?.box_button?.raw || []} />
          </a>
        </ButtonStyled2>
      </BoxStyled>
    </PackagesStyled>
  );
};
