import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  GetStoryPostDetails,
  SuccessStory,
} from "features/success-stories/successStoriesModel";
import { Maybe } from "types/declarations";
import { RootState } from "utils/store/store";
import SuccessStoriesService from "./successStoriesService";

export interface SuccessStoryState {
  allSuccessStories: Array<SuccessStory>;
  successStory?: Maybe<SuccessStory>;
}

const initialState = {
  allSuccessStories: [],
} as SuccessStoryState;

export const getHomeSuccessStories = createAsyncThunk(
  "successStories/getHomeSuccessStories",
  async (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const successStories = state.successStories.allSuccessStories;

    const results = SuccessStoriesService.getHomeSuccessStories({
      allSuccessStories: successStories,
    });

    return results;
  }
);

export const getStoryPostDetails = createAsyncThunk(
  "successStories/getStoryPostDetailsByLink",
  async (props: GetStoryPostDetails, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const posts = state.successStories.allSuccessStories;
    return SuccessStoriesService.getStoryPostDetails({
      ...props,
      allSuccessStories: posts as Array<SuccessStory>,
    });
  }
);

const successStoriesSlice = createSlice({
  name: "successStories",
  initialState,
  reducers: {
    setAllSuccessStories(state, action: PayloadAction<Array<SuccessStory>>) {
      state.allSuccessStories = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getStoryPostDetails.fulfilled, (state, action) => {
      state.successStory = action.payload;
    });
  },
});

export const { setAllSuccessStories } = successStoriesSlice.actions;
export default successStoriesSlice.reducer;
