import * as React from 'react';
import './Technology.scss';
import { PrismicHomepageDataType } from 'types/declarations';
import { FC } from 'react';
import TechnologyGridItem from 'features/homepage/technology/TechnologyGridItem';

export interface TechnologyProps {
  data: PrismicHomepageDataType;
}

const Technology: FC<TechnologyProps> = ({ data }: TechnologyProps) => {
  return (
    <div className='technology-grid'>
      {data?.technology_points?.map((point, index) => (
        <TechnologyGridItem key={index} point={point} />
      ))}
    </div>
  );
};

export default Technology;
