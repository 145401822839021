import SuccessStoryPost from "features/success-stories/items/posts/SuccessStoryPost";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "utils/store/store";
import { getHomeSuccessStories } from "../successStoriesSlice";
import "./HomeItems.scss";

const HomeItems = (): JSX.Element => {
  const dispatch = useDispatch();
  const { allSuccessStories: allStories } = useSelector(
    (state: RootState) => state.successStories
  );

  const allSuccessStories = [...allStories]?.sort((a, b) => new Date(a.date) > new Date(b.date) ? -1 : 1);

  useEffect(() => {
    dispatch(getHomeSuccessStories());
  }, [dispatch]);

  return (
    <section>
      <div className="home-ss-items">
        {allSuccessStories?.map(post => <SuccessStoryPost post={post} key={post?.link} />)}
      </div>
    </section>
  );
};

export default HomeItems;
