import React from 'react'
import styled, { css } from 'styled-components'
import DataplaceFavicon from 'assets/logos/dataplaceai-fav.svg'
import { RichText } from 'prismic-reactjs'
import { useFooterData } from '../footerService'
import { colors, productColors } from 'shared/constants'
import { Link } from 'gatsby'
import { useMediaQuery } from 'utils/hooks/useMediaQuery'
import { useSelector } from 'react-redux'
import { RootState } from 'utils/store/store'

const StyledMainFooterDiv = styled.div(() => {
  const { tablet, mobile } = useMediaQuery()

  return css`
    display: flex;
    justify-content: space-between;
    flex-direction: ${(tablet || mobile) ? 'column' : 'row'};
    align-items: center;
    padding: 1rem 0;
    gap: 1rem;  
    width: 100%;
    border-top: 2px solid ${colors.gray.light};
  `
})

const StyledCopyrightDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

const StyledDataplaceFavicon = styled(DataplaceFavicon)`
  width: 1rem;
  height: 1rem;
`

const sharedTextStyling = css`
  font-weight: 400;
  font-size: 0.75rem;
  margin: 0;
  color: ${productColors.dark};
`

const StyledCopyrightText = styled.p`
  ${sharedTextStyling}
  line-height: 0.875rem;
`

const StyledTermsDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`

const StyledTermsText = styled.p`
  ${sharedTextStyling}
  line-height: 1rem;
`

function Policies() {
  const data = useFooterData()
  const { language } = useSelector((state: RootState) => state.i18n)

  return (
    <StyledMainFooterDiv>
      <StyledCopyrightDiv>
        <StyledDataplaceFavicon />
        <StyledCopyrightText>{RichText.asText(data.footer_copyright?.richText)}</StyledCopyrightText>
      </StyledCopyrightDiv>
      <StyledTermsDiv>
        <Link to={`/${language}/terms-of-service/`}>
          <StyledTermsText>{RichText.asText(data.footer_terms_of_service?.raw)}</StyledTermsText>
        </Link>
        <Link to={`/${language}/regulations/`}>
          <StyledTermsText>{RichText.asText(data.footer_regulations?.raw)}</StyledTermsText>
        </Link>
        <Link to={`/${language}/privacy-policy/`}>
          <StyledTermsText>{RichText.asText(data.footer_privacy_policy?.raw)}</StyledTermsText>
        </Link>
      </StyledTermsDiv>
    </StyledMainFooterDiv>
  )
}

export default Policies
