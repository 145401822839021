import * as React from "react";
import "./ProductBenefitsShowcase.scss";
import { useProductBenefitsData } from "features/product-page/product-benefits/productBenefitsService";
import { RichText } from "prismic-reactjs";
import { ProductPageProps } from "features/product-page/product-homepage/productHomeService";
import { FC } from "react";

const ProductBenefitsShowcase: FC<ProductPageProps> = (
  props: ProductPageProps
) => {
  const data = useProductBenefitsData(props);
  
    return (
      <section className="product-benefits-showcase">
        <div className="product-benefits-showcase__inner">
          <div className="product-benefits-showcase__inner--left">
            <div>
              <span>{RichText.asText(data?.hero_subheader?.raw || [])}</span>
              <h1>{RichText.asText(data?.hero_header?.raw || [])}</h1>
            </div>
          </div>
          <div className="product-benefits-showcase__inner--right">
            <img
              className="product-benefits-showcase-img"
              src={data?.hero_image?.url ?? ""}
              alt="Showcase Product Location"
            />
          </div>
        </div>
      </section>
    );
};

export default ProductBenefitsShowcase;
