import styled, { css } from 'styled-components'
import { useMediaQuery } from 'utils/hooks/useMediaQuery'

const StyledEmployeeImg = styled.img(() => {
  const { tablet, mobile } = useMediaQuery()

  return css`
    max-width: ${tablet ? '160px' : mobile ? '56px' : '360px'};
    max-height: ${tablet ? '160px' : mobile ? '56px' : '360px'};
    margin: 0;
    border-radius: 0.75rem;
  `
})

export default StyledEmployeeImg
