import SuccessStoriesLayout from "containers/succesStories-layout/SuccessStoriesLayout";
import Showcase from "./items/showcase/Showcase";
import React, { useEffect, useState } from "react";
import Seo from "utils/seo/Seo";
import "./SuccessStoriesHome.scss";
import CtaBanner from "features/success-stories/items/banners/CtaBanner";
import HubspotMeetingModal from "ui/HubspotMeetingModal/HubspotMeetingModal";
import FreeTrialBanner from "../../shared-components/free-trial-banner/FreeTrialBanner";
import HomeItems from "features/success-stories/home/HomeItems";
import { useSSData } from "./successStoriesService";
import { useDispatch } from "react-redux";
import { Language, setLanguage } from "utils/i18n/i18nSlice";
import { checkForPopup } from "components/shared-components/popups/generic-popup/checkForPopupService";
import { GenericPopup } from "components/shared-components/popups/generic-popup/GenericPopup";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
const linkResolver = require('../../../../linkResolver.js');

const SuccessStoriesHome = (): JSX.Element => {
  const ss = useSSData();
  const dispatch = useDispatch();
  const displayPopup = checkForPopup()
    ?.page?.text?.split(";")
    ?.filter(p => p !== "")
    ?.includes("success_stories");
    
  const [showMeeting, setShowMeeting] = useState(false);

  const handleCloseMeeting = () => {
    if (showMeeting) {
      setShowMeeting(false);
    }
  };

  useEffect(() => {
    if (typeof window !== `undefined`) {
      const url = location?.pathname?.split('/').filter(item => item !== '')
  
      const findLangInUrl = (): number => url.findIndex(item => item === 'en-pl' || 'pl' || 'fr-fr' || 'de-de');
      const urlLanguage = url[findLangInUrl()] as Language
      dispatch(setLanguage(urlLanguage));
    }
  }, []);

  return (
    <>
      <Seo
        title={ss?.seo_title?.text}
        description={ss?.seo_description?.text}
        canonicalUrl={ss?.seo_canonical_url?.url}
      />
      
      {displayPopup && <GenericPopup />}

      {showMeeting && (
        <HubspotMeetingModal
          closeMeeting={handleCloseMeeting}
          left="25vw"
          show={showMeeting}
          top="5vh"
        />
      )}
      <SuccessStoriesLayout>
        <section className="success-stories-container">
          <div className="success-stories-container__inner">
            <Showcase />
            <HomeItems />
          </div>
        </section>
        <FreeTrialBanner />
        <CtaBanner />
      </SuccessStoriesLayout>
    </>
  );
};
export default withPrismicPreview(SuccessStoriesHome, [
  {
    repositoryName: 'carcavelos',
    linkResolver: linkResolver.linkResolver,
  },
]);
