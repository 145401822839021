import * as React from 'react';
import './IntroductionBoxMobile.scss';
import { FC, useCallback } from 'react';
import {
  Maybe,
  PrismicHomepageDataHeroCards,
  PrismicStructuredTextType,
} from 'types/declarations';
import { RichText } from 'prismic-reactjs';
import Placeme from 'images/placeme-location-contact.svg';
import Target from 'images/placeme-target-contact.svg';
import Source from 'images/placeme-source-contact.svg';
import Plus from 'assets/product_tab_mobile_plus.svg';
import Minus from 'assets/product_tab_mobile_minus.svg';
import styled, { css } from 'styled-components';
import Button from 'ui/button/Button';
import { ColorSpan } from '../color-span';
import { useMediaQuery } from 'utils/hooks/useMediaQuery';

const Wrapper = styled.div<{ selected: boolean; bgColor: string }>(
  () => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    background-color: #ffffff;
  `
);

const StyledButton = styled.button<{ selected: boolean; bgColor: string }>(
  ({ bgColor }) => css`
    outline: inherit;
    border: none;
    display: flex;
    flex-direction: column;
    background-color: ${bgColor};
    cursor: pointer;
  `
);

const Content = styled.div(() => {
  const { tablet } = useMediaQuery();
  return css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding: 2rem 1rem;
    width: 90vw;
    color: #002163;
    > div {
      text-align: center;
    }

    ${tablet &&
    css`
      flex-direction: row;
    `}
  `;
});

const ProductTabLogo = styled.img`
  width: 9rem;
  height: 2rem;
  object-fit: contain;
`;

const ProductTabImage = styled.img(() => {
  return css`
    width: 14rem;
    height: 9rem;
    object-fit: cover;
  `;
});

const DescriptionButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  a {
    flex: auto;
  }
  margin-bottom: 2rem;
`;

const ImagesWrapper = styled.div(() => {
  const { tablet } = useMediaQuery();
  return css`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 2rem;

    ${tablet &&
    css`
      width: 45%;
    `}
  `;
});

const DescriptionWrapper = styled.div(() => {
  const { tablet } = useMediaQuery();
  return css`
    ${tablet &&
    css`
      width: 55%;
    `}
  `;
});

export interface IntroductionBoxProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  title: string;
  description?: Maybe<PrismicStructuredTextType>;
  bgColor: string;
  selected: boolean;
  card: Maybe<PrismicHomepageDataHeroCards>;
}

const IntroductionBox: FC<IntroductionBoxProps> = ({
  title,
  description,
  bgColor,
  onClick,
  selected,
  card,
}: IntroductionBoxProps) => {
  const getIcon = useCallback(() => {
    if (title?.includes('placeme')) {
      return <img style={{ width: '14px' }} src={Placeme} alt='product logo' />;
    } else if (title?.includes('target')) {
      return <img style={{ width: '14px' }} src={Target} alt='product logo' />;
    } else {
      return <img style={{ width: '14px' }} src={Source} alt='product logo' />;
    }
  }, [title]);

  const { tablet } = useMediaQuery();

  const content = React.useRef<HTMLDivElement>(null);

  const iconStyle = {
    marginLeft: 'auto',
  };

  return (
    <Wrapper selected={selected} bgColor={bgColor}>
      <StyledButton
        selected={selected}
        className='introduction-box-mobile'
        bgColor={bgColor}
        onClick={onClick}>
        <p className='introduction-box-mobile__link'>
          <h3 className='introduction-box-mobile__title'>
            {getIcon()}
            {title}
            {selected ? (
              <Minus style={iconStyle} />
            ) : (
              <Plus style={iconStyle} />
            )}
          </h3>
          <div className='introduction-box-mobile__description'>
            <RichText render={description?.raw || []} />
          </div>
        </p>
      </StyledButton>
      {
        <>
          <Content
            ref={content}
            style={selected ? undefined : { display: 'none' }}>
            <ImagesWrapper>
              <ProductTabLogo
                src={card?.product_tab_logo?.url || undefined}
                alt={card?.product_tab_logo?.alt || undefined}
              />
              <ProductTabImage
                src={card?.product_tab_image?.url || undefined}
                alt={card?.product_tab_image?.alt || undefined}
              />
            </ImagesWrapper>
            <DescriptionWrapper>
              <ColorSpan
                text={RichText.asText(card?.product_tab_description?.raw)}
              />
            </DescriptionWrapper>
          </Content>

          <DescriptionButtonsWrapper
            style={selected ? undefined : { display: 'none' }}>
            {RichText.asText(card?.product_tab_button_1_link?.raw) &&
              tablet && (
                <Button
                  to={RichText.asText(card?.product_tab_button_1_link?.raw)}
                  filled>
                  {RichText.asText(card?.product_tab_button_1_text?.raw)}
                </Button>
              )}
            {RichText.asText(card?.product_tab_button_2_link?.raw) && (
              <Button
                to={RichText.asText(card?.product_tab_button_2_link?.raw)}
                arrowRight>
                {RichText.asText(card?.product_tab_button_2_text?.raw)}
              </Button>
            )}
          </DescriptionButtonsWrapper>
        </>
      }
    </Wrapper>
  );
};

export default IntroductionBox;
