import { RichText } from 'prismic-reactjs'
import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { Maybe, PrismicProductHomeDataBodyReviewsSliceItem, PrismicProductHomeDataBodyReviewsSlicePrimary } from 'types/declarations'
import { useMediaQuery } from 'utils/hooks/useMediaQuery'
import StyledEmployeeImg from './StyledEmployeeImg'
import CustomerInfo from './CustomerInfo'
import { colors } from 'shared/constants'

const StyledMainReviewDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`

const StyledReviewDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const StyledCompanyLogoImg = styled.img`
  max-width: 130px;
  margin: 0;
`

const StyledReviewText = styled.h4(() => {
  const { tablet, mobile } = useMediaQuery()

  return css`
    font-weight: 700;
    font-size: ${mobile ? '1.625rem' : '1.75rem'};
    line-height: ${(tablet || mobile) ? '2.125rem' : '2.25rem'};
    margin: 0;
    min-height: 240px;
    color: ${colors.darkBlue};
  `
})

interface CustomerReviewProps {
  selectedReview?: Maybe<PrismicProductHomeDataBodyReviewsSliceItem>
  primary?: Maybe<PrismicProductHomeDataBodyReviewsSlicePrimary>
}

function CustomerReview({ selectedReview, primary }: CustomerReviewProps) {
  const { desktop, laptop } = useMediaQuery()
  const selectedCompanyLogoImgUrl = useMemo(() => selectedReview?.company_logo_img?.url, [selectedReview])
  const selectedEmployeeImgUrl = useMemo(() => selectedReview?.employee_portrait_img?.url, [selectedReview])

  return (
    <StyledMainReviewDiv>
      <StyledReviewDiv>
        {selectedCompanyLogoImgUrl
          ? <StyledCompanyLogoImg src={selectedCompanyLogoImgUrl} />
          : null
        }
        <StyledReviewText>{RichText.asText(selectedReview?.review?.richText)}</StyledReviewText>
        <CustomerInfo selectedEmployeeImgUrl={selectedEmployeeImgUrl} selectedReview={selectedReview} primary={primary} />
      </StyledReviewDiv>
      {(desktop || laptop) && selectedEmployeeImgUrl
        ? <StyledEmployeeImg src={selectedEmployeeImgUrl}/>
        : null
      }
    </StyledMainReviewDiv>
  )
}

export default CustomerReview
