import React, { ChangeEvent, useCallback, useEffect, useState} from "react";
import Filters from "features/blog/search/filters/Filters";
import SearchBar from "features/blog/search/search-bar/SearchBar";
import Breadcrumbs from "features/blog/items/breadcrumbs/Breadcrumbs";
import { useSelector } from "react-redux";
import { RootState } from "utils/store/store";
import { useDispatch } from "react-redux";
import { Dispatch } from "utils/store/store";
import useDebounce from "utils/hooks/useDebounce";
import {
  clearSearch,
  getHomeBlogPosts,
  getSearchResults,
} from "components/features/blog/blogSlice";

const SearchHeader = ({isCleared, handleClear}:{isCleared: boolean, handleClear?: (isCleared : boolean)=> void }): JSX.Element => {
 

  const dispatch = useDispatch<Dispatch>();
  const [query, setQuery] = useState<string>("");
  const debouncedQuery = useDebounce<string>(query, 100);
  const { language } = useSelector((state: RootState) => state.i18n);

  const onChangeSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
    if(handleClear){
      handleClear(false)
      }
  }, []);

  useEffect(() => {
    if (query.length === 0) {
      dispatch(clearSearch());
      dispatch(getHomeBlogPosts());
    }
  }, [query, dispatch]);

  useEffect(() => {
    if (debouncedQuery?.length > 1) {
      dispatch(getSearchResults(debouncedQuery));
    }
  }, [debouncedQuery, dispatch]);

  useEffect(()=>{
    if(isCleared){
      setQuery('')
    }
   },[isCleared])

  return (
    <div className="blog-top">
      <div>
        {language==='pl' ? <Breadcrumbs first="Wiedza i raporty" second="Blog" /> : <Breadcrumbs first="Resources" second="Blog" />}
        <SearchBar query={query} onChangeSearch={onChangeSearch} handleClear={handleClear} isCleared = {isCleared}/>
      </div>
      <Filters/>
      <div className="header-flex">
        {/* <Pagination /> */}
      </div>
    </div>
  );
};
export default SearchHeader;
