import * as React from 'react';
import './Technology.scss';
import { RichText } from 'prismic-reactjs';
import { useTechnologyData } from 'features/homepage/technology/technologyService';
import TechnologyGrid from 'features/homepage/technology/TechnologyGrid';
import styled, { css } from 'styled-components';
import { useMediaQuery } from 'utils/hooks/useMediaQuery';
import { SectionHeading, SectionTitle } from '../shared-components/section';

const TechnologyInnerInfoStyled = styled.div(() => {
  return css`
    max-width: 1130px;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  `;
});

const TechnologyInnerInfoParagraphStyled = styled.p(() => {
  const { mobile, tablet } = useMediaQuery();
  return css`
    font-size: 44px;
    line-height: 52px;
    text-align: center;
    color: #001c61;
    font-weight: 600;
    ${mobile &&
    css`
      font-size: 30px;
    `}
    ${tablet &&
    css`
      font-size: 30px;
      line-height: 38px;
      padding: 0 30px;
    `}
  `;
});

const TechnologyInnerInfoSpanStyled = styled.span(() => {
  return css`
    font-size: 16px;
    line-height: 52px;
    letter-spacing: 2px;
    color: #001c61;
    text-transform: uppercase;
  `;
});

const TechnologySection = styled.section(() => {
  const { mobile, tablet } = useMediaQuery();
  return css`
    padding: 3rem 0 5rem 0;
    ${mobile && css`
      padding: 1rem 0 3rem 0;
    `}
    ${tablet &&
    css`
      padding-bottom: 10rem;
    `}
  `;
});

const Technology = (): JSX.Element => {
  const data = useTechnologyData();

  return (
    <TechnologySection className='technology'>
      <div className='technology__inner'>
        <TechnologyInnerInfoStyled>
          <SectionTitle>
            {RichText.asText(data?.technology_subheader?.raw || [])}
          </SectionTitle>
          <SectionHeading>
            {RichText.asText(data?.technology_header?.raw || [])}
          </SectionHeading>
        </TechnologyInnerInfoStyled>
        <TechnologyGrid data={data} />
      </div>
    </TechnologySection>
  );
};

export default Technology;
