import React from 'react';
import { config, animated, useInView } from '@react-spring/web';

interface ICompanyNumberAnimated {
  number: number;
  animationMargin?: string;
}

function CompanyInNumbersAnimatedNumber({
  number,
  animationMargin,
}: ICompanyNumberAnimated) {
  const [ref, springs] = useInView(
    () => ({
      from: {
        num: 0,
      },
      to: {
        num: number,
      },
      config: config.slow,
    }),
    {
      rootMargin: `-${animationMargin}% 0%`,
      once: true,
    }
  );

  return (
    <animated.h1 ref={ref}>
      {springs.num.to(num => Math.floor(num))}
    </animated.h1>
  );
}

export default CompanyInNumbersAnimatedNumber;
