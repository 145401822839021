import { Link } from 'gatsby'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'utils/store/store'
import { useLocale } from 'locale/useLocale'
import Placeme from 'assets/logos/placeme_logo_product.svg'
import styled, { css } from 'styled-components'
import { colors } from 'shared/constants'
import { useMediaQuery } from 'utils/hooks/useMediaQuery'

const StyledLink = styled(Link)`
  color: ${colors.black};

  &.active {
    color: ${colors.lightBlue};
    font-weight: 600;
  }
`

const SubMenuWrapper = styled.div(() => css`
  background-color: ${colors.background};
  -webkit-box-shadow: 0px 32px 34px -24px rgba(66, 68, 90, 0.2);
  -moz-box-shadow: 0px 32px 34px -24px rgba(66, 68, 90, 0.2);
  box-shadow: 0px 32px 34px -24px rgba(66, 68, 90, 0.2);
`)

const Container = styled.div(() => css`
  max-width: 1440px;
  margin: 0 auto;
  padding: 16px 40px;
  display: flex;
  align-items: center;

  @media (max-width: 1200px){
    padding: 16px 25px;
  }
`)

const StyledNav = styled.nav(() => css`
  margin-left: auto;
`)

const StyledUl = styled.ul(() => css`
  margin: 0;
  display: flex;
  list-style: none;
  align-items: center;
`)

const StyledLi = styled.li(() => css`
  margin: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: ${colors.darkBlue};
  text-decoration: none;

  &::first-letter {
    text-transform: capitalize;
  }

  &:not(:last-child ){
    padding-right: 40px;
  }
`)

const StyledPlaceme = styled(Placeme)`
  height: 22px;
  width: 120px;
`

const SubNavbar = () => {
  const locale = useLocale('ProductSecondNavbar')
  const { language } = useSelector((state: RootState) => state.i18n)
  const { mobile } = useMediaQuery()

  return (
    <SubMenuWrapper>
      <Container>
        <Link aria-label='placeme' to={`/${language}/placeme/`}>
          <StyledPlaceme />
        </Link>
        <StyledNav>
          <StyledUl>
          {!mobile
            ? (
              <>
                <StyledLi>
                  <StyledLink to={`/${language}/placeme`} activeClassName='active'>
                    {locale?.aboutProduct}
                  </StyledLink>
                </StyledLi>
                <StyledLi>
                  <StyledLink to={`/${language}/placeme-functionality`} activeClassName='active'>
                    {locale?.functionality}
                  </StyledLink>
                </StyledLi>
              </>
            )
            : null
          }
            <StyledLi>
              <StyledLink to={`/${language}/pricing`} activeClassName='active'>
                {locale?.pricelist}
              </StyledLink>
            </StyledLi>
          </StyledUl>
        </StyledNav>
      </Container>
    </SubMenuWrapper>
  )
}

export default SubNavbar
