import * as React from "react";
import "./ProductBenefitsIntroductionItem.scss";
import { Link } from "gatsby";
import BtnArrowRight from "assets/arrowbtn.svg";
import ProductBenefitsItem from "images/product-benefits-single-item.png";
import { FC } from "react";
import { RootState } from "utils/store/store";
import { useSelector } from 'react-redux'
import { RichText, RichTextBlock } from "prismic-reactjs";
import { useLocale } from "locale/useLocale";

export interface ProductBenefitsIntroductionItemProps {
  description?: RichTextBlock[];
  buttonText?:string;
  img?: string | null;
  link?: string;
}

const ProductBenefitsIntroductionItem: FC<ProductBenefitsIntroductionItemProps> = ({
  description, img, link,
}: ProductBenefitsIntroductionItemProps) => {
  const { language } = useSelector((state: RootState) => state.i18n);
  const locale = useLocale("ProductBenefitsIntroductionItem");

  return (
    <div className="product-benefits-introduction-item">
      <div className="product-benefits-introduction-item--left">
        <RichText render={description  ?? []}/>
        {language ==='pl' && <Link className="product-func-case-study-box__inner--link" to={`/${language}` + link  + '/' || `/${language}` + '/blog/'}>
        {locale?.readMore}
          <BtnArrowRight />
        </Link>}
      </div>
      <div className="product-benefits-introduction-item--right">
        <img className='product-benefits-introduction-item-img' src={img || ProductBenefitsItem} alt="Benefits" />
      </div>
    </div>
  );
};

export default ProductBenefitsIntroductionItem;
