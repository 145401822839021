import { RichText } from 'prismic-reactjs'
import React from 'react'
import { colors } from 'shared/constants'
import styled, { css } from 'styled-components'
import IsOpenIcon from 'assets/faq-open.svg'
import IsCloseIcon from 'assets/faq-closed.svg'
import { PrismicProductHomeDataBodyFaqSliceItem } from 'components/slices/faq-slice/FaqTypes'
import { Maybe } from 'types/declarations'
import { PrismicProductHomeDataBodyPricingFormSliceItem } from 'components/slices/pricing-form-slice/PricingFormTypes'

const DropdownDescription = styled.p(() => css`
  line-height: 23px;
  font-size: 15px;
  font-weight: 400;
  margin-left: 2.8rem;
  margin-top: 1rem;
  color: ${colors.main};
`)

const DropdownTitle = styled.h2(() => css`
  line-height: 27px;
  font-size: 18px;
  color: ${colors.main};
  margin-bottom: 0;
  display: flex;
  align-items: center;

  svg {
    min-width: 20px;
    margin-right: 1.5rem;
  }

  &:hover {
    color: ${colors.lightBlue};
  }
`)

const DropdownElement = styled.div(() => css`
  cursor: pointer;
  margin-bottom: 1rem;
`)

type ItemProps = PrismicProductHomeDataBodyFaqSliceItem | PrismicProductHomeDataBodyPricingFormSliceItem

interface DropdownProps {
  selectedItem: number | null
  handleClickDropdown: (el: number) => void
  index: number
  item: Maybe<ItemProps>
  twoColumnsVersion?: boolean
}

const Dropdown = ({ selectedItem, handleClickDropdown, index, item, twoColumnsVersion }: DropdownProps) => {
  const finalIndex = index * 2 + (twoColumnsVersion ? 1 : 0)

  return (
    <React.Fragment key={`${finalIndex}-${RichText.asText(item?.dropdown_element_title?.richText)}`}>
      <DropdownElement onClick={() => handleClickDropdown(finalIndex)}>
        <DropdownTitle>
          {selectedItem === finalIndex ? <IsOpenIcon /> : <IsCloseIcon />}
          {RichText.asText(item?.dropdown_element_title?.richText)}
        </DropdownTitle>
        {selectedItem === finalIndex
          ? <DropdownDescription>{RichText.asText(item?.dropdown_element_description?.richText)}</DropdownDescription>
          : null
        }
      </DropdownElement>
    </React.Fragment>
  )
}

export default Dropdown
