import React, { useCallback } from "react";
import "./Banners.scss";
import Button from "ui/button/Button";
import { navigate } from "gatsby";
import PlacemeLogo from "../../../../../assets/placeme-white.svg";
import { useLocale } from "locale/useLocale";

const ProductTrialBanner = (): JSX.Element => {
  const locale = useLocale("SuccessStory");

  const onDemoClick = useCallback(() => {
    navigate('https://auth.dataplace.ai/sign-in');
  }, []);

  return (
    <div className="product-trial-banner">
      <div className="product-trial-banner__inner">
        <div className="product-trial-banner__inner--left">
          <h3 className="product-trial-banner__inner--left--title">
            {locale?.ptbTitle}
          </h3>
          <p className="product-trial-banner__inner--left--text">
            {locale?.ptbTitle}
          </p>
          <div className="product-trial-banner__inner--left--bottom">
            <Button
              className="product-trial-banner__inner--left--button"
              as="button"
              handleButtonClick={onDemoClick}
              minWidth={180}
              arrowRight>
                {locale?.ptbButton}
            </Button>
            <div>
              <span className="product-trial-banner__inner--left--tag">
                {locale?.ptbTag}
              </span>
              <p className="product-trial-banner__inner--left--label">
                {locale?.ptbLabel}
              </p>
            </div>
          </div>
        </div>
        <div className="product-trial-banner__inner--right">
          <PlacemeLogo />
        </div>
      </div>
    </div>
  );
};

export default ProductTrialBanner;
