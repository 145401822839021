import * as React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { FC } from "react";
import { Maybe } from "graphql/jsutils/Maybe";
import useLanguage from 'utils/hooks/useLanguage';
export interface SeoProps {
  description?: string | Maybe<string> | undefined;
  lang?: string;
  meta?: { name: string; content: string }[];
  title: string | Maybe<string> | undefined;
  canonicalUrl?: Maybe<string> | undefined
}

const Seo: FC<SeoProps> = ({
  description,
  lang,
  meta = [],
  title,
  canonicalUrl,
}: SeoProps) => {
  const { language } = useLanguage()
  const { site } = useStaticQuery(
    graphql`
       query {
         site {
           siteMetadata {
             title
             description
             author
           }
         }
       }
     `
  );

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;
  const currentUrl = typeof window !== "undefined" ? window.location.href : '';

  return (
    <Helmet
      htmlAttributes={{
        lang: lang ?? language,
      }}
      title={title ?? ''}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : ""}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      <link rel="canonical" href={canonicalUrl || currentUrl} />
    </Helmet>
  );
};

export default Seo;