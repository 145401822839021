import { useState, useEffect } from 'react';

export function useMediaQuery() {
  const [matches, setMatches] = useState({
    mobile: false,
    tablet: false,
    laptop: false,
    desktop: false,
  });

  useEffect(() => {
    const mobile = window.matchMedia(
      'only screen and (max-device-width: 428px) and (orientation: portrait)'
    );
    const tablet = window.matchMedia(
      'only screen and (min-device-width: 429px) and (max-device-width: 1024px) and (orientation: portrait)'
    );
    const laptop = window.matchMedia(
      'screen and (min-device-width: 429px) and (max-device-width: 1599px) and (orientation: landscape)'
    );
    const desktop = window.matchMedia(
      'screen and (min-device-width: 1600px) and (orientation: landscape)'
    );
    const newMatches = {
      mobile: mobile.matches,
      tablet: tablet.matches,
      laptop: laptop.matches,
      desktop: desktop.matches,
    };
    const listener = () => setMatches(newMatches);
    if (
      matches.mobile !== newMatches.mobile ||
      matches.tablet !== newMatches.tablet ||
      matches.laptop !== newMatches.laptop ||
      matches.desktop !== newMatches.desktop
    ) {
      listener();
    }
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, [matches]);

  return matches;
}
