import React, { ChangeEvent } from "react";
import { useSelector } from "react-redux";
import { RootState } from "utils/store/store";

import "./SearchBar.scss";

const SearchBar = ({
  query,
  onChangeSearch,
}: {
  query?: string;
  isCleared: boolean;
  handleClear?: (isCleared: boolean) => void;
  onChangeSearch?: (e: ChangeEvent<HTMLInputElement>) => void;
}): JSX.Element => {
  const { language } = useSelector((state: RootState) => state.i18n);
  return (
    <>
      <input
        className="search-bar"
        onChange={onChangeSearch}
        value={query}
        placeholder={
          language === "pl" ? "wpisz słowo kluczowe" : "enter a keyword"
        }
      />
    </>
  );
};
export default SearchBar;
