import * as React from 'react';
import { PrismicClientsDataType, PrismicProductHomeDataBodyBrandCarouselSlice } from 'types/declarations';
import styled, { css } from 'styled-components';
import { useMediaQuery } from 'utils/hooks/useMediaQuery';
import BrandItem from './components/BrandItem';

export interface ClientsGridProps {
  data: PrismicClientsDataType;
}

const sectionHeight = {
  mobileHeight: '50px',
  desktopHeight: '50px',
};

interface SectionProps {
  padding?: string
  backgroundColor?: string
}

const Section = styled.section<SectionProps>(({padding, backgroundColor}) => {
  const { mobile, tablet } = useMediaQuery();

  return css`
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    height: ${sectionHeight.desktopHeight};
    ${mobile && css`height: ${sectionHeight.mobileHeight};`}
    ${tablet && css`margin-top: 30px;`}
    ${padding && css`padding: ${padding};`}
    ${backgroundColor && css`background-color: ${backgroundColor};`}
  `;
});

const BrandWrapper = styled.div<{
  iconCount?: number;
  width: string;
  logoMargin: string;
}>(({ iconCount, width, logoMargin }) => {
  const { mobile, tablet } = useMediaQuery();
  const animationSpeed = mobile ? '40s' : tablet ? '50s' : '60s';

  return css`
    display: flex;
    position: absolute;
    justify-content: left;
    overflow: hidden;
    animation: scroll-primary ${animationSpeed} linear infinite;
    width: calc((${width} + ${logoMargin}) * ${iconCount} * 2);
    height: ${sectionHeight.desktopHeight};

    ${mobile && css`height: ${sectionHeight.mobileHeight};`}

    @keyframes scroll-primary {
      0% {
        transform: translateX(0);
      }

      100% {
        transform: translateX(
          calc((-${width} - ${logoMargin}) * (${iconCount}))
        );
      }
    }
  `;
});

const BrandCarousel: React.FC<{ 
  slice: PrismicProductHomeDataBodyBrandCarouselSlice,
  padding?: string,
  backgroundColor?: string
}> = ({ slice, padding, backgroundColor }) => {
  const { mobile } = useMediaQuery();
  const shuffledData = slice.items?.sort(() => Math.random() - 0.5);

  const iconWidth = mobile ? '130px' : '162px';
  const logoMargin = mobile ? '20px' : '0px';

  return (
    <Section padding={padding} backgroundColor={backgroundColor}>
      <BrandWrapper
        iconCount={shuffledData?.length}
        width={iconWidth}
        logoMargin={logoMargin}>
        {shuffledData 
          ?
            [...shuffledData, ...shuffledData]?.map((client, index) => (
              <BrandItem
               key={index}
                item={client}
                width={iconWidth}
                logoMargin={logoMargin}
              />
            ))
          : null
        }
      </BrandWrapper>
    </Section>
  );
};

export default BrandCarousel;
