import React from 'react'

const Microdata = (): JSX.Element => {
  const structuredData = {
    "@context": "http://schema.org",
    "@type": "LocalBusiness",
    "address": {
    "@type": "PostalAddress",
    "addressLocality": "Warszawa ",
    "streetAddress": "Aleja Solidarności 119/125 m. 21",
    "postalCode": "03-412",
    "addressRegion": "mazowieckie",
    "addressCountry": "Polska"
    },
    "name": "Dataplace",
    "legalName": "Dataplace",
    "alternateName": "Dataplace",
    "brand": "Dataplace",
    "email": "hello@dataplace.ai",
    "telephone": "+48 881 655 966",
    "priceRange": "PLN",
    "url": "https://dataplace.ai/",
    "sameAs": "https://www.facebook.com/dataplaceai/"
  };

  return (
    <script type="application/ld+json">
      {JSON.stringify(structuredData)}
    </script>
  )
}

export default Microdata;
