import React, { FC, useCallback, useState, useMemo } from "react";
import "./Banners.scss";
import { BlogFormBanner } from "features/blog/blogModel";
import { RichText } from "prismic-reactjs";
import Select from "ui/select/Select";
import { postSubscriptionForm } from "../../blogService";
import { useSelector } from "react-redux";
import { RootState } from "utils/store/store";
import { Link } from "gatsby";
import Close from "../../../../../assets/close.svg";
import MessageIcon from "../../../../../assets/message.svg";
import ErrorIcon from "../../../../../assets/error.svg";

export interface SignUpBannerProps {
  banner: BlogFormBanner;
}

const SubscribeBanner: FC<SignUpBannerProps> = ({
  banner,
}: SignUpBannerProps) => {
  const formOptions = RichText.asText(
    banner?.industry_options?.raw || []
  ).split(";");
  const [email, setEmail] = useState("");
  const [industry, setIndustry] = useState("");
  const { language } = useSelector((state: RootState) => state.i18n);
  const [infoModalDisplay, setModalDisplay] = useState(false);
  const [infoModalText, setInfoModalText] = useState("");

  const formDisabled = useMemo(() => {
    return !email || !industry;
  }, [email, industry]);

  const onEmailChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setEmail(e.target.value);
    },
    []
  );

  const onSelectorChange = useCallback((value: string) => {
    setIndustry(value);
  }, []);

  const onSubmit = useCallback(() => {
    if (!formDisabled) {
      postSubscriptionForm(
        {
          email,
          industry,
        },
        language,
        setInfoModalText,
        setModalDisplay
      );
    }
  }, [email, industry]);

  return (
    <>
      {infoModalDisplay && (
        <div className="info-modal-newsletter">
          <span
            onClick={() => {
              setModalDisplay(false);
            }}>
            <Close width="20" height="20" className="close" />
          </span>
          <div
            className={
              infoModalText?.includes("nieprawidłowy" || "invalid" || "pola")
                ? "newsletter-banner-invalid"
                : "newsletter-banner-correct"
            }>
            <p>{infoModalText}</p>
            {infoModalText?.includes("nieprawidłowy" || "invalid" || "pola") ? (
              <ErrorIcon
                width="261"
                height="206"
                className="image-info-modal"
              />
            ) : (
              <MessageIcon
                width="361"
                height="286"
                className="image-info-modal"
              />
            )}
          </div>
        </div>
      )}
      <div className="subscribe-banner-blog">
        <img
          className="subscribe-banner-blog__image"
          src={banner?.image?.url ?? ""}
          alt="banner"
        />
        <h3 className="subscribe-banner-blog__title">
          {RichText.asText(banner?.header?.raw || [])}
        </h3>
        <input
          type="email"
          value={email}
          onChange={onEmailChange}
          placeholder={RichText.asText(banner?.mail?.raw || [])}
          className="subscribe-banner-blog__email"
          style={{ border: email?.length > 0 ? "2px solid #001D62" : "" }}
        />
        <Select
          index={10}
          containerStyles={{
            height: "45px",
            margin: "0",
            width: "90%",
          }}
          defaultValue={RichText.asText(banner?.industry?.raw || [])}
          onChange={onSelectorChange}
          options={formOptions}
        />
        <button
          onClick={onSubmit}
          className="subscribe-banner-blog__button"
          disabled={formDisabled}>
          {RichText.asText(banner?.button?.raw || [])}
        </button>
        {language === "pl" ? (
          <p className="subscribe-banner-blog__privacy">
            Sprawdź naszą{" "}
            <Link to={`/${language}/privacy-policy`} className="subscribe-banner-blog__link">
              Politykę prywatności
            </Link>{" "}
            i dowiedz się, w jaki sposób przetwarzamy dane. W każdej chwili
            możesz przerwać subskrypcję newslettera bez ponoszenia kosztów.
          </p>
        ) : (
          <p className="subscribe-banner-blog__privacy">
            To learn how we process your data, visit our{" "}
            <Link to={`/${language}/privacy-policy`} className="subscribe-banner-blog__link">
              Privacy Policy.
            </Link>{" "}
            You can unsubscribe from the newsletter at any time without costs.
          </p>
        )}
      </div>
    </>
  );
};

export default SubscribeBanner;
