import React, { ReactNode } from 'react'
import { colors } from 'shared/constants'
import styled, { css } from 'styled-components'
import BtnArrowRight from 'assets/arrowbtn.svg'

interface StyledButtonProps {
  $display?: string
  $alignItems?: string
  $justifyContent?: string
  $gap?: string
  $minWidth?: string
  $maxWidth?: string
  $width?: string
  $minHeight?: string
  $maxHeight?: string
  $height?: string
  $padding?: string
  $fontSize?: string
  $fontWeight?: string
  $color?: string
  $bgColor?: string
  $border?: string
  $hoverBorder?: string
  $borderRadius?: string
  $borderColor?: string
  $position?: string
  $svgWidth?: string
  $svgHeight?: string
  $disabled?: boolean
}

const StyledButton = styled.div<StyledButtonProps>(({
  $display,
  $alignItems,
  $justifyContent,
  $gap,
  $padding,
  $minWidth,
  $maxWidth,
  $width,
  $minHeight,
  $maxHeight,
  $height,
  $fontSize,
  $fontWeight,
  $color,
  $bgColor,
  $border,
  $hoverBorder,
  $borderRadius,
  $borderColor,
  $position,
  $svgWidth,
  $svgHeight,
  $disabled = false,
}) => css`
  display: ${$display ?? 'flex'};
  gap: ${$gap ?? '1rem'};
  align-items: ${$alignItems ?? 'center'};
  justify-content: ${$justifyContent ?? 'center'};
  box-sizing: border-box;
  padding: ${$padding ?? '0.5rem 0.75rem'};
  min-width: ${$minWidth ?? '7.5rem'};
  min-height: ${$minHeight ?? '2.75rem'};
  font-size: ${$fontSize ?? '1rem'};
  font-weight: ${$fontWeight ?? '400'};
  color: ${$color ?? colors.white};
  background-color: ${$bgColor ?? colors.main};
  border: ${$border ?? `1px solid ${$borderColor ?? $color ?? colors.white}`};
  border-radius: ${$borderRadius ?? '0.375rem'};
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  svg {
    width: ${$svgWidth ?? '0.75rem'};
    height: ${$svgHeight ?? '0.75rem'};
    path {
      stroke: ${$color ?? colors.white};
    }
  }

  :hover {
    color: ${$bgColor ?? colors.main};
    background-color: ${$color ?? colors.white};
    border: ${$hoverBorder ?? `1px solid ${$bgColor ?? $borderColor ?? colors.main}`};

    svg {
      path {
        stroke: ${$bgColor ?? colors.main};
      }
    }
  }
  
  ${$maxWidth && css`max-width: ${$maxWidth};`}
  ${$width && css`width: ${$width};`}
  ${$maxHeight && css`max-height: ${$maxHeight};`}
  ${$height && css`height: ${$height};`}
  ${$position && css`position: ${$position};`}
  ${$disabled && css`
    cursor: default;
    background: #acacac;
    box-shadow: 0 0 5px rgba(128, 128, 128, 0.5);
    border-color: #acacac;
  `}
`)

interface SharedButtonProps {
  redirectsTo?: string
  onClick?(): void
  children?: ReactNode
  arrowRight?: boolean
  buttonProps?: StyledButtonProps
}

function SharedButton({ redirectsTo, children, onClick, buttonProps, arrowRight = false }: SharedButtonProps) {

  return (
    <StyledButton 
      onClick={() => {
        onClick?.()
        redirectsTo && window.location.assign(redirectsTo)
      }}
      {...buttonProps}
    >
      {children}
      {arrowRight ? <BtnArrowRight /> : null}
    </StyledButton>
  )
}

export default SharedButton
