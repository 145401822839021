import React, { useRef, useState } from 'react'
import { colors, productColors } from 'shared/constants'
import styled, { css } from 'styled-components'
import { RichText } from 'prismic-reactjs'
import { InputMaybe, PrismicProductHomeDataBodyHeroCardSlice, PrismicProductHomeDataBodyHeroCardSlicePrimary, Scalars } from 'types/declarations'
import { useMediaQuery } from 'utils/hooks/useMediaQuery'
import SharedButton from 'ui/SharedButton/SharedButton'
import PlayIcon from 'assets/playButton.svg'
import VideoModal from './components/VideoModal'

interface StyledSectionProps {
  backgroundColor?: InputMaybe<Scalars['String']>
}

const StyledSection = styled.section<StyledSectionProps>(({ backgroundColor }) => css`
  background-color: ${backgroundColor ? backgroundColor : colors.background};
  transition: all 0.5s ease-in-out;
`)

const StyledMainDiv = styled.div(() => {
  const { laptop, desktop, tablet, mobile } = useMediaQuery()

  return css`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    padding: ${tablet ? '105px 25px 0' : mobile ? '10px 15px 0' : '60px 84px 0'};
    max-width: 1440px;
    gap: ${(desktop || laptop) ? '2rem' : '1rem'};
  `
})

const StyledTitle = styled.h1(() => {
  const { tablet, mobile } = useMediaQuery()

  return css`
    font-weight: 700;
    font-size: ${tablet ? '2.375rem' : mobile ? '1.75rem' : '2.75rem'};
    line-height: ${tablet ? '2.875rem' : mobile ? '2.125rem' : '3.375rem'};
    max-width: 900px;
    margin: 0;
    text-align: center;
    color: ${colors.darkBlue};
  `
})

const StyledDescription = styled.div(() => {
  const { mobile } = useMediaQuery()

  return css`
    max-width: 940px;
    text-align: center;

    p {
      font-size: ${mobile ? '1.25rem' : '1.5rem'};
      font-weight: 400;
      line-height: ${mobile ? '1.75rem' : '2rem'};
      margin: 0;
      text-align: center;
      color: ${colors.darkBlue};

      em {
        background-color: ${productColors.violet.light};
        font-style: normal;
      }
    }

    h1, h2, h3, h4, h5, h6 {
      margin-top: 3rem;
      color: ${colors.darkBlue};
    }
  `
})

const StyledCTADiv = styled.div(() => {
  const { desktop, laptop } = useMediaQuery()

  return css`
    display: flex;
    justify-content: center;
    position: relative;
    flex-direction: ${(desktop || laptop) ? 'row' : 'column'};
    min-height: 4rem;
    gap: 2rem;
    width: 100%;
  `
})

const StyledCTAImg = styled.img(() => {
  const { desktop, laptop, tablet, mobile } = useMediaQuery()

  return css`
    width: ${(desktop || laptop) ? '220px' : '200px'};
    position: ${(desktop || laptop) ? 'absolute' : 'relative'};
    align-self: ${(tablet || mobile) ? 'center' : 'none'};
    left: ${(desktop || laptop) ? '-42px' : 0};
    top: 0;
    margin: 0;
  `
})

const StyledCTAButtonsDiv = styled.div(() => {
  const { mobile } = useMediaQuery()

  return css`
    display: flex;
    flex-direction: ${mobile ? 'column' : 'row'};
    width: ${mobile ? '100%' : 'fit-content'};
    align-items: center;
    align-self: center;
    gap: 1rem;
  `
})

const StyledVideoOverlayImgDiv = styled.div(() => css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`)

const StyledVideoOverlayImg = styled.img`
  margin: 0;
  border-radius: 1rem;
`

const StyledVideo = styled.video`
  height: 90vh;
  width: 90vw;
  border-radius: 1rem;
  border: none;
`

const StyledPlayButtonContentDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`

const StyledFooterDiv = styled.div(() => {
  const { mobile, tablet } = useMediaQuery()

  return css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: ${colors.white};
    min-height: ${mobile ? '188px' : tablet ? '160px' : '200px'};
    margin: 0 0 1rem;
  `
})

const StyledFooterText = styled.h3(() => {
  const { tablet, mobile } = useMediaQuery()

  return css`
    font-size: ${tablet ? '2.125rem' : mobile ? '1.625rem' : '2.25rem'};
    line-height: ${mobile ? '2.25rem' : '2.875rem'};
    font-weight: 700;
    max-width: 900px;
    text-align: center;
    margin: 0;
    color: ${colors.darkBlue};
  `
})

interface HeroProps {
  slice: PrismicProductHomeDataBodyHeroCardSlice
  onLeftButtonClick?(): void
}

function Hero({ slice, onLeftButtonClick }: HeroProps) {
  const mainDiv = useRef<HTMLDivElement>(null)
  const footerDiv = useRef<HTMLDivElement>(null)
  const { mobile } = useMediaQuery()
  const [showModal, setShowModal] = useState(false)

  const slicePrimary = slice.primary as PrismicProductHomeDataBodyHeroCardSlicePrimary
  const pointerImgUrl = slicePrimary?.pointer_img?.url
  const videoOverlayImgUrl = slicePrimary.video_overlay_img?.url
  const videoUrl = slicePrimary?.video_url?.raw.url
  const leftButtonText = RichText.asText(slicePrimary?.left_button_text?.richText)
  const rightButtonText = RichText.asText(mobile ? slicePrimary.video_play_button_text?.richText : slicePrimary?.right_button_text?.richText)
  const footerText = RichText.asText(slicePrimary?.footer?.richText)

  return (
    <StyledSection backgroundColor={slicePrimary?.section_background_color}>
      {!!videoUrl
        ? (
          <VideoModal onCloseButton={() => setShowModal(false)} shouldDisplay={showModal}>
            <StyledVideo
              src={videoUrl}
              autoPlay
              controls
              controlsList='nodownload noplaybackrate'
              disablePictureInPicture
            />
          </VideoModal>
        )
        : null
      }
      <StyledMainDiv ref={mainDiv}>
        <StyledTitle>{RichText.asText(slicePrimary?.title?.richText)}</StyledTitle>
        <StyledDescription>{RichText.render(slicePrimary?.description?.richText)}</StyledDescription>
        <StyledCTADiv>
          <StyledCTAButtonsDiv>
            <SharedButton
              buttonProps={{
                $color: productColors.violet.dark,
                $bgColor: colors.white,
                $width: mobile ? '100%' : undefined,
                $display: leftButtonText.length > 0 ? 'flex' : 'none'
              }}
              redirectsTo={slicePrimary.left_button_url?.raw.url}
              onClick={onLeftButtonClick}
            >
              {leftButtonText}
            </SharedButton>
            <SharedButton
              buttonProps={{
                $bgColor: productColors.violet.dark,
                $width: mobile ? '100%' : undefined,
                $svgWidth: '1.2rem',
                $svgHeight: '1.2rem',
                 $display: rightButtonText.length > 0 ? 'flex' : 'none'
              }}
              redirectsTo={mobile ? undefined : slicePrimary.right_button_url?.raw.url}
              onClick={() => mobile ? setShowModal(true) : undefined}
            >
              {mobile ? <PlayIcon /> : null}
              {rightButtonText}
            </SharedButton>
          </StyledCTAButtonsDiv>
          {!!pointerImgUrl && !mobile
            ? <StyledCTAImg alt='cta image' src={pointerImgUrl} />
            : null
          }
        </StyledCTADiv>
        {!!videoOverlayImgUrl
          ? (
            <StyledVideoOverlayImgDiv>
              {mobile
                ? null
                : (
                  <SharedButton
                    buttonProps={{
                      $position: 'absolute',
                      $bgColor: productColors.violet.dark,
                      $color: colors.white,
                      $minHeight: '4.75rem',
                      $svgWidth: '2.75rem',
                      $svgHeight: '2.75rem',
                    }}
                    onClick={() => setShowModal(true)}
                  >
                    <StyledPlayButtonContentDiv>
                      <PlayIcon />
                      {RichText.asText(slicePrimary.video_play_button_text?.richText)}
                    </StyledPlayButtonContentDiv>
                  </SharedButton>
                )
              }
              <StyledVideoOverlayImg src={videoOverlayImgUrl} />
            </StyledVideoOverlayImgDiv>
          )
          : null
        }
      </StyledMainDiv>
      {footerText.length
        ? (
          <StyledFooterDiv ref={footerDiv}>
            <StyledFooterText>{footerText}</StyledFooterText>
          </StyledFooterDiv>
        )
        : null}
    </StyledSection>
  )
}

export default Hero
