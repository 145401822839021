import * as React from "react";
import "./ProductFuncShowcase.scss";
import { RichText } from "prismic-reactjs";
import { useProductFunctionalitiesData } from "features/product-page/product-functionalities/productFunctionalitiesService";
import { ProductPageProps } from "features/product-page/product-homepage/productHomeService";
import { FC } from "react";

const ProductFuncShowcase: FC<ProductPageProps> = (props: ProductPageProps) => {
  const data = useProductFunctionalitiesData(props);

  return (
    <section className="product-func-showcase">
      <div className="product-func-showcase__inner">
        <div className="product-func-showcase__inner--left">
          <div>
            <h1>{RichText.asText(data?.hero_header?.raw || [])}</h1>
          </div>
        </div>
        <div className="product-func-showcase__inner--right">
          <img
            className="product-func-showcase-img"
            src={data?.hero_image?.url ?? ""}
            alt="Showcase Product Location"
          />
        </div>
      </div>
    </section>
  );
};

export default ProductFuncShowcase;
