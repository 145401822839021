import { RichText } from 'prismic-reactjs'
import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react'
import { colors, productColors } from 'shared/constants'
import styled, { css } from 'styled-components'
import { Maybe, PrismicProductHomeDataBodyPredictionModelsSliceItem } from 'types/declarations'
import { useMediaQuery } from 'utils/hooks/useMediaQuery'

const CardDiv = styled.div<{$isActive: boolean}>(({ $isActive }) => css`
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 1rem;
    padding: 1rem;
    cursor: pointer;
    border-top: 3px solid ${$isActive ? productColors.violet.dark : productColors.violet.light};
    background-color: ${$isActive ? productColors.violet.light : 'transparent'};
`)


const CardTitle = styled.h5(() => {
  const { tablet, mobile } = useMediaQuery()

  return css`
    font-weight: 700;
    font-size: ${mobile ? '1.2rem' : tablet ? '1.375rem' : '1.5rem'};
    line-height: ${mobile ? '1.65rem' : tablet ? '1.875rem' : '2rem'};
    margin: 0;
    color: ${colors.darkBlue};
  `
})

const CardDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  p {
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 400;
    color: ${colors.darkBlue};
    margin: 0;
  }
`

interface ModelCardProps {
  card: Maybe<PrismicProductHomeDataBodyPredictionModelsSliceItem | undefined>
  selectedCard: Maybe<PrismicProductHomeDataBodyPredictionModelsSliceItem | undefined>
  setSelectedCard: Dispatch<SetStateAction<Maybe<PrismicProductHomeDataBodyPredictionModelsSliceItem> | undefined>> 
}

function ModelCard({ card, selectedCard, setSelectedCard }: ModelCardProps) {
  const isActive = useMemo(() => card === selectedCard, [selectedCard])

  const setCardAsCurrent = useCallback(() => setSelectedCard(card), [])

  return (
    <CardDiv onClick={setCardAsCurrent} $isActive={isActive}>
      <CardTitle>{RichText.asText(card?.model_title?.richText)}</CardTitle>
      <CardDescription>{RichText.render(card?.model_description?.richText)}</CardDescription>
    </CardDiv>
  )
}

export default ModelCard
