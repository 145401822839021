import * as React from 'react';
import './Awards.scss';
import { RichText } from 'prismic-reactjs';
import AwardsGridItem from 'features/homepage/awards/AwardsGridItem';
import { useAwardsData } from 'features/homepage/awards/awardsService';
import styled, { css } from 'styled-components';
import { useMediaQuery } from 'utils/hooks/useMediaQuery';

const AwardsGrid = styled.div(() => {
  const { mobile } = useMediaQuery();
  return css`
    ${mobile &&
    css`
      flex-wrap: wrap;
      > div {
        flex-basis: 33%;
      }
    `}
  `;
});

const AwardsSection = styled.section(() => {
  const { mobile } = useMediaQuery();
  return css`
    padding: 2rem 0 4rem 0;
    ${mobile &&
    css`
      padding-bottom: 3rem;
    `}
  `;
});

const Awards = (): JSX.Element => {
  const data = useAwardsData();
  const awards = data.awards_images;

  return (
    <AwardsSection className='awards'>
      <div className='awards__inner'>
        <p className='awards__inner--heading'>
          {RichText.asText(data?.awards_subtitle?.raw || [])}
        </p>
        <AwardsGrid className='awards__inner--grid'>
          {awards?.map((award, index) => (
            <AwardsGridItem key={index} award={award} />
          ))}
        </AwardsGrid>
      </div>
    </AwardsSection>
  );
};

export default Awards;
