import IndexPage from './src/components/features/homepage/index';
import BlogHome from './src/components/features/blog/BlogHome';
import BlogPostDetails from './src/components/features/blog/post/PostDetails';
import ContactPage from './src/components/features/contact/ContactPage';
import ReportsHome from './src/components/features/reports/ReportsHome';
import ReportDetails from './src/components/features/reports/report/ReportDetails';
import PrivacyPolicy from './src/components/features/policy/PrivacyPolicy';
import Regulations from './src/components/features/policy/Regulations';
import TermsOfService from './src/components/features/policy/TermsOfService';
import SuccessStoriesHome from './src/components/features/success-stories/SuccessStoriesHome';
import SSPostDetails from './src/components/features/success-stories/post/PostDetails';
import CareerPage from './src/components/features/career/CareerPage';
import CareerPostDetails from './src/components/features/career/post/PostDetails';
import ProductFunctionalities from './src/components/features/product-page/product-functionalities/ProductFunctionalities';
import ProductBenefits from './src/components/features/product-page/product-benefits/ProductBenefits';
import PriceListPage from './src/components/features/product-page/product-pricelist/priceList/PriceListPage';
import ProductPlaceme from 'features/product-page/product-homepage/ProductPlaceme';
import ProductHome from 'features/product-page/product-homepage/ProductHome';
import Pricing from './src/components/features/pricing/Pricing';
import PlacemeFunctionality from 'features/product-page/placeme-functionality/PlacemeFunctionality';

export const componentResolverMap = {
  '/': IndexPage,
  'blog': BlogHome,
  'blog/*': BlogPostDetails,
  'contact': ContactPage,
  'kontakt': ContactPage,
  'report': ReportsHome,
  'report/*': ReportDetails,
  'privacy-policy': PrivacyPolicy,
  'regulations': Regulations,
  'terms-of-service': TermsOfService,
  'success-stories': SuccessStoriesHome,
  'success-stories/*': SSPostDetails,
  'career': CareerPage,
  'kariera': CareerPage,
  'kariera/*': CareerPostDetails,
  'career/*': CareerPostDetails,
  'placeme': ProductPlaceme,
  'placeme-functionalities': ProductFunctionalities,
  'placeme_functionality': PlacemeFunctionality,
  'placeme-benefits': ProductBenefits,
  'placeme-pricelist': PriceListPage,
  'pricing': Pricing,
  'target': ProductHome,
  'target-functionalities': ProductFunctionalities,
  'target-benefits': ProductBenefits,
  'target-pricelist': PriceListPage,
  'source': ProductHome,
  'source-functionalities': ProductFunctionalities,
  'source-benefits': ProductBenefits,
  'source-pricelist': PriceListPage,
};
