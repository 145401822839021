import * as React from "react";
import "./ProductBenefitsIntroductionGridBox.scss";
import { FC } from "react";
import ProductBenefitsIntroductionBox from "features/product-page/product-benefits/product-benefits-introduction/product-benefits-introduction-box/ProductBenefitsIntroductionBox";
import { PrismicProductBenefitsDataType } from "types/declarations";

export interface IntroductionGridBoxProps {
  data: PrismicProductBenefitsDataType;
}

const ProductBenefitsIntroductionGridBox: FC<IntroductionGridBoxProps> = ({
  data,
}: IntroductionGridBoxProps) => {
  return (
    <div className="product-benefits-introduction__grid">
      {data.hero_cards?.map((card, index) => <ProductBenefitsIntroductionBox key={index} imgSrc={card?.image?.url} description={card?.text?.raw} />)}
    </div>
  );
};

export default ProductBenefitsIntroductionGridBox;
