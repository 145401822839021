import * as React from 'react';
import './Cta.scss';
import { RichText } from 'prismic-reactjs';
import Button from 'ui/ButtonComponent/Button';
import { useCtaData } from 'features/homepage/cta/ctaService';
import styled, { css } from 'styled-components';
import { useMediaQuery } from 'utils/hooks/useMediaQuery';
import CtaCard from './CtaCard';

interface ICta {
  handleHubspot?: () => void;
}

const CtaHeading = styled.div(() => {
  const { mobile, tablet } = useMediaQuery();
  return css`
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    h3 {
      font-weight: 600;
      font-size: 2.5rem;
      line-height: 52px;
      color: #fff;
      margin-bottom: 1.56rem;
      ${tablet &&
      css`
        font-size: 1.5rem;
        line-height: 38px;
        margin: 0;
      `}
      ${mobile &&
      css`
        font-size: 1.5rem;
        line-height: 38px;
        margin: 0;
      `}
    }
  `;
});

const CtaCardsDiv = styled.div(() => {
  const { tablet, laptop, desktop } = useMediaQuery();
  return css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 3rem;
    ${(laptop || desktop) &&
    css`
      flex-direction: row;
      margin: 2rem 0 4rem 0;
    `}
    ${tablet &&
    css`
      width: 100%;
      gap: 3rem;
      margin: 2rem 0;
    `}
  `;
});

const ButtonDiv = styled.div(() => {
  const { mobile } = useMediaQuery();
  return css`
    display: flex;
    justify-content: center;
    ${mobile &&
    css`
      margin-top: 2rem;
    `}
  `;
});

const Cta = ({ handleHubspot }: ICta): JSX.Element => {
  const data = useCtaData();

  return (
    <section className='cta'>
      <div className='cta__inner'>
        <div>
          <CtaHeading>
            <h3>{RichText.asText(data?.cta_header?.raw || [])}</h3>
          </CtaHeading>
          <CtaCardsDiv>
            {data?.cta_section_cards?.map(card => (
              <CtaCard card={card} />
            ))}
          </CtaCardsDiv>
          <ButtonDiv>
            <Button
              as='navLink'
              onClick={handleHubspot}
              minWidth='15rem'
              arrowRight
              reverse
              borderRadius='10px'>
              {RichText.asText(data?.cta_button?.raw || [])}
            </Button>
          </ButtonDiv>
        </div>
      </div>
    </section>
  );
};

export default Cta;
