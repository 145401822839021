import * as React from "react";
import "./Showcase.scss";
import { RichText } from "prismic-reactjs";
import Button from "ui/button/Button";
import { useShowcaseData } from "features/career/items/showcase/showcaseService";
import { useSelector } from "react-redux";
import { RootState } from "utils/store/store";
import Breadcrumbs from "features/career/items/breadcrumbs/Breadcrumbs";
import { useLocale } from "locale/useLocale";

interface ShowcaseProps {
  click: () => void;
}

const Showcase = ({ click }: ShowcaseProps ): JSX.Element => {
  const data = useShowcaseData();
  const { language } = useSelector((state: RootState) => state.i18n);
  const locale = useLocale("Career");

  return (
    <section className="recruitment-showcase">
      <div className="recruitment-showcase__top">
        { language === "pl" ? 
          (<Breadcrumbs firstName="dataplace.ai" secondName="Kariera" secondLink="kariera" />) : 
          (<Breadcrumbs firstName="dataplace.ai" secondName="Career" secondLink="career" />)
        }
      </div>
      <div className="recruitment-showcase__inner">
        <div className="recruitment-showcase__inner--left">
          <div>
            <div className="recruitment-showcase__inner--left--category">
              {locale?.career}
            </div>
            <div className="recruitment-showcase__inner--left--title">
              <h1>{RichText.asText(data?.header_title?.raw || [])}</h1>
            </div>
            <div className="recruitment-showcase__inner--left--subtitle">
              {RichText.asText(data?.header_subtitle?.raw || [])}
            </div>
            <Button
              className="recruitment-showcase__inner--left--button"
              as="button"
              handleButtonClick={click}
              minWidth={210}
              filled
              arrowDown>
              {RichText.asText(data?.header_button?.raw || [])}
            </Button>
          </div>
        </div>
        <div className="recruitment-showcase__inner--right">
          <img
            className="recruitment-showcase__inner--right-img"
            src={data?.header_image?.url ?? ""}
            alt={data?.header_image?.alt ?? ""}
          />
        </div>
      </div>
    </section>
  );
};

export default Showcase;
