import { v4 as uuid } from "uuid";
import { SearchFilter } from "features/blog/blogModel";

export const defaultReportFilters = [
  {
    value: "dla Marketingu", 
    valueEn: 'for Marketing',
    id: uuid(),
    selected: false,
    icon: "https://i.imgur.com/kGIcmzf.png",
    iconSelected: "https://i.imgur.com/80xj4a3.png",
  },
  {
    value: "dla Sprzedaży", 
    valueEn: 'for Sales',
    id: uuid(),
    selected: false,
    icon: "https://i.imgur.com/9vOaqlH.png",
    iconSelected: "https://i.imgur.com/M6fNBkY.png",
  },
  {
    value: "dla Ekspansji",
    valueEn: 'for Expansion',
    id: uuid(),
    selected: false,
    icon: "https://i.imgur.com/7AfnLcm.png",
    iconSelected: "https://i.imgur.com/YsayUuh.png",
  },
  {
    value: "dla Centrali",
    valueEn: 'for Headquarters',
    id: uuid(),
    selected: false,
    icon: "https://i.imgur.com/N5hNn2q.png",
    iconSelected: "https://i.imgur.com/d6Le4fS.png",
  },
] as Array<SearchFilter>;
