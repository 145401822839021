import { useLocation } from '@reach/router'

interface PlacemeUrls {
  [key: string]: string
}

export function usePathNamesIncludes(placemeUrls: PlacemeUrls) {
  const location = useLocation()

  return Object.values(placemeUrls).some(url => location?.pathname?.includes(url))
}
