import { useDispatch, useSelector } from "react-redux";
import { RootState } from "utils/store/store";
import React, { useEffect } from "react";
import { getHomeReports } from "features/reports/reportsSlice";
import BigReportsItem from "features/reports/items/posts/BigReportsItem";
import "./HomeItems.scss";
import ReportsItem from "../items/posts/ReportsItem";
import { Language, setLanguage } from "utils/i18n/i18nSlice";

const HomeItems = (): JSX.Element => {
  const dispatch = useDispatch();
  const { homeReports } = useSelector((state: RootState) => state.reports);
  const reports = [...homeReports]?.sort((a, b) => new Date(a.date) > new Date(b.date) ? -1 : 1);

  useEffect(() => {
    dispatch(getHomeReports());
  }, [dispatch]);
  
  useEffect(() => {
    if (typeof window !== `undefined`) {
      const url = location?.pathname?.split('/').filter(item => item !== '')
  
      const findLangInUrl = (): number => url.findIndex(item => item === 'en-pl' || 'pl' || 'fr-fr' || 'de-de');
      const urlLanguage = url[findLangInUrl()] as Language
      dispatch(setLanguage(urlLanguage));
    }
  }, []);

  return (
    <>
      <BigReportsItem item={reports?.shift()} />
      <div className="home-items-wrap">
        <div className="home-report-items">
          { reports?.map((item, index)=> <ReportsItem item={item} key={index} />) }
        </div>
      </div>
    </>
  );
};

export default HomeItems;
