import React from "react";
import "./Breadcrumbs.scss";
import BtnArrowRight from "assets/arrowRight.svg";
import { Link } from "gatsby";
import { FC } from "react";
import { useSelector } from "react-redux";
import { RootState } from "utils/store/store";

export interface BreadcrumbsProps {
  firstName: string;
  secondName: string;
  secondLink?: string;
  thirdName?: string;
}

const Breadcrumbs: FC<BreadcrumbsProps> = ({
  firstName,
  secondName,
  thirdName, 
  secondLink
}: BreadcrumbsProps) => {
  const { language } = useSelector((state: RootState) => state.i18n);

  return (
    <div className="breadcrumbs">
      <p style={{cursor: 'default'}}>{firstName}</p>
      { !secondLink ? <p style={{cursor: 'default'}}><BtnArrowRight/> {secondName}</p> : '' }
      { secondLink ? <Link to={'/' + language + '/' + secondLink?.toLowerCase()  + '/'}><p className={!thirdName ? "last" : ""}><BtnArrowRight/> {secondName}</p></Link> : ''}
      { thirdName ? <p className="last"><BtnArrowRight/> {thirdName}</p> : '' }
    </div>
  );
};
export default Breadcrumbs;
