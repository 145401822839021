import { graphql, useStaticQuery } from "gatsby";
import {
  PrismicRecruitment,
  PrismicRecruitmentDataType,
  PrismicRecruitmentEdge,
} from "types/declarations";
import { useSelector } from "react-redux";
import { RootState } from "utils/store/store";
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews";

export const useCvBannerData = (): PrismicRecruitmentDataType => {
  const { language } = useSelector((state: RootState) => state.i18n);

  const resultS = useStaticQuery(graphql`
    {
      allPrismicRecruitment {
        totalCount
        edges {
          node {
            lang
            _previewable
            data {
              cv_description {
                raw
              }
              cv_image {
                alt
                url
              }
              cv_subtitle {
                raw
              }
              cv_title {
                raw
              }
            }
          }
        }
      }
    }
  `);
  
  const {data: result} = useMergePrismicPreviewData(resultS)
  return result.allPrismicRecruitment.edges
    .map((edge: PrismicRecruitmentEdge) => edge.node)
    .filter((node: PrismicRecruitment) => node.lang === language)
    .map((node: PrismicRecruitment) => node.data)
    .find(
      (data: PrismicRecruitmentDataType) =>
      data?.cv_title?.raw &&
      data?.cv_subtitle?.raw &&
      data?.cv_description?.raw
    );
};
