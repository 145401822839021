import React, { useCallback, useState } from "react";
import "./Faq.scss";
import { RichText } from "prismic-reactjs";
import { useFaqData } from "./faqService";
import Arrow from "images/arrow-right.svg";
import ArrowUp from "images/arrow-up.svg";
import ArrowDown from "images/arrow-down.svg";
import { FaqPoint } from "features/faq/faqModel";
import { useLocale } from "locale/useLocale";

const Faq = (): JSX.Element => {
  const locale = useLocale("Faq")
  const data = useFaqData();

  const [points, setPoints] = useState(
    data?.faq_point?.map((point, index) => {
      return {
        index,
        data: point,
        collapsed: index !== 0,
      } as FaqPoint;
    })
  );
    
  const onPointClick = useCallback(
    (point: FaqPoint) => {
      if (points) {
        const newPoints = [...points];
        const index = newPoints?.findIndex(p => p.index === point.index);
        if (index !== -1) {
          for (let i = 0; i < newPoints.length; i++) {
            newPoints[i].collapsed = true;
          }
          newPoints[index].collapsed = false;
          setPoints(newPoints);
        }
      }
    },
    [points]
  );

  return (
    <div className="faq">
      <div className="faq__inner">
        <div className="faq__inner--heading">
          <RichText render={data?.faq_header?.raw || []} />
        </div>
        {points?.map((point: FaqPoint) => (
          <div
            key={point.index}
            onClick={() => onPointClick(point)}
            className={`faq__inner__point-container ${
              point?.collapsed ? "" : "faq__inner__point-visible"
            }`}>
            <img style={{marginRight: '15px'}} src={Arrow} alt="arrow" />
            <div>
              <div className="faq__inner__point-container__heading">
                <div className="faq__inner__point-container__heading__text">
                  <RichText render={point?.data?.header?.raw || []} />
                </div>
                {!point?.collapsed && <img src={ArrowUp} alt="arrow up" />}
              </div>
              <div
                className={`faq__inner__point-container__description ${
                  point?.collapsed
                    ? "faq__inner__point-container__description-hidden"
                    : ""
                }`}>
                {!point?.collapsed
                  ? RichText.asText(point?.data?.text?.raw || [])
                  : RichText.asText(point?.data?.text?.raw || []).substr(0, 100)}
                {point?.collapsed && (
                  <span className="faq__inner__point-container__show-more">
                    {locale?.more} <img src={ArrowDown} alt="arrow-down" />
                  </span>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Faq;