import * as React from 'react';
import './CompanyInNumbers.scss';
import { RichText } from 'prismic-reactjs';
import { useCompanyInNumbersData } from './companyInNumbersService';
import CompanyInNumbersCard from './CompanyInNumbersCard';
import styled, { css } from 'styled-components';
import { useMediaQuery } from 'utils/hooks/useMediaQuery';

const CompanyInnerDiv = styled.div(() => {
  return css`
    margin: 0 2rem;
  `;
});

const CompanyInnerInfoDiv = styled.div(() => {
  const { tablet, laptop, desktop } = useMediaQuery();

  return css`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto 2rem auto;
    span {
      margin: 1.5rem 0;
      font-size: 19px;
      font-weight: 400;
      line-height: 3.25rem;
      letter-spacing: 2px;
      color: #001c61;
      text-transform: uppercase;
    }
    p {
      margin: 0;
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 3.25rem;
      text-align: center;
      color: #001c61;
      font-weight: 600;
    }
    ${tablet &&
    css`
      span {
        margin: 3rem 0 1rem 0;
      }
      p {
        font-size: 1.75rem;
        margin-bottom: 1rem;
      }
    `}
    ${(laptop || desktop) &&
    css`
      margin: 0 auto 5rem auto;
      span {
        margin: 3rem 0;
      }
      p {
        font-size: 2.75rem;
      }
    `}
  `;
});

const CardGridDiv = styled.div(() => {
  const { tablet, laptop, desktop } = useMediaQuery();
  return css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    ${tablet &&
    css`
      display: grid;
      justify-items: center;
      align-items: center;
      grid-template-columns: 1fr 1fr;
      padding-bottom: 3rem;
    `}
    ${(laptop || desktop) &&
    css`
      flex-direction: row;
      justify-content: space-evenly;
      padding-bottom: 7rem;
    `}
  `;
});

function CompanyInNumbers() {
  const data = useCompanyInNumbersData();

  return (
    <section className='company-numbers'>
      <CompanyInnerDiv>
        <CompanyInnerInfoDiv>
          <span>
            {RichText.asText(data?.company_in_numbers_title?.raw || [])}
          </span>
          <p>
            {RichText.asText(data?.company_in_numbers_description?.raw || [])}
          </p>
        </CompanyInnerInfoDiv>
        <CardGridDiv>
          {data?.company_in_numbers_card?.map(card => (
            <CompanyInNumbersCard card={card} />
          ))}
        </CardGridDiv>
      </CompanyInnerDiv>
    </section>
  );
}

export default CompanyInNumbers;
