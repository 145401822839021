module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManager":{"trackingId":"GTM-5VPJPWX","cookieName":"gatsby-gdpr-google-tagmanager","dataLayerName":"dataLayer"},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Dataplace.ai Website","short_name":"dataplace.ai","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"standalone","icon":"src/images/logos/dataplaceai-fav.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8752405c6eca1d88cc68a04b857ddf2d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"path":"/preview","lang":"*","repositoryName":"carcavelos","accessToken":"MC5ZRnh2b1JNQUFDTUFOcXRD.77-977-9eu-_ve-_vXfvv71277-9Mmvvv73vv73vv70nae-_ve-_vXzvv73vv70yc--_ve-_vTPvv70lG--_ve-_ve-_vQ","imageImgixParams":{"auto":"compress,format","fit":"max","q":85},"imagePlaceholderImgixParams":{"w":100,"blur":15,"q":85},"promptForAccessToken":true,"apiEndpoint":"https://carcavelos.cdn.prismic.io/api/v2","pageSize":100,"toolbar":"new"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
