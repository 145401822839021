import styled, { css } from 'styled-components';
import { useMediaQuery } from 'utils/hooks/useMediaQuery';

export const SectionTitle = styled.p`
  font-size: 19px;
  line-height: 52px;
  letter-spacing: 2px;
  color: #001c61;
  text-transform: uppercase;
  font-weight: normal;
  text-align: center;
  margin-bottom: 1rem;
`;

export const SectionHeading = styled.p(() => {
  const { mobile, tablet, laptop, desktop } = useMediaQuery();
  return css`
    align-self: center;
    justify-self: center;
    font-style: normal;
    font-weight: 600;
    text-align: center;
    color: #002163;
    max-width: 1003px;
    margin-left: 1rem;
    margin-right: 1rem;

    ${mobile &&
    css`
      font-size: 22px;
      line-height: 40px;
    `}

    ${tablet &&
    css`
      font-size: 30px;
      line-height: 52px;
    `}

    ${laptop &&
    css`
      font-size: 30px;
      line-height: 52px;
    `}

    ${desktop &&
    css`
      font-size: 40px;
      line-height: 52px;
    `}
  `;
});
