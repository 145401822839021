import { useStaticQuery } from "gatsby";
import { graphql } from "gatsby";
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews";
import { useSelector } from "react-redux";
import {
  PrismicGenericPopup,
  PrismicGenericPopupDataType,
  PrismicGenericPopupEdge,
} from "types/declarations";
import { RootState } from "utils/store/store";

export const useGenericPopupData = (): PrismicGenericPopupDataType => {
  const { language } = useSelector((state: RootState) => state.i18n);

  const resultS = useStaticQuery(graphql`
    {
      allPrismicGenericPopup {
        edges {
          node {
            lang
            _previewable
            data {
              button_label {
                raw
              }
              description {
                raw
                text
              }
              hubspot_form_id {
                text
              }
              input_label {
                raw
              }
              input_label_2 {
                raw
              }
              input_label_3 {
                raw
              }
              input_placeholder {
                text
              }
              input_placeholder_2 {
                text
              }
              privacy_policy {
                raw
              }
              success_message {
                raw
                text
              }
              title {
                raw
              }
              body {
                ... on PrismicGenericPopupDataBodyImage {
                  id
                  slice_type
                  primary {
                    image {
                      alt
                      url
                    }
                  }
                }
                ... on PrismicGenericPopupDataBodyText {
                  id
                  slice_type
                  primary {
                    text {
                      raw
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const {data: result} = useMergePrismicPreviewData(resultS)
  return result.allPrismicGenericPopup.edges
    .map((edge: PrismicGenericPopupEdge) => edge?.node)
    .filter((node: PrismicGenericPopup) => node?.lang === language)
    .map((node: PrismicGenericPopup) => node?.data)[0];
};

export const postGenericPopupForm = (
  email: string,
  firstName: string,
  company: string,
  lang: string,
  setSuccessMessage: React.Dispatch<React.SetStateAction<string | undefined>>,
  formId: string,
  successMessage: string,
): void => {
  const getUrlByLanguage = (lang: string) => {
    switch (lang) {
      case "pl":
        return `https://api.hsforms.com/submissions/v3/integration/submit/20363741/${formId}`;
      case "en-pl":
        return `https://api.hsforms.com/submissions/v3/integration/submit/20363741/${formId}`;
      default:
        return `https://api.hsforms.com/submissions/v3/integration/submit/20363741/${formId}`;
    }
  };
  const xhr = new XMLHttpRequest();
  const url = getUrlByLanguage(lang);
  // Example request JSON:
  const data = {
    fields: [
      {
        name: "email",
        value: email,
      },
      {
        name: "firstname",
        value: firstName,
      },
      {
        name: "company",
        value: company,
      },
    ],

    legalConsentOptions: {
      // Include this object when GDPR options are enabled
      consent: {
        consentToProcess: true,
        text: "I agree to allow Example Company to store and process my personal data.",
        communications: [
          {
            value: true,
            subscriptionTypeId: 999,
            text: "I agree to receive marketing communications from Example Company.",
          },
        ],
      },
    },
  };

  const final_data = JSON.stringify(data);

  xhr.open("POST", url);
  // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
  xhr.setRequestHeader("Content-Type", "application/json");
  xhr.onreadystatechange = function () {
    if (xhr.readyState == 4 && xhr.status === 200) {
      setSuccessMessage(successMessage);
    }
  };

  // Sends the request
  xhr.send(final_data);
};
