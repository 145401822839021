import React from "react";
import "./Banners.scss";
import Button from "ui/ButtonComponent/Button";
import { useLocale } from "locale/useLocale";
import { useSelector } from "react-redux";
import { RootState } from "utils/store/store";


const CtaBanner = (): JSX.Element => {
  const locale = useLocale("SuccessStory");
  const { language } = useSelector((state: RootState) => state.i18n);

  return (
    <div className="cta-banner-succes-stories">
      <div className="cta-banner-succes-stories__inner">
        <span className="cta-banner-succes-stories__inner--label">
        {locale?.ctaTitle}
        </span>
        <p className="cta-banner-succes-stories__inner--text">
          {locale?.ctaDescription}
        </p>
        <Button
          className="cta-banner-succes-stories__inner--button"
          as="navLink"
          to={`/${language}/contact/`}
          minWidth='180px'
          arrowRight>
            {locale?.ctaButton}
        </Button>
      </div>
    </div>
  );
};

export default CtaBanner;
