import React, { MutableRefObject } from "react";
import "./Offers.scss";
import { useSelector } from "react-redux";
import { RootState } from "utils/store/store";
import { Link } from "gatsby";
import Arrow from "assets/arrowbtn.svg";
import VerticalLine from "assets/vertical_line.svg";
import { useLocale } from "locale/useLocale";

interface OfferProps {
  reference: MutableRefObject<HTMLDivElement>;
}

const Offers = ({ reference }: OfferProps): JSX.Element => {
  const locale = useLocale("Career");
  const { allRecruitmentPosts } = useSelector(
    (state: RootState) => state.recruitment
  );

  return (
    <div ref={reference} className="offers">
      <div className="offers__inner">
        <div className="offers__inner--title">{locale?.positions}</div>
        <div
          className="offers__inner--container"
          style={
            allRecruitmentPosts?.length < 3
              ? { justifyContent: "flex-start" }
              : { justifyContent: "space-between" }
          }>
          {allRecruitmentPosts?.map((post, index) => (
            <Link
              to={post?.link}
              key={index}
              className="offers__inner--container---item">
              <div>
                {post?.tags?.map((tag, i) => (
                  <div className="offers__inner--container---item_tag" key={i}>
                    {tag?.value}
                  </div>
                ))}
                <h3 className="offers__inner--container---item_title">
                  {post?.title}
                </h3>
                <p className="offers__inner--container---item_salary">
                  {post?.salary}
                </p>
                <div className="offers__inner--container---item_info">
                  <span>{post?.worktime}</span> <VerticalLine />
                  <span>{post?.location}</span> <VerticalLine />
                  <span>{post?.contract}</span>
                </div>
                <p className="offers__inner--container---item_description">
                  {post?.description}
                </p>
              </div>
              <div className="offers__inner--container---item_button">
                {locale?.findMore} <Arrow />
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Offers;
