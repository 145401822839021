import { Link } from "gatsby";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import {
  Maybe,
  PrismicSuccessStoryDataBodyCredentialsBannerPrimary,
} from "types/declarations";
import { RootState } from "utils/store/store";
import "./CredentialsBanner.scss";

export interface CredentialsBannerProps {
  banner:
    | Maybe<PrismicSuccessStoryDataBodyCredentialsBannerPrimary>
    | undefined;
}

const CredentialsBanner: FC<CredentialsBannerProps> = ({
  banner,
}: CredentialsBannerProps) => {
  const { language } = useSelector((state: RootState) => state.i18n);

  return (
    <Link to={'/'+ language + '/' + banner?.banner_link?.raw[0]?.text ?? ''} className="credentials-banner">
      <img
        className="credentials-banner__main-img"
        src={banner?.banner_img?.url ?? ""}
        alt={banner?.banner_img?.alt ?? ""}
      />
      <div className="credentials-banner__content">
        <h3>{banner?.banner_title?.raw[0]?.text}</h3>
        <img
          src={banner?.banner_client_logo?.url ?? ""}
          alt={banner?.banner_client_logo?.alt ?? ""}
        />
        <h5>{banner?.banner_person?.raw[0]?.text}</h5>
      </div>
    </Link>
  );
};
export default CredentialsBanner;
