import { graphql, useStaticQuery } from "gatsby";
import {
  PrismicProductBenefits,
  PrismicProductBenefitsDataType,
  PrismicProductBenefitsEdge,
  PrismicProductHome,
} from "types/declarations";
import { useSelector } from "react-redux";
import { RootState } from "utils/store/store";
import { ProductPageProps } from "features/product-page/product-homepage/productHomeService";
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews";

export const useProductBenefitsData = ({
  productName,
}: ProductPageProps): PrismicProductBenefitsDataType => {
  const { language } = useSelector((state: RootState) => state.i18n);
  const resultS = useStaticQuery(graphql`
    {
      allPrismicProductBenefits {
        totalCount
        edges {
          node {
            lang
            tags
            _previewable
            data {
              case_studies {
                case_study_tag {
                  raw
                }
                case_study_button {
                  raw
                }
                case_study_description {
                  raw
                }
                case_study_header {
                  raw
                }
                case_study_image {
                  url
                }
                case_study_link {
                  raw
                }
              }
              case_studies_text {
                raw
              }
              case_studies_description {
                raw
              }
              hero_header {
                raw
              }
              hero_subheader {
                raw
              }
              hero_cards {
                image {
                  url
                  alt
                }
                text {
                  raw
                }
              }
              hero_image {
                url
                alt
              }
              benefits_cta_button {
                raw
              }
              benefits_cta_header {
                raw
              }
              benefits_header {
                raw
              }

              benefits_sections {
                button {
                  raw
                }
                image {
                  url
                  alt
                }
                text {
                  raw
                }
                link {
                  raw
                }
              }
            }
          }
        }
      }
    }
  `);
  const {data: result} = useMergePrismicPreviewData(resultS)
  return result.allPrismicProductBenefits.edges
    .map((edge: PrismicProductBenefitsEdge) => edge.node)
    .filter((node: PrismicProductBenefits) => node.lang === language)
    .filter((node: PrismicProductHome) => node?.tags?.includes(productName))
    .map((node: PrismicProductBenefits) => node.data)[0];
};
