import * as React from 'react';
import './Clients.scss';
import { FC } from 'react';
import { PrismicClientsDataType } from 'types/declarations';
import ClientsGridItem, {
  ClientsGridItemProps,
} from 'features/clients/ClientsGridItem';
import styled, { css } from 'styled-components';
import { useMediaQuery } from 'utils/hooks/useMediaQuery';

export interface ClientsGridProps {
  data: PrismicClientsDataType;
}

const sectionHeight = {
  mobileHeight: '80px',
  desktopHeight: '90px',
};

const Section = styled.section(() => {
  const { mobile } = useMediaQuery();

  return css`
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    height: ${sectionHeight.desktopHeight};
    ${mobile &&
    css`
      height: ${sectionHeight.mobileHeight};
    `}
  `;
});

const Div = styled.div<{
  iconCount?: number;
  width: string;
  logoMargin: string;
}>(({ iconCount, width, logoMargin }) => {
  const { mobile, tablet } = useMediaQuery();
  const animationSpeed = mobile ? '40s' : tablet ? '50s' : '60s';

  return css`
    display: flex;
    position: absolute;
    justify-content: left;
    overflow: hidden;
    animation: scroll-primary ${animationSpeed} linear infinite;
    width: calc((${width} + ${logoMargin}) * ${iconCount} * 2);
    height: ${sectionHeight.desktopHeight};

    ${mobile &&
    css`
      height: ${sectionHeight.mobileHeight};
    `}

    @keyframes scroll-primary {
      0% {
        transform: translateX(0);
      }

      100% {
        transform: translateX(
          calc((-${width} - ${logoMargin}) * (${iconCount}))
        );
      }
    }
  `;
});

const ClientsGrid: FC<ClientsGridProps> = ({ data }: ClientsGridProps) => {
  const shuffledData = data?.client_images?.sort(() => Math.random() - 0.5);
  const { mobile } = useMediaQuery();
  const iconWidth = mobile ? '130px' : '162px';
  const logoMargin = mobile ? '20px' : '60px';

  return (
    <Section>
      <Div
        iconCount={shuffledData?.length}
        width={iconWidth}
        logoMargin={logoMargin}>
        {shuffledData &&
          [...shuffledData, ...shuffledData]?.map((client, index) => (
            <ClientsGridItem
              key={index}
              item={client}
              width={iconWidth}
              logoMargin={logoMargin}
            />
          ))}
      </Div>
    </Section>
  );
};

export default ClientsGrid;
