import React, { FC, useCallback } from "react";
import styled, {css} from 'styled-components'
import {
  Maybe,
  PrismicBlogPostBodyProductBannerItemType,
} from "types/declarations";
import "./PostBanners.scss";
import { RichText } from "prismic-reactjs";
import {Link} from "gatsby";
import BtnArrowRight from "assets/arrowbtn.svg";
import Placeme from "assets/placeme.svg";
import Target from "assets/target.svg";
import Source from "assets/source.svg";
import { useSelector } from "react-redux";
import { RootState } from "utils/store/store";


const hexToRgba = (hex: string, a: number) => {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function(r, g, b) {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, ${a})` : null;
}

const Wrapper = styled.div(({spanColor} : {spanColor: string})=> {
  return css `
    & > .product-banner {
      background-color: ${hexToRgba(spanColor, 0.04)} !important;
      border-radius: 4px;
      border-top: 4px solid ${spanColor};
    }
  `
})
export interface ProductBannerProps {
  banner: Maybe<PrismicBlogPostBodyProductBannerItemType>;
}

const ProductBanner: FC<ProductBannerProps> = ({banner}: ProductBannerProps) => {
  const { language } = useSelector((state: RootState) => state.i18n);
  const title = RichText.asText(banner?.banner_title?.raw || []);

  const getIcon = useCallback(() => {
    if (title.includes("placeme")) {
      return <Placeme />;
    } else if (title.includes("target")) {
      return <Target />;
    } else {
      return <Source />;
    } 
  }, [title]);

  return (
    <Wrapper spanColor={banner?.color || ''}>
      <div className="product-banner">
        <div className="product-banner__heading">
          <h4>{ language === 'pl' ? 'Wypróbuj' : 'Try' } {RichText.asText(banner?.banner_title?.raw || []).toUpperCase()} { language === 'pl' ? 'za darmo' : 'for free' }</h4>
          {getIcon()}
        </div>
        <div className="product-banner__content">
          <RichText render={banner?.text?.raw || []}/>
          <div className="product-banner__buttons">
            {RichText.asText(banner?.first_button_text?.raw || []).length > 0 ?  <Link to={`/${language}${RichText.asText(banner?.first_button_link?.raw || [])}`}>{RichText.asText(banner?.first_button_text?.raw || [])} <BtnArrowRight /></Link> : null}
            <div className="label">
              <div>
                { language === 'pl' ? 'ZA DARMO' : 'FREE' }
              </div>
              <p>{RichText.asText(banner?.sub_text?.raw || [])}</p>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
export default ProductBanner;
