import React, { FC } from "react";
import "./Banners.scss";
import { BlogReportBanner } from "features/blog/blogModel";
import { Link } from "gatsby";
import { RichText } from "prismic-reactjs";
import { useSelector } from "react-redux";
import { RootState } from "utils/store/store";

export interface SignUpBannerProps {
  banner: BlogReportBanner;
}

const SignUpBanner: FC<SignUpBannerProps> = ({ banner }: SignUpBannerProps) => {
  const { language } = useSelector((state: RootState) => state.i18n);

  return (
    <div className="sign-up-banner">
      <img className="sign-up-banner__image" src={banner?.image?.url ?? ""} alt="colorful graphic" />
      <div className="sign-up-banner__title">
        <RichText render={banner?.title?.raw || []} />
      </div>
      <Link
        className="sign-up-banner__link"
        to={`/${language}/report/${RichText.asText(banner?.link?.raw || [])}/`}>
        {RichText.asText(banner?.button?.raw || [])} 
      </Link>
    </div>
  );
};

export default SignUpBanner;
