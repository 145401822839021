import * as React from "react";
import "./Navbar.scss";
import NavbarArrowBottom from "assets/navbar-arrow-bottom.svg";
import { FC } from "react";

export interface NavbarItemProps {
  children: React.ReactNode;
  showDropdownArrow?: boolean;
  active?: boolean;
  button?: boolean;
  filledButton?: boolean;
  className?: string;
}

const NavbarItem: FC<NavbarItemProps> = ({
  children,
  showDropdownArrow,
  active,
  button,
  filledButton,
  className,
}: NavbarItemProps) => {
  return (
    <li className={`${active ? "navbar-item active" : "navbar-item"} ${button ? "button" : ""} ${filledButton ? "filled-button" : ""} ${className ? className : ""}`}>
      {children}
      {showDropdownArrow && (
        <NavbarArrowBottom className="navbar-item__arrowBottom" />
      )}
    </li>
  );
};

export default NavbarItem;
