import { BlogPost } from './../blogModel';
import { Pagination } from "features/blog/blogModel";
import { Report } from 'features/reports/reportsModel';

export function paginate(array: BlogPost[] | Report[], pagination: Pagination): (BlogPost[] | Report[]) {
  // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
  return array.slice(
    (pagination.page - 1) * pagination.pageSize,
    pagination.page * pagination.pageSize
  );
}
