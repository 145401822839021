import React, { FC } from "react";
import "./SimilarPosts.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "utils/store/store";
import BtnArrowRight from "assets/arrowbtn.svg";
import {
  Maybe,
  PrismicStructuredTextType,
  PrismicSuccessStoryBodySimmilarSuccessStoryItemType,
} from "types/declarations";
import styled, { css } from "styled-components";
import Placeme from "assets/placemeWhite.svg";
import Target from "assets/target-white.svg";
import Source from "assets/source-white.svg";
import { Link } from "gatsby";
import { getStoryPostDetails } from "features/success-stories/successStoriesSlice";

interface SimilarPostsProps {
  items:
    | Maybe<Maybe<PrismicSuccessStoryBodySimmilarSuccessStoryItemType>[]>
    | undefined;
}

const Span = styled.span(({ productColor }: { productColor: string }) => {
  return css`
    background: ${productColor};
    font-weight: 600;
    border-radius: 8px;
    padding: 7px 15px;
    svg {
      max-width: 120px;
    }
  `;
});

const SimilarPosts: FC<SimilarPostsProps> = ({ items }: SimilarPostsProps) => {
  const { language } = useSelector((state: RootState) => state.i18n);
  const dispatch = useDispatch();

  const getProduct = (
    product: Maybe<PrismicStructuredTextType> | undefined
  ) => {
    switch (product) {
      case "placeme":
        return (
          <div className="success-story-post__tags--tag">
            <Span productColor="#362FB7">
              <Placeme />
            </Span>
          </div>
        );
      case "target":
        return (
          <div className="success-story-post__tags--tag">
            <Span productColor="#CC3C00">
              <Target />
            </Span>
          </div>
        );
      case "source":
        return (
          <div className="success-story-post__tags--tag">
            <Span productColor="#6830B2">
              <Source />
            </Span>
          </div>
        );
      default:
        return (
          <div className="success-story-post__tags--tag">
            <Span productColor="#6830B2">{product}</Span>
          </div>
        );
    }
  };

  return (
    <div className="similar-posts">
      <div className="similar-posts__inner">
        <p className="similar-posts__inner--title">
          {language === "pl"
            ? "Podobne Historie klientów"
            : "Similar Success Stories"}
        </p>
        <div className="similar-posts__inner--content">
          {items?.map((item, index) => {
            const link = item?.similar_link?.raw[0]?.text;

            return (
              <Link
                className="success-story-post"
                key={index}
                to={`/${language}/success-stories/${item?.similar_link?.raw[0]?.text}/`}
                onClick={() => dispatch(getStoryPostDetails({ link }))}>
                {getProduct(item?.similar_products?.raw[0]?.text)}
                <div className="success-story-post__top">
                  <div className="success-story-post__top--logo">
                    <img
                      src={item?.similar_client?.url ?? ""}
                      alt={item?.similar_client?.alt ?? ""}
                    />
                  </div>
                  <div className="success-story-post__top--category">
                    {item?.similar_industry?.raw[0]?.text}
                  </div>
                </div>
                <h3 className="success-story-post__title">
                  {item?.similar_title?.raw[0]?.text}
                </h3>
                <p className="success-story-post__description">
                  {item?.similar_description?.raw[0]?.text}
                </p>
                <div className="success-story-post__link">
                  {language === "pl" ? "Poznaj historię" : "Read more"}
                  <BtnArrowRight />
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default SimilarPosts;
