import { graphql, useStaticQuery } from "gatsby";
import {
  PrismicRecruitment,
  PrismicRecruitmentDataType,
  PrismicRecruitmentEdge,
} from "../../../../../types/declarations";
import { useSelector } from "react-redux";
import { RootState } from "../../../../utils/store/store";
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews";

export const useCompanyData = (): PrismicRecruitmentDataType => {
  const { language } = useSelector((state: RootState) => state.i18n);
  const resultS = useStaticQuery(graphql`
    {
      allPrismicRecruitment {
        totalCount
        edges {
          node {
            lang
            _previewable
            data {
              company_columns {
                column_description {
                  raw
                }
                column_image {
                  alt
                  url
                }
                column_title {
                  raw
                }
              }
              company_header {
                raw
              }
            }
          }
        }
      }
    }
  `);
  const {data: result} = useMergePrismicPreviewData(resultS)
  return result.allPrismicRecruitment.edges
    .map((edge: PrismicRecruitmentEdge) => edge?.node)
    .filter((node: PrismicRecruitment) => node?.lang === language)
    .map((node: PrismicRecruitment) => node?.data)[0];
};
