import { useSelector } from 'react-redux';
import { RootState } from 'utils/store/store';
import i18n_pl from "./pl.json";
import i18n_en_pl from "./en-pl.json";
import i18n_fr_fr from "./fr-fr.json";
import i18n_de_de from "./de-de.json";
import { Language } from 'utils/i18n/i18nSlice';

export function useLocale(componentName: string): Record<string, string> {
  const { language } = useSelector((state: RootState) => state.i18n);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const langs: { [key in Language]: any } = {
      'pl': i18n_pl,
      'en-pl': i18n_en_pl,
      'de-de': i18n_de_de,
      'fr-fr': i18n_fr_fr,
  };
  
  return langs[language][componentName];
}

