/* eslint-disable no-useless-escape */
import { RichText } from "prismic-reactjs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./GenericPopup.scss";
import {
  postGenericPopupForm,
  useGenericPopupData,
} from "./genericPopupService";
import Close from "assets/close.svg";
import { useSelector } from "react-redux";
import { RootState } from "utils/store/store";
import { Maybe } from "graphql/jsutils/Maybe";
import {
  PrismicGenericPopupDataBodyImage,
  PrismicGenericPopupDataBodyImagePrimary,
  PrismicGenericPopupDataBodySlicesType,
  PrismicGenericPopupDataBodyText,
  PrismicGenericPopupDataBodyTextPrimary,
} from "types/declarations";
import { v4 as uuid } from "uuid";
import { navigate } from "gatsby";

export const GenericPopup = (): JSX.Element => {
  const data = useGenericPopupData();
  const re = new RegExp("[^@s]+@[^@s]+.[^@s]+");
  const { language } = useSelector((state: RootState) => state.i18n);

  const [modalDisplay, setModalDisplay] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | undefined>(
    undefined
  );
  const [mail, setMail] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [company, setCompany] = useState<string>("");

  const formDisabled = useMemo(() => {
    return !mail || !re.test(mail) || !name;
  }, [mail, name]);

  const onMailChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setMail(e.target.value);
  }, []);

  const onNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  }, []);

  const onCompanyChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setCompany(e.target.value);
  }, []);

  const onSubmitForm = useCallback(() => {
    if (!formDisabled) {
      postGenericPopupForm(
        mail,
        name,
        company,
        language,
        setSuccessMessage,
        data?.hubspot_form_id?.text ?? "",
        data?.success_message?.text ?? ""
      );
    }
  }, [mail, name, company]);

  const manageScroll = (position: string): void => {
    document.body.style.position = position;
  };

  useEffect(() => {
    if (typeof window !== `undefined` && modalDisplay) {
      manageScroll("fixed");
    }
  }, [modalDisplay]);

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setTimeout(() => setModalDisplay(true), 6000)
    }
  }, []);

  return modalDisplay ? (
    <div className="popup-overlay">
      <div className="generic-popup">
        <div className="generic-popup__left">
          {data?.body?.map(
            (
              slice: Maybe<PrismicGenericPopupDataBodySlicesType>,
              index: number
            ) => {
              if (slice?.slice_type === "text") {
                const textSlice = slice as PrismicGenericPopupDataBodyText;
                const text =
                  textSlice?.primary as PrismicGenericPopupDataBodyTextPrimary;
                return (
                  <RichText
                    key={index + uuid()}
                    render={text?.text?.raw || []}
                  />
                );
              }
              if (slice?.slice_type === "image") {
                const imageSlice = slice as PrismicGenericPopupDataBodyImage;
                const image =
                  imageSlice?.primary as PrismicGenericPopupDataBodyImagePrimary;
                return (
                  <img
                    key={index + uuid()}
                    src={image?.image?.url ?? ""}
                    alt={image?.image?.alt ?? ""}
                  />
                );
              }
              return <div key={index + uuid()} />;
            }
          )}
        </div>
        <div className="generic-popup__right">
          <div className="generic-popup__right__top_wrapper">
            <RichText render={data?.title?.raw} />
            <span
          onClick={() => {
            setModalDisplay(false);
            manageScroll("initial");
          }}>
          <Close width="30" height="30" />
        </span>
            </div>
          {data?.description?.text !== '' && <div className="generic-popup__right--description">
             <RichText render={data?.description?.raw} />
          </div>}
          {successMessage ? (
            <div className="success">
              <RichText render={data?.success_message?.raw} />
            </div>
          ) : (<>
              <div>
                <div className="generic-popup__right--label">
                  <RichText render={data?.input_label?.raw || []} />
                </div>
                <input
                  type="email"
                  pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                  required
                  placeholder={
                    data?.input_placeholder?.text || "example@mail.com"
                  }
                  value={mail}
                  onChange={onMailChange}
                />
                <div className="generic-popup__right--label popup-input">
                <RichText render={data?.input_label_2?.raw || []} />
                </div>
                <input
                  type="name"
                  required
                  placeholder={
                    data?.input_placeholder_2?.text || "Jan Kowalski"
                  }
                  value={name}
                  onChange={onNameChange}
                />
                    <div className="generic-popup__right--label popup-input">
                    <RichText render={data?.input_label_3?.raw || []} />
                </div>
                <input
                  type="company"
                  value={company}
                  onChange={onCompanyChange}
                />
                <div className="generic-popup__right--policy">
                  <RichText render={data?.privacy_policy?.raw || []} />
                </div>
              </div>
              <div className="generic_popup_buttons_wrapper">
              <button onClick={onSubmitForm} className={`${formDisabled ? 'button-disabled': '' } generic_popup_button`} disabled={formDisabled}>
                {RichText.asText(data?.button_label?.raw || [])}
              </button>
              <button onClick={()=>{ navigate('http://dataplace-20363741.hs-sites.com/pl-pl/teaser-0-0')}} className='generic_popup_button orange_generic_popup_button'>Zamów raport</button>
              </div>
          </>
          )}
        </div>
   
      </div>
    </div>
  ) : (
    <div />
  );
};
