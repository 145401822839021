import React, { useCallback } from "react";
import "./FreeTrialBanner.scss";
import Button from "ui/button/Button";
import { navigate } from "gatsby";
import FTBannerImg from "assets/FTBannerImg.svg";
import FTBannerImgLight from "assets/FTBannerImgLight.svg";
import ProductsLogosWhite from "../products-logos-bar/ProductsLogosWhite";
import { useLocale } from "locale/useLocale";
import ProductsLogos from "../products-logos-bar/ProductsLogos";

export interface FreeTrialBannerProps {
  light?: boolean
}

const FreeTrialBanner = ({ light }: FreeTrialBannerProps): JSX.Element => {
  const locale = useLocale("FreeTrialBanner");

  const onDemoClick = useCallback(() => {
    navigate('https://app.dataplace.ai/auth/sign-in');
  }, []);

  return (
    <div className={`free-trial-banner ${light ? 'light' : 'dark'}`}>
      <div className="free-trial-banner__inner">
        <div className="free-trial-banner__inner--left">
          <h3 className="free-trial-banner__inner--left--title">
            {locale?.ftbTitle}
          </h3>
          <div className="mobile-img">
            { light ? <FTBannerImgLight /> : <FTBannerImg /> }
          </div>
          <p className="free-trial-banner__inner--left--text">
            {locale?.ftbDescription}
          </p>
          <Button
            className="free-trial-banner__inner--left--button"
            as="button"
            handleButtonClick={onDemoClick}
            minWidth={180}
            arrowRight>
            {locale?.ftbButton}
          </Button>
          <span className="free-trial-banner__inner--left--label">
            {locale?.ftbLabel}
          </span>
          { light ? <ProductsLogos /> : <ProductsLogosWhite /> }
        </div>
        <div className="free-trial-banner__inner--right">
          { light ? <FTBannerImgLight /> : <FTBannerImg /> }
        </div>
      </div>
    </div>
  );
};

export default FreeTrialBanner;
