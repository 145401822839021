import React, { useEffect } from "react";
import { PageProps } from "gatsby";
import Layout from "containers/layout/Layout";
import { RichText } from "prismic-reactjs";
import { PolicyModel } from "components/features/policy/policyModel";
import { useDispatch } from "react-redux";
import { Language, setLanguage } from "utils/i18n/i18nSlice";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import Seo from "utils/seo/Seo";
const linkResolver = require('../../../../linkResolver.js');

const TermsOfService = (props: PageProps): JSX.Element => {
  const context = props.pageContext as PolicyModel;
  const dispatch = useDispatch();

  useEffect(() => {
    if (typeof window !== `undefined`) {
      const url = location?.pathname?.split('/').filter(item => item !== '')
  
      const findLangInUrl = (): number => url.findIndex(item => item === 'en-pl' || 'pl' || 'fr-fr' || 'de-de');
      const urlLanguage = url[findLangInUrl()] as Language
      dispatch(setLanguage(urlLanguage));
    }
  }, []);

  return (
    <>
      <Seo title='term of service' />
      <Layout>
        <section className='regulations'>
          <div className='regulations__inner'>
          <RichText render={context?.content?.raw || []} />
          </div>
        </section>
      </Layout>
    </>
  );
};

export default withPrismicPreview(TermsOfService, [
  {
    repositoryName: 'carcavelos',
    linkResolver: linkResolver.linkResolver,
  },
]);
