import React, { MutableRefObject, useEffect, useRef } from "react";
import "./ReportDetails.scss";
import { useDispatch, useSelector } from "react-redux";
import { PageProps } from "gatsby";
import { RootState } from "utils/store/store";
import { getReportDetails } from "features/reports/reportsSlice";
import { Report } from "features/reports/reportsModel";
import ReportsLayout from "containers/reports-layout/ReportsLayout";
import ReportForm from "features/reports/report/ReportForm";
import Breadcrumbs from "../items/breadcrumbs/Breadcrumbs";
import CaseStudies from "features/case-studies/CaseStudies";
import {
  Maybe,
  PrismicReportDataBodyReportBanner,
  PrismicReportDataBodyReportFacts,
  PrismicReportDataBodyReportFactsPrimary,
  PrismicReportDataBodyReportSummary,
  PrismicReportDataBodyReportSummaryPrimary,
  PrismicReportDataBodySlicesType,
  PrismicReportDataType,
} from "types/declarations";
import { RichText, RichTextBlock } from "prismic-reactjs";
import ReportBanner from "./ReportBanner";
import Seo from "utils/seo/Seo";
import { useLocale } from "locale/useLocale";
import { Language, setLanguage } from "utils/i18n/i18nSlice";
import { checkForPopup } from "components/shared-components/popups/generic-popup/checkForPopupService";
import { GenericPopup } from "components/shared-components/popups/generic-popup/GenericPopup";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
const linkResolver = require('../../../../../linkResolver.js');

const ReportDetails = (props: PageProps): JSX.Element => {
  const dispatch = useDispatch();
  const { language } = useSelector((state: RootState) => state.i18n);
  const { reportDetails } = useSelector((state: RootState) => state.reports);
  const link = (props.pageContext as Partial<Report>)?.link;
  const details = reportDetails as PrismicReportDataType;
  const locale = useLocale("Report");
  const formSection = useRef() as MutableRefObject<HTMLDivElement>;
  const displayPopup = checkForPopup()
    ?.page?.text?.split(";")
    ?.filter(p => p !== "")
    ?.includes("report_post");

  useEffect(() => {
    dispatch(getReportDetails({ link }));
  }, [link, language]);

  const transformedText = () => {
    const newPoints: RichTextBlock[] = [];

    reportDetails?.points?.raw?.map((obj: RichTextBlock) => {
      const newText = obj?.text && obj?.text?.replace(";", "");

      newPoints.push({
        text: newText,
        type: obj?.type,
        spans: obj?.spans,
      });
    });

    return newPoints;
  };

  const onHeaderButtonClick = () => {
    if (typeof window !== "undefined") {
      window.scrollTo({
        top: formSection.current.offsetTop - 40,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (typeof window !== `undefined`) {
      const url = location?.pathname?.split("/").filter(item => item !== "");

      const findLangInUrl = (): number =>
        url.findIndex(item => item === "en-pl" || "pl" || "fr-fr" || "de-de");
      const urlLanguage = url[findLangInUrl()] as Language;
      dispatch(setLanguage(urlLanguage));
    }
  }, []);

  return (
    <>
      <Seo
        title={reportDetails?.seo_title}
        description={reportDetails?.seo_description}
        canonicalUrl={reportDetails?.seo_canonical_url}
      />

      {displayPopup && <GenericPopup />}

      <ReportsLayout>
        <div style={{ position: "relative" }}>
          <div className="header-container">
            <div className="header-container__inner">
              <Breadcrumbs
                firstName="Wiedza i raporty"
                secondName="Raporty branżowe"
                secondLink="report"
                thirdName={reportDetails?.title}
              />
              <div className="header-container__inner--wrap">
                <div className="header-container__hero">
                  <div className="header-container__hero__header-flex">
                    <div className="header-container__hero__header-flex__header">
                      <div style={{ display: "flex" }}>
                        {reportDetails?.tags?.map((tag, index) => (
                          <p key={index} className="label">
                            {tag?.value}
                          </p>
                        ))}
                        <p className="report-label">{reportDetails?.label}</p>
                      </div>
                      <h1>{reportDetails?.title}</h1>
                    </div>
                    <div className="header-container__hero__header-flex__description">
                      <img
                        src={reportDetails?.image?.url ?? ""}
                        alt={reportDetails?.image?.alt ?? ""}
                      />
                      <div style={{ margin: "10px 0" }}>
                        <RichText render={transformedText() ?? []} />
                      </div>
                    </div>
                    <button onClick={onHeaderButtonClick}>
                      {locale?.button}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="content-container">
            <div className="content-container__inner">
              <div className="content-container__inner--wrap">
                <div className="content-container__content">
                  {reportDetails?.content?.map(
                    (
                      slice: Maybe<PrismicReportDataBodySlicesType>,
                      index: number
                    ) => {
                      if (slice?.slice_type === "report_summary") {
                        const textSlice =
                          slice as PrismicReportDataBodyReportSummary;
                        const text =
                          textSlice?.primary as PrismicReportDataBodyReportSummaryPrimary;
                        return (
                          <div key={index} className="report_summary">
                            <h3>{RichText.asText(text?.title1?.raw || [])}</h3>
                            <RichText render={text?.content?.raw || []} />
                          </div>
                        );
                      }
                      return null;
                    }
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="banner-container">
            <div className="banner-container__inner">
              <div className="banner-container__inner--wrap">
                <div className="banner-container__content">
                  {reportDetails?.content?.map(
                    (
                      slice: Maybe<PrismicReportDataBodySlicesType>,
                      index: number
                    ) => {
                      if (slice?.slice_type === "report_banner") {
                        const bannerSlice =
                          slice as PrismicReportDataBodyReportBanner;
                        if (bannerSlice) {
                          return (
                            <ReportBanner content={bannerSlice} key={index} />
                          );
                        }
                      }
                      return null;
                    }
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="facts-container">
            <div className="facts-container__inner">
              <div className="facts-container__inner--wrap">
                <div className="facts-container__content">
                  {reportDetails?.content?.map(
                    (
                      slice: Maybe<PrismicReportDataBodySlicesType>,
                      index: number
                    ) => {
                      if (slice?.slice_type === "report_facts") {
                        const textSlice = slice as PrismicReportDataBodyReportFacts;
                        const text =
                          textSlice?.primary as PrismicReportDataBodyReportFactsPrimary;
                        return (
                          <div key={index} className="report_facts">
                            <h3>
                              <img
                                src={text?.image?.url ?? ""}
                                alt={text?.image?.alt ?? ""}
                              />
                              {RichText.asText(text?.title1?.raw || [])}
                            </h3>
                            <RichText render={text?.content?.raw || []} />
                          </div>
                        );
                      }
                      return null;
                    }
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="form-container" ref={formSection}>
            <ReportForm report={reportDetails} />
          </div>
          <div className="fixed-container">
            <div className="fixed-container__inner">
              <ReportForm report={reportDetails} arrow />
            </div>
          </div>
        </div>
        <CaseStudies data={details} />
      </ReportsLayout>
    </>
  );
};
export default withPrismicPreview(ReportDetails, [
  {
    repositoryName: 'carcavelos',
    linkResolver: linkResolver.linkResolver,
  },
]);
