import React, { ForwardedRef, forwardRef, useMemo, useState } from 'react'
import { useMediaQuery } from 'utils/hooks/useMediaQuery'
import styled, { css } from 'styled-components'
import { colors, productColors } from 'shared/constants'
import { 
  Maybe,
  PrismicProductHomeDataBodyPredictionModelsSlice,
  PrismicProductHomeDataBodyPredictionModelsSliceItem,
} from 'types/declarations'
import ModelCard from './components/ModelCard'
import { RichText } from 'prismic-reactjs'
import SharedButton from 'ui/SharedButton/SharedButton'

const StyledSection = styled.section`
  background-color: ${colors.background};
  scroll-margin-top: 4.5rem;
`

const MainDiv = styled.div(() => {
  const { tablet, mobile } = useMediaQuery()

  return css`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    gap: 1rem;
    max-width: 1440px;
    padding: ${tablet ? '60px 25px' : mobile ? '35px 15px' : '60px 84px'};
    align-items: center;
  `
})

const StyledTitle = styled.h2(() => {
  const { mobile, tablet } = useMediaQuery()

  return css`
    color: ${colors.darkBlue};
    font-weight: 700;
    font-size: ${mobile ? '1.875rem' : tablet ? '2.25rem' : '2.5rem'};
    line-height: ${mobile ? '2.5rem' : '3.25rem'};
    max-width: ${mobile ? '100%' : tablet ? '95%' : '80%'};
    text-align: center;
    margin: 0;
  `
})

const ImgDiv = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    position: relative;
  `

const ImgDivBackground = styled.div`
    bottom: 0;
    min-height: 80%;
    min-width: 100%;
    align-self: flex-end;
    border-radius: 12px;
    background-color: ${productColors.violet.light};
    position: absolute;
  `

const StyledImg = styled.img(() => {
  const { desktop, laptop, tablet } = useMediaQuery()

  return css`
    height: ${(desktop || laptop) ? '500px' : tablet ? '440px' : '220px'};
    margin: 0;
    z-index: 10;
  `
})

const CardsDiv = styled.div(() => {
  const { desktop, laptop } = useMediaQuery()

  return css`
    display: flex;
    flex-direction: ${(desktop || laptop) ? 'row' : 'column'};
    gap: 1rem;
  `
})

const ButtonDiv = styled.div(() => {
  const { tablet } = useMediaQuery()

  return css`
    display: flex;
    width: 100%;
    justify-content: ${tablet ? 'flex-start' : 'center'};
  `
})

interface PredictionModelsProps {
  slice: PrismicProductHomeDataBodyPredictionModelsSlice
}

const PredictionModels = forwardRef(({ slice }: PredictionModelsProps, ref: ForwardedRef<HTMLElement>) => {
  const slicePrimary = slice.primary
  const sliceItems = slice.items
  const { mobile } = useMediaQuery()
  const [selectedCard, setSelectedCard] = useState<Maybe<PrismicProductHomeDataBodyPredictionModelsSliceItem> | undefined>(sliceItems?.[0])
  const imgUrl = useMemo(() => selectedCard?.model_img?.url, [selectedCard])


  return (
    <StyledSection ref={ref}>
      <MainDiv>
        <StyledTitle>{RichText.asText(slicePrimary?.title?.richText)}</StyledTitle>
        <ImgDiv>
          {!!imgUrl 
            ? <StyledImg src={imgUrl} alt='model image' />
            : null
          }
          <ImgDivBackground />
        </ImgDiv>
        <CardsDiv>
          {sliceItems?.map((item, index) => (
            <ModelCard 
              key={index}
              card={item}
              selectedCard={selectedCard}
              setSelectedCard={setSelectedCard}
            />
          ))}
        </CardsDiv>
        <ButtonDiv>
          <SharedButton 
            buttonProps={{
              $color: productColors.violet.dark,
              $bgColor: colors.background,
              $width: mobile ? '100%' : 'fit-content',
            }}
            redirectsTo={slicePrimary?.button_url?.raw.url}
          >
            {RichText.asText(slicePrimary?.button_text?.richText)}
          </SharedButton>
        </ButtonDiv>
      </MainDiv>
    </StyledSection>
  )
})

export default PredictionModels
