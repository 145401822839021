import * as React from "react";
import "./BlogLayout.scss";
import { FC } from "react";
import Footer from "features/footer/Footer";
import RootContainer from "containers/RootContainer";
import Header from "components/shared-components/header/Header";

export interface BlogLayoutProps {
  children: React.ReactNode;
}

const BlogLayout: FC<BlogLayoutProps> = ({ children }: BlogLayoutProps) => {
  return (
    <RootContainer>
      <Header />
      <main className="main-blog">{children}</main>
      <Footer />
    </RootContainer>
  );
};

export default BlogLayout;
