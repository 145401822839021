import "./ReportsItems.scss";
import Arrow from "images/arrow-right.svg";
import React, { FC } from "react";
import { Link } from "gatsby";
import BtnArrowRight from "assets/arrowbtn.svg";
import { Report } from "features/reports/reportsModel";
import { RichText } from "prismic-reactjs";
import { useSelector } from "react-redux";
import { RootState } from "utils/store/store";

export interface ReportItemProps {
  item: Report | undefined;
}

const ReportsItem: FC<ReportItemProps> = ({ item }: ReportItemProps) => {
  const { language } = useSelector((state: RootState) => state.i18n);
  const points = RichText.asText(item?.points?.raw || [])?.split(';');
  
  return (
    <div className="report-item">
      <Link to={item?.link + "/"}>
        <img className="report-item__image" src={item?.minImage?.url ?? ""} alt="colorful graphic" />
        <div className="report-item__inner">
          <div>
            <div className="tags">
              {item?.tags?.map(tag => (
                <span key={tag?.value} className="tag">
                  {tag?.value}
                </span>
              ))}
            </div>
            <h3 className="report-item__title">{item?.title}</h3>
            <div className="points">
              {points?.map((point, index) => (
                <div
                  key={index}
                  style={{
                    margin: "10px 0",
                    display: "flex",
                    alignItems: "start",
                  }}>
                  <img style={{ margin: "15px 15px 0 0" }} src={Arrow} alt="arrow" /> {point}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="report-item__link">
          {language === "pl" ? "Dowiedz się więcej" : "Find out more"}
          <BtnArrowRight />
        </div>
      </Link>
    </div>
  );
};

export default ReportsItem;
