import { v4 as uuid } from "uuid";
import { SearchFilter } from "features/blog/blogModel";

export const defaultFilters = [
  {
    value: "Wszystko",
    id: uuid(),
    selected: true,
    valueEn: "All",
    icon: "https://carcavelos.cdn.prismic.io/carcavelos/6594acfc-cd6b-40ac-ab65-2761bab87449_filter.svg",
    iconSelected: "https://carcavelos.cdn.prismic.io/carcavelos/d66c2ae3-8c75-4a32-934a-35730d658a5b_filter-selected.svg",
  },
  {
    value: "placeme",
    id: uuid(),
    selected: false,
    icon: "https://i.imgur.com/wdNRwN9.png",
    iconSelected: "https://i.imgur.com/uKChsCM.png",
  },
  {
    value: "target",
    id: uuid(),
    selected: false,
    icon: "https://i.imgur.com/oZtLzYV.png",
    iconSelected: "https://i.imgur.com/GOUX0xo.png",
  },
  {
    value: "source",
    id: uuid(),
    selected: false,
    icon: "https://i.imgur.com/O9RMVka.png",
    iconSelected: "https://i.imgur.com/P0NrsH1.png",
  },
  {
    value: "Raporty",
    id: uuid(),
    selected: false,
    link: "report",
    valueEn: "Reports",
    icon: "https://carcavelos.cdn.prismic.io/carcavelos/6594acfc-cd6b-40ac-ab65-2761bab87449_filter.svg",
    iconSelected: "https://carcavelos.cdn.prismic.io/carcavelos/d66c2ae3-8c75-4a32-934a-35730d658a5b_filter-selected.svg",
  },
  {
    value: "Trendy",
    id: uuid(),
    selected: false,
    valueEn: "Trends",
    icon: "https://carcavelos.cdn.prismic.io/carcavelos/6594acfc-cd6b-40ac-ab65-2761bab87449_filter.svg",
    iconSelected: "https://carcavelos.cdn.prismic.io/carcavelos/d66c2ae3-8c75-4a32-934a-35730d658a5b_filter-selected.svg",
  },
  {
    value: "Location intelligence",
    id: uuid(),
    selected: false,
    icon: "https://carcavelos.cdn.prismic.io/carcavelos/6594acfc-cd6b-40ac-ab65-2761bab87449_filter.svg",
    iconSelected: "https://carcavelos.cdn.prismic.io/carcavelos/d66c2ae3-8c75-4a32-934a-35730d658a5b_filter-selected.svg",
  },
] as Array<SearchFilter>;
