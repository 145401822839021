import * as React from "react";
import "containers/reports-layout/ReportsLayout.scss";
import { FC } from "react";
import Footer from "features/footer/Footer";
import RootContainer from "containers/RootContainer";
import Header from "components/shared-components/header/Header";

export interface ReportsLayoutProps {
  children: React.ReactNode;
}

const ReportsLayout: FC<ReportsLayoutProps> = ({
  children,
}: ReportsLayoutProps) => {
  return (
    <RootContainer>
      <Header />
      <main className="main-reports">{children}</main>
      <Footer />
    </RootContainer>
  );
};

export default ReportsLayout;
