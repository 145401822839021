import * as React from 'react';
import './Footer.scss';
import PlacemeColor from 'assets/logos/dataplaceai-company-color.svg';
import FooterGridBox from 'features/footer/footer-grid-box/FooterGridBox';
import FooterMenu from 'features/footer/footer-menu/FooterMenu';
import styled, { css } from 'styled-components';
import Policies from './footer-policies/Policies';
import { useMediaQuery } from 'utils/hooks/useMediaQuery';

const FooterInnerDiv = styled.div(() => {
  const { tablet, mobile } = useMediaQuery()

  return css`
    display: flex;
    flex-direction: column;
    gap: 3rem;
    max-width: 1440px;
    margin: 0 auto;
    padding: ${tablet ? '105px 25px 0' : mobile ? '105px 15px 0' : '60px 84px 0'};
  `
})

const Footer = (): JSX.Element => (
  <footer className='footer'>
    <FooterInnerDiv>
      <PlacemeColor width='238' height='50' />
      <FooterGridBox isBig={false} />
      <FooterMenu />
      <Policies />
    </FooterInnerDiv>
  </footer>
);

export default Footer;
