import { graphql, useStaticQuery } from "gatsby";
import {
  PrismicHomepage,
  PrismicHomepageDataType,
  PrismicHomepageEdge,
} from "../../../../types/declarations";
import { useSelector } from "react-redux";
import { RootState } from "../../../utils/store/store";
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews";

export const useShowcaseData = (): PrismicHomepageDataType => {
  const { language } = useSelector((state: RootState) => state.i18n);
  const resultS = useStaticQuery(graphql`
    {
      allPrismicHomepage {
        totalCount
        edges {
          node {
            lang
            _previewable
            data {
              hero_image {
                url
                alt
              }
              hero_title {
                raw
              }
              hero_subtitle {
                raw
              }
              free_trial_button {
                raw
              }
              demo_button {
                raw
              }
              seo_title {
                text
              }
              seo_description {
                text
              }
              seo_canonical_url {
                url
              }
            }
          }
        }
      }
    }
  `);
  const {data: result} = useMergePrismicPreviewData(resultS)
  return result.allPrismicHomepage.edges
    .map((edge: PrismicHomepageEdge) => edge.node)
    .filter((node: PrismicHomepage) => node.lang === language)
    .map((node: PrismicHomepage) => node.data)
    .find(
      // Find first non null value
      (data: PrismicHomepageDataType) =>
        data.hero_title?.raw &&
        data.hero_subtitle?.raw &&
        data.free_trial_button?.raw &&
        data.demo_button?.raw
    );
};
