import * as React from "react";
import "./Company.scss";
import { RichText } from "prismic-reactjs";
import { useCompanyData } from "./companyService";

const Company = (): JSX.Element => {
  const data = useCompanyData();

  return (
    <section className="company">
      <div className="company__inner">
            <div className="company__inner--title">
              <h2>{RichText.asText(data?.company_header?.raw || [])}</h2>
            </div>
            <div className="company__inner--columns">
              {data?.company_columns?.map((column, index) => <div className="company__inner--columns--column" key={index}>
                    <img src={column?.column_image?.url ?? ""} alt={column?.column_image?.alt ?? ""} />
                    <div>
                      <div className="company__inner--columns--column-title">
                        <p>{RichText.asText(column?.column_title?.raw || [])}</p>
                      </div>
                      <div className="company__inner--columns--column-description">
                        <p>{RichText.asText(column?.column_description?.raw || [])}</p>
                      </div>
                    </div>
                  </div>
              )}
            </div>
        </div>
    </section>
  );
};

export default Company;
