import BlogItem from "features/blog/items/posts/BlogItem";
import React, { useEffect, useMemo } from "react";
import "./HomeItems.scss";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "utils/store/store";
import { getHomeBanners, getHomeBlogPosts } from "features/blog/blogSlice";
import BigBlogItem from "features/blog/items/posts/BigBlogItem";
import CtaBanner from "features/blog/items/banners/CtaBanner";
import { v4 as uuid } from "uuid";
import {
  HomeItem,
  isBlogPost,
  Tag,
  BlogBannerDTO,
  isFormBanner,
  isReportBanner,
} from "features/blog/blogModel";
import SignUpBanner from "features/blog/items/banners/SignUpBanner";
import {
  Maybe,
  PrismicBlogBodyBanners,
  PrismicBlogBodyBannersItemType,
  PrismicBlogBodyFormBanners,
  PrismicBlogBodyFormBannersItemType,
  PrismicBlogBodySlicesType,
} from "types/declarations";
import { RichText } from "prismic-reactjs";
import SubscribeBanner from "features/blog/items/banners/SubscribeBanner";

const HomeItems = (): JSX.Element => {
  const dispatch = useDispatch<Dispatch>();
  const {
    homePosts,
    allBlogPosts,
    homeBannersDTO,
    searchFilters,
  } = useSelector((state: RootState) => state.blog);
  const sortedHomePosts = [...homePosts]?.sort((a, b) => {
    const toInt = (value: string) => value === '' ? 99999999 : parseInt(value)

    return toInt(a.order) - toInt(b.order)
  });

  const items = useMemo(() => {
    let bannersLength = 0;

    homeBannersDTO?.forEach(banner => {
      let len = 0;
      banner?.body?.forEach(body => {
        len += body?.items?.length ?? 0;
      });
      bannersLength += len;
    });
    const length = sortedHomePosts?.length + bannersLength;
    const featuredItemIndex = sortedHomePosts?.findIndex(item => item?.isFeatured);

    const array = Array(length) as Array<HomeItem>;
    for (let i = 0; i < sortedHomePosts?.length; i++) {
      if (!sortedHomePosts[i]?.isFeatured) {
        array[i] = { ...sortedHomePosts[i] };
      }
    }

    homeBannersDTO.forEach((banner: BlogBannerDTO) => {
      banner?.body?.forEach((slice: Maybe<PrismicBlogBodySlicesType>) => {
        if (slice?.slice_type === "banners") {
          const formBanner = slice as PrismicBlogBodyFormBanners;

          formBanner?.items?.forEach(
            (item: Maybe<PrismicBlogBodyFormBannersItemType>) => {
              if (item) {
                const pos = Number(RichText.asText(item?.position?.raw || []));
                if (array[pos]) {
                  array?.splice(pos, 0, item);
                } else {
                  array[pos] = item;
                }
              }
            }
          );
        } else if (slice?.slice_type === "form_banners") {
          const reportBanner = slice as PrismicBlogBodyBanners;
          reportBanner?.items?.forEach(
            (item: Maybe<PrismicBlogBodyBannersItemType>) => {
              if (item) {
                const pos = Number(RichText.asText(item?.position?.raw || []));
                if (array[pos]) {
                  array?.splice(pos, 0, item);
                } else {
                  array[pos] = item;
                }
              }
            }
          );
        }
      });
    });
    if (featuredItemIndex !== -1) {
      array.splice(0, 0, sortedHomePosts[featuredItemIndex]);
    }

    return array;
  }, [sortedHomePosts, homeBannersDTO]);

  useEffect(() => {
    dispatch(getHomeBanners());
    dispatch(getHomeBlogPosts());
  }, [dispatch, allBlogPosts]);

  const checkIfIsAnyItem = () => {
    let isAnyItem = false;
    items?.map(item => {
      if (item?.content) {
        isAnyItem = true;
      }
    });
    return isAnyItem;
  };

  const possiblyInteresting = allBlogPosts?.filter((_, index) => {
    return index < 3;
  });

  return (
    <section>
      <div className="home-blog-items">
        {items?.slice(0, 7).map((item, index) => {
          if (isBlogPost(item)) {
            if (item?.isFeatured) {
              return <BigBlogItem key={uuid()} item={item} />;
            } else {
              return <BlogItem key={uuid()} item={item} />;
            }
          } else if (isFormBanner(item)) {
            const getAllIndex = searchFilters?.findIndex((tag: Tag) =>
              tag?.value?.toLowerCase()?.includes("wszystko")
            );
            return getAllIndex !== -1 &&
              searchFilters[getAllIndex]?.selected ? (
              <SubscribeBanner key={index} banner={item} />
            ) : null;
          } else if (isReportBanner(item)) {
            const getAllIndex = searchFilters?.findIndex((tag: Tag) =>
              tag?.value?.toLowerCase()?.includes("wszystko")
            );
            return getAllIndex !== -1 &&
              searchFilters[getAllIndex]?.selected ? (
              <SignUpBanner key={index} banner={item} />
            ) : null;
          } else {
            return null;
          }
        })}
      </div>
      {homePosts?.length > 4 && <CtaBanner />}
      <div className="home-blog-items">
        {items?.slice(7, items.length)?.map((item, index) => {
          if (isBlogPost(item)) {
            if (item?.isFeatured) {
              return <BigBlogItem key={uuid()} item={item} />;
            } else {
              return <BlogItem key={uuid()} item={item} />;
            }
          } else if (isFormBanner(item)) {
            const getAllIndex = searchFilters?.findIndex((tag: Tag) =>
              tag?.value?.toLowerCase()?.includes("wszystko")
            );
            return getAllIndex !== -1 &&
              searchFilters[getAllIndex]?.selected ? (
              <SubscribeBanner key={index} banner={item} />
            ) : null;
          } else if (isReportBanner(item)) {
            const getAllIndex = searchFilters?.findIndex((tag: Tag) =>
              tag?.value?.toLowerCase()?.includes("wszystko")
            );
            return getAllIndex !== -1 &&
              searchFilters[getAllIndex]?.selected ? (
              <SignUpBanner key={index} banner={item} />
            ) : null;
          } else {
            return null;
          }
        })}
      </div>
      {!checkIfIsAnyItem() && (
        <>
          <p>Brak postów dla wybranego taga</p> <h2>Może Cię zainteresować</h2>
          <div className="row-wrapper">
            {possiblyInteresting?.map(result => (
              <BlogItem key={uuid()} item={result} />
            ))}
          </div>
        </>
      )}
    </section>
  );
};

export default HomeItems;
