/* eslint-disable no-useless-escape */
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import Select from "ui/select/Select";
import {
  useContactData,
  postContactForm,
} from "features/contact/contactService";
import { RichText } from "prismic-reactjs";
import { RootState } from "utils/store/store";
import { useSelector } from "react-redux";
import { Link } from "gatsby";
import Close from "assets/close.svg";
import MessageIcon from "assets/message.svg";
import Info from "assets/info.svg";
import { useLocale } from "locale/useLocale";
import { emailRegex } from "utils/constants";

const ContactForm: FC = (): JSX.Element => {
  const contact = useContactData();
  const locale = useLocale("Contact");
  const { language } = useSelector((state: RootState) => state.i18n);
  const contactFormRef = useRef<HTMLDivElement>(null)

  const [mail, setMail] = useState<string>("");
  const [company, setCompany] = useState<string>("");
  const [department, setDepartment] = useState<string>("");
  const [newsletter, setNewsletter] = useState<string>("Yes");
  const [infoModalDisplay, setModalDisplay] = useState(false);

  const departmentOptions = RichText.asText(
    contact.form_departament_options?.raw || []
  ).split(";");

  const formDisabled = useMemo(() => {
    return !mail || !company || !department || !emailRegex.test(mail);
  }, [mail, company, department]);

  const onMailChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setMail(e.target.value);
  }, []);

  const onCompanyChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setCompany(e.target.value);
  }, []);

  const onDepartmentChange = useCallback((value: string) => {
    setDepartment(value);
  }, []);

  const onNewsletterChange = useCallback((value: boolean) => {
    value === true ? setNewsletter("Yes") : setNewsletter("No");
  }, []);

  const onSubmitForm = useCallback(() => {
    if (!formDisabled) {
      postContactForm(
        {
          mail,
          company,
          department,
          newsletter,
        },
        language,
        setModalDisplay
      );
    }
  }, [mail, company, department, newsletter]);

  useEffect(() => {
    contactFormRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
  }, [])

  return (
    <>
      {infoModalDisplay && (
        <div className="info-modal-newsletter">
          <span
            onClick={() => {
              setModalDisplay(false);
            }}>
            <Close width="20" height="20" className="close" />
          </span>
          <div className="contact-correct">
            <p>{locale.emailSuccess}</p>
            <MessageIcon
              width="361"
              height="286"
              className="image-info-modal"
            />
          </div>
        </div>
      )}
      <div className="contact-container__hero__form-flex scroll_margin" ref={contactFormRef}>
        <div>{RichText.asText(contact.form_header?.raw || [])}</div>
        <p>{locale.formDescription}</p>
        <div>{RichText.asText(contact.form_mail?.raw || [])}</div>
        <input
          type="email"
          pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
          required
          placeholder="jan@kowalski.com"
          value={mail}
          onChange={onMailChange}
        />
        <div>{RichText.asText(contact.form_industry?.raw || [])}</div>
        <input
          type="text"
          placeholder="e.g. Retail"
          required
          value={company}
          onChange={onCompanyChange}
        />
        <div>{RichText.asText(contact.form_departament?.raw || [])}</div>
        <Select
          defaultValue={locale?.choose}
          onChange={onDepartmentChange}
          index={10}
          options={departmentOptions}
        />
        <div className="contact-container__hero__form-flex__checkbox-container">
          <label className="contact-container__hero__form-flex__checkbox-container">
            <p className="text">
              {locale.newsletter}
              <span className="tooltip1">
                <Info />
                <span>
                  {locale.tooltipOneFirst}
                  <Link to={`/${language}/privacy-policy/`}>{locale.tooltipOneLink}</Link>
                  {locale.tooltipOneSecond}
                </span>
              </span>
            </p>
            <input
              type="checkbox"
              onChange={e => onNewsletterChange(e.target.checked)}
              checked={newsletter === 'Yes' ? true : false}
              value="newsletter"
            />
            <span className="checkmark"></span>
          </label>
        </div>
        <p className="contact-container__hero__form-flex__privacy">
          {locale.privacy}
          <span className="tooltip2">
            <Info />
            <span>
              {locale.tooltipTwo}
              <Link to={`/${language}/regulations/`}>{locale.tooltipTwoLink}</Link>
            </span>
          </span>
        </p>
        <button onClick={onSubmitForm} disabled={formDisabled}>
          {RichText.asText(contact.form_submit?.raw || [])}
        </button>
      </div>
    </>
  );
};

export default ContactForm;
