import React from "react";
import "./Filters.scss";
import { useSelector } from "react-redux";
import { RootState } from "utils/store/store";
import { SearchFilter } from "features/blog/blogModel";
import Filter from "features/blog/search/filters/Filter";

const Filters = (): JSX.Element => {
  const { searchFilters } = useSelector((state: RootState) => state.blog);

  return (
    <div className="filters-container">
      {searchFilters.map((filter: SearchFilter) => (
        <Filter key={filter.id} filter={filter} />
      ))}
    </div>
  );
};
export default Filters;
