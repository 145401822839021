import * as React from 'react';
import './CompanyInNumbers.scss';
import { PrismicHomepageDataCompanyInNumbers } from 'types/declarations';
import { RichText } from 'prismic-reactjs';
import styled, { css } from 'styled-components';
import { useMediaQuery } from 'utils/hooks/useMediaQuery';
import CompanyInNumbersAnimatedNumber from './CompanyInNumbersAnimatedNumber';

interface ICompanyInNumbersProps {
  card: PrismicHomepageDataCompanyInNumbers | null;
}

const CardDiv = styled.div(() => {
  const { tablet, laptop, desktop } = useMediaQuery();

  return css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 17rem;
    margin-bottom: 3rem;
    p {
      margin: 0;
      text-align: center;
      font-size: 1rem;
      font-weight: 600;
      line-height: 2rem;
      color: #001c61;
      white-space: pre-wrap;
    }
    ${tablet &&
    css`
      grid: 1fr;
      margin: 1rem 0 2rem 0;
    `}
    ${(laptop || desktop) &&
    css`
      margin: 0;
      p {
        font-size: 1.12rem;
      }
    `}
  `;
});

const NumberDiv = styled.div(() => {
  const { tablet, laptop, desktop } = useMediaQuery();
  return css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    h1 {
      margin: 0;
      text-align: center;
      font-size: 2.5rem;
      font-weight: 700;
      line-height: 3.25rem;
      color: #001c61;
    }
    ${tablet &&
    css`
      h1 {
        font-size: 3.25rem;
      }
    `}
    ${(laptop || desktop) &&
    css`
      margin-bottom: 1.5rem;
      h1 {
        font-size: 4rem;
      }
    `}
  `;
});

function CompanyInNumbersCard({ card }: ICompanyInNumbersProps) {
  const cardNumber = RichText.asText(card?.company_in_numbers_card_number?.raw);
  const splitCardNumber = cardNumber?.split(/(\d+)/).filter(el => el != '');
  const number = Number(splitCardNumber[0]);
  const sign = splitCardNumber[1] || '';
  const animationMargin = '25';

  return (
    <CardDiv>
      <NumberDiv>
        <CompanyInNumbersAnimatedNumber
          animationMargin={animationMargin}
          number={number}
        />
        <h1>{sign}</h1>
      </NumberDiv>
      <p>{RichText.asText(card?.company_in_numbers_card_text?.raw)}</p>
    </CardDiv>
  );
}

export default CompanyInNumbersCard;
