import { Link } from "gatsby";
import { getLinkFromTitle } from "features/product-page/productUtils";
import * as React from "react";
import { FC } from "react";
import { useLocation } from "@reach/router";
import "../product-benefits/product-benefits-showcase/ProductBenefitsShowcase.scss";
import { useSelector } from "react-redux";
import { RootState } from "utils/store/store";

export interface ProductSecondNavbarProps {
  imageUrl: string;
  handleHubspot?: () => void;
}

const ProductSecondNavbar: FC<ProductSecondNavbarProps> = (
  data: ProductSecondNavbarProps
) => {
  const location = useLocation();
  const isComingSoon = !location.pathname.includes("placeme");
  // const locale = useLocale("ProductSecondNavbar");
  const { language } = useSelector((state: RootState) => state.i18n);

  return (
    <div
      style={{ backgroundColor: "#FBF7F4" }}
      className="product-benefits-showcase__nav">
      <Link to={`/${language}/${getLinkFromTitle(location.pathname)}/`}>
        <img
          style={{ width: "200px" }}
          src={data.imageUrl}
          alt="product logo"
        />
      </Link>
      <nav>
        <ul>
          {!isComingSoon && (
            <>
              {/* <li>
                <Link
                  to={`/${language}/${getLinkFromTitle(
                    location.pathname
                  )}-functionalities/`}>
                  {locale?.functionalities}
                </Link>
              </li>
              <li>
                <Link to={`/${language}/${getLinkFromTitle(location.pathname)}-benefits/`}>
                  {locale?.benefits}
                </Link>
              </li>
              <li>
                <Link
                  to={`/${language}/${getLinkFromTitle(location.pathname)}-pricelist/`}>
                  {locale?.pricelist}
                </Link>
              </li> */}
            </>
          )}
        </ul>
      </nav>
    </div>
  );
};
export default ProductSecondNavbar;
