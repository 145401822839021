import { Maybe } from 'graphql/jsutils/Maybe';
import { RichText, RichTextBlock } from 'prismic-reactjs';
import React, { useState } from 'react';
import { PrismicProductPricelistDataFaq } from 'types/declarations';
import Opened from 'assets/faq-open.svg'
import Closed from 'assets/faq-closed.svg'
interface IFAQSection {
  title: RichTextBlock[] | undefined;
  faq: Maybe<Maybe<PrismicProductPricelistDataFaq>[]> | undefined;
}

export const FAQSection = ({title, faq}: IFAQSection): JSX.Element => {
  const firstColumn = faq?.slice(0, (Math.ceil((faq?.length ?? 0) / 2)));
  const secondColumn = faq?.slice(firstColumn?.length, faq?.length);

  const [opened, setOpened] = useState<string | undefined>();

  return (
    <div className="pricelist-container__faq">
      <div className="pricelist-container__faq--inner">
        <RichText render={title} />
        <div className="pricelist-container__faq--inner___columns">
          <div className="first-column">
            {firstColumn?.map((f, i) => (
              <div className="faq-element" key={i}>
                <div className="question">
                  <span className="arrow">
                    {opened === 'f' + i ? <Opened onClick={() => setOpened(undefined)} /> : <Closed onClick={() => setOpened('f' + i)} />}
                  </span>
                  <RichText render={f?.question?.raw} />
                </div>
                {opened === 'f' + i ? (
                  <div className="answer">
                    <RichText render={f?.answer?.raw} />
                  </div>
                ) : null }
              </div>
            ))}
          </div>
          <div className="second-column">
            {secondColumn?.map((f, i) => (
              <div className="faq-element" key={i}>
                <div className="question">
                  <span className="arrow">
                    {opened === 's' + i ? <Opened onClick={() => setOpened(undefined)} /> : <Closed onClick={() => setOpened('s' + i)} />}
                  </span>
                  <RichText render={f?.question?.raw} />
                </div>
                {opened === 's' + i ? (
                  <div className="answer">
                    <RichText render={f?.answer?.raw} />
                  </div>
                ) : null }
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
