import React from 'react'
import { PrismicProductHomeDataBodyPricingFormSlice } from './PricingFormTypes'
import PricingInfoSide from './PricingInfoSide'
import PricingFormSide from './PricingFormSide'
import styled, { css } from 'styled-components'
import { useMediaQuery } from 'utils/hooks/useMediaQuery'

const Wrapper = styled.div(() => {
  const { tablet, mobile } = useMediaQuery()

  return css`
  display: grid;
  align-items: center;
  margin: 0 auto;
  padding: ${tablet ? '2rem 0' : mobile ? '1.5rem 0 ' : '0 2.6rem'};
  max-width: 1440px;
  grid-template-columns: ${mobile || tablet ? '1fr' : 'repeat(2, 1fr)'};
`
})

const PricingSlice: React.FC<{ slice: PrismicProductHomeDataBodyPricingFormSlice }> = ({ slice }) => {

  return (
    <Wrapper>
      <PricingInfoSide slice={slice} />
      <PricingFormSide slice={slice} />
    </Wrapper>
  )
}

export default PricingSlice
