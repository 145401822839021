import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  BlogBanner,
  BlogBannerDTO,
  BlogPost,
  BlogPostDetails,
  GetBlogPostDetails,
  Pagination,
  SearchFilter,
} from "features/blog/blogModel";
import BlogService from "features/blog/blogService";
import { defaultFilters } from "features/blog/blogExampleData";
import { RootState } from "utils/store/store";
import { Maybe } from "types/declarations";

export interface BlogState {
  searchQuery: string;
  searchResults: Array<BlogPost>;
  allBlogPosts: Array<BlogPost>;
  homePosts: Array<BlogPost>;
  homeBanners: Array<BlogBanner>;
  homeBannersDTO: Array<BlogBannerDTO>;
  searchFilters: Array<SearchFilter>;
  pagination: Pagination;
  blogPost?: Maybe<BlogPostDetails>;
}

const initialState = {
  searchQuery: "",
  searchResults: [],
  allBlogPosts: [],
  homePosts: [],
  homeBanners: [],
  homeBannersDTO: [],
  searchFilters: defaultFilters,
  pagination: { page: 1, pageSize: 125, numberOfItems: 0 },
} as BlogState;

export const toggleFilter = createAsyncThunk(
  "blog/toggleFilter",
  async (variables: SearchFilter, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const query = state.blog.searchQuery;
    thunkAPI.dispatch(toggleFilterState(variables));
    if (query) {
      thunkAPI.dispatch(getSearchResults(query));
    } else {
      thunkAPI.dispatch(getHomeBlogPosts());
    }
  }
);

export const getSearchResults = createAsyncThunk(
  "blog/getSearchResults",
  async (query: string, thunkAPI) => {
    thunkAPI.dispatch(setSearchQuery(query));
    const state = thunkAPI.getState() as RootState;
    const filters = state.blog.searchFilters;
    const pagination = state.blog.pagination;
    const posts = state.blog.allBlogPosts;

    return BlogService.getSearchResults({
      query,
      filters,
      pagination,
      allBlogPosts: posts,
    });
  }
);

export const getHomeBlogPosts = createAsyncThunk(
  "blog/getHomeBlogPosts",
  async (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const filters = state.blog.searchFilters;
    const pagination = state.blog.pagination;
    const posts = state.blog.allBlogPosts;
    const banners = state.blog.homeBanners;

    const results = BlogService.getHomeBlogPosts({
      filters,
      pagination,
      allBlogPosts: posts,
    });
    thunkAPI.dispatch(
      setPagination({
        ...pagination,
        numberOfItems: results.length + banners.length,
      })
    );
    return results;
  }
);

export const paginateBlog = createAsyncThunk(
  "blog/paginateBlog",
  async (pagination: Pagination, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const filters = state.blog.searchFilters;
    const query = state.blog.searchQuery;
    const posts = state.blog.allBlogPosts;
    const banners = state.blog.homeBanners;

    if (query) {
      const results = BlogService.getSearchResults({
        query,
        filters,
        pagination,
        allBlogPosts: posts,
      });
      thunkAPI.dispatch(
        setPagination({ ...pagination, numberOfItems: results.length })
      );
      thunkAPI.dispatch(setSearchResults(results));
      return results;
    } else {
      const results = BlogService.getHomeBlogPosts({
        filters,
        pagination,
        allBlogPosts: posts,
      });
      thunkAPI.dispatch(
        setPagination({
          ...pagination,
          numberOfItems: results.length + banners.length,
        })
      );
      thunkAPI.dispatch(setHomePosts(results));
      return results;
    }
  }
);

export const getHomeBanners = createAsyncThunk(
  "blog/getHomeBanners",
  async () => {
    return BlogService.getHomeBanners();
  }
);

export const getBlogPostDetails = createAsyncThunk(
  "blog/getBlogPostDetailsByLink",
  async (props: GetBlogPostDetails, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const posts = state.blog.allBlogPosts;
    return BlogService.getBlogPostDetails({
      ...props,
      allBlogPosts: posts as Array<BlogPostDetails>,
    });
  }
);

const blogSlice = createSlice({
  name: "blog",
  initialState,
  reducers: {
    toggleFilterState(state, action: PayloadAction<SearchFilter>) {
      const allIndex = state.searchFilters.findIndex((filter: SearchFilter) =>
        filter.value.toLowerCase().includes("wszystko")
      );
      const foundIndex = state.searchFilters.findIndex(
        (filter: SearchFilter) => filter.id === action.payload.id
      );

      // Return if invalid filter clicked
      if (foundIndex === -1) {
        return;
      }

      // When clicked all filter
      if (allIndex === foundIndex) {
        state.searchFilters = state.searchFilters.map(
          (filter: SearchFilter) => {
            return { ...filter, selected: false };
          }
        );
        state.searchFilters[allIndex].selected = true;
      } else {
        state.searchFilters[foundIndex].selected = !state.searchFilters[
          foundIndex
        ].selected;

        if (allIndex !== -1) {
          state.searchFilters[allIndex].selected = false;
        }
        let noneSelected = true;
        for (let i = 0; i < state.searchFilters.length; i++) {
          if (state.searchFilters[i].selected) {
            noneSelected = false;
          }
        }
        if (noneSelected) {
          state.searchFilters[allIndex].selected = true;
          return;
        }
      }
    },
    setAllBlogPosts(state, action: PayloadAction<Array<BlogPost>>) {
      state.allBlogPosts = action.payload;
    },
    setSearchQuery(state, action: PayloadAction<string>) {
      state.searchQuery = action.payload;
    },
    setFilters(state, action: PayloadAction<Array<SearchFilter>>) {
      state.searchFilters = action.payload;
    },
    setHomePosts(state, action: PayloadAction<Array<BlogPost>>) {
      state.homePosts = action.payload;
    },
    setSearchResults(state, action: PayloadAction<Array<BlogPost>>) {
      state.searchResults = action.payload;
    },
    setPagination(state, action: PayloadAction<Pagination>) {
      state.pagination = action.payload;
    },
    setHomeBannersDTO(state, action: PayloadAction<Array<BlogBannerDTO>>) {
      state.homeBannersDTO = action.payload;
    },
    clearSearch(state) {
      state.searchQuery = "";
      state.homePosts = [];
      state.searchResults = [];
    },
  },
  extraReducers: builder => {
    builder.addCase(getHomeBlogPosts.fulfilled, (state, action) => {
      state.homePosts = action.payload;
    });
    builder.addCase(getHomeBanners.fulfilled, (state, action) => {
      state.homeBanners = action.payload;
    });
    builder.addCase(getSearchResults.fulfilled, (state, action) => {
      state.searchResults = action.payload;
    });
    builder.addCase(getBlogPostDetails.fulfilled, (state, action) => {
      state.blogPost = action.payload;
    });
    builder.addCase(paginateBlog.fulfilled, () => {
      if (typeof window !== "undefined") {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }
    });
  },
});

export const {
  toggleFilterState,
  setAllBlogPosts,
  setSearchQuery,
  setFilters,
  setPagination,
  setHomePosts,
  setSearchResults,
  clearSearch,
  setHomeBannersDTO,
} = blogSlice.actions;
export default blogSlice.reducer;
