import React, { FC } from "react";
import "./Infobar.scss";
import Separator from "assets/vertical-line.svg";
import { useSelector } from "react-redux";
import { RootState } from "utils/store/store";
import { Tag } from "features/success-stories/successStoriesModel";
import Tags from "features/success-stories/items/tags/Tags";

interface InfobarProps {
  tags: Tag[] | undefined;
  client: string | undefined;
  industry: string | undefined;
}

const Infobar: FC<InfobarProps> = ({
  tags,
  client,
  industry,
}: InfobarProps) => {
  const { language } = useSelector((state: RootState) => state.i18n);

  return (
    <div className="ss-post__left--infobar">
      {tags && (
        <div className="ss-post__left--infobar--item">
          <span>{language === "pl" ? "produkt" : "product"}</span>
          <div>
            <Tags tags={tags} />
          </div>
        </div>
      )}

      {client && (
        <div className="ss-post__left--infobar--item">
          <span>{language === "pl" ? "klient" : "client"}</span>
          <p>{client}</p>
        </div>
      )}

      {industry && (
        <>
          <div className="ss-post__left--infobar--item">
            <Separator />
          </div>
          <div className="ss-post__left--infobar--item">
            <span>{language === "pl" ? "branża" : "industry"}</span>
            <p>{industry}</p>
          </div>
        </>
      )}
    </div>
  );
};
export default Infobar;
