import * as React from 'react'
import Arrow from 'assets/arrowIcon.svg'
import styled, { css } from 'styled-components'

interface StyledLinkProps {
  active?: boolean
}

const StyledLink = styled.li<StyledLinkProps>(({ active }) => css`
  cursor: pointer;
  text-decoration: none;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${active && css`
    text-decoration: underline !important;
    text-underline-offset: 0.15rem;`}
`)

const StyledArrow = styled(Arrow)<StyledLinkProps>(({ active }) => css`
  ${!active && css`transform: rotate(180deg);`}
`)

export interface NavbarItemProps {
  children: React.ReactNode
  showDropdownArrow?: boolean
  isActive?: boolean
  isOpen?: boolean
  onClick?: () => void
}

const MobileNavbarItem = ({
  children,
  showDropdownArrow,
  onClick,
  isActive,
  isOpen,
}: NavbarItemProps) => {
  return (
    <StyledLink active={isActive} onClick={onClick}>
      {children}
      {showDropdownArrow ? <StyledArrow active={isOpen} /> : null}
    </StyledLink>
  )
}

export default MobileNavbarItem
