import { RichText } from "prismic-reactjs";
import * as React from "react";
import { colors } from 'shared/constants';
import styled, { css } from "styled-components";
import { Maybe, PrismicProductHomeDataBodySuccessStoriesSliceItem } from "types/declarations";
import { useMediaQuery } from "utils/hooks/useMediaQuery";

const StatisticsElement = styled.div(() => {
  const { mobile } = useMediaQuery();

  return css`
    display: flex;
    flex-direction: column;
    color: ${colors.darkBlue};

    ${mobile && css`
      flex-direction: row;
      align-items: center;
      margin-top: 10px;
    `}
  `;
});

const Number = styled.div(() => {
  const { mobile, tablet } = useMediaQuery();

  return css`
    font-size: 64px;
    font-weight: 700;
    line-height: 72px;
    margin-bottom: 10px;

    ${tablet && css`
      font-size: 48px;
    `}

    ${mobile && css`
      font-size: 38px;
      line-height: 64px;
      width: 27%;
    `}
  `;
});

const StatDescription = styled.div(() => {
  const { mobile } = useMediaQuery();

  return css`
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 28px;

    ${mobile && css`
      margin-bottom: 0;
      width: 70%;
    `}
  `;
});

const StatsWrapper = styled.div(() => {
  const { tablet, mobile } = useMediaQuery()

  return css`
    display: flex;
    text-align: left;
    justify-content: space-between;

    ${mobile && css`flex-direction: column;`}
    ${(mobile || tablet) && css`width: 100%;`}

    ${!tablet && !mobile && css`
      flex-direction: column;
      max-width: 32%;
      margin-top: 110px;
    `}
  `
});

interface StatisticsObject {
  value: number;
  description: string;
  percent?: boolean;
}

interface StatisticsProps {
  item: Maybe<PrismicProductHomeDataBodySuccessStoriesSliceItem>;
}

const Statistics: React.FC<StatisticsProps> = ({ item }) => {
  const statisticsData: StatisticsObject[] = [
    { value: item?.number || 0, description: RichText.asText(item?.number_description?.richText || []), percent: false },
    { value: item?.percent || 0, description: RichText.asText(item?.percent_description?.richText || []), percent: true },
    { value: item?.second_number || 0, description: RichText.asText(item?.second_number_description?.richText || []), percent: false },
  ];

  return (
    <StatsWrapper>
      {statisticsData.map((stat, index) => (
        <StatisticsElement key={index}>
          <Number>{stat.value}{stat.percent ? '%' : null}</Number>
          <StatDescription>{stat.description}</StatDescription>
        </StatisticsElement>
      ))}
    </StatsWrapper>
  );
};

export default Statistics;
