import * as React from "react";
import { RichText } from "prismic-reactjs";
import {
  ContentSection,
  ContentSectionsDTO,
} from "ui/content-sections/contentSectionsModel";
import ContentSections from "ui/content-sections/ContentSections";
import { v4 as uuid } from "uuid";
import { useProductFunctionalitiesData } from "features/product-page/product-functionalities/productFunctionalitiesService";
import { ProductPageProps } from "features/product-page/product-homepage/productHomeService";
import { FC } from "react";

const ToolsSections: FC<ProductPageProps> = (props: ProductPageProps) => {
  const data = useProductFunctionalitiesData(props);

  const dto = {
    sections:
      data?.tools_sections?.map(section => {
        const descriptions = RichText.asText(section?.descriptions?.raw || []);
        return {
          header: RichText.asText(section?.header?.raw || []),
          subheader: RichText.asText(section?.subheader?.raw || []),
          descriptions: descriptions ? descriptions.split(";") : [],
          image: section?.image,
          styles: { backgroundColor: "white" },
        } as ContentSection;
      }) ?? [],
    styles: { backgroundColor: "#FBF7F4" },
    uuid: uuid(),
  } as ContentSectionsDTO;

  return <ContentSections data={dto} />;
};

export default ToolsSections;
