import { graphql, useStaticQuery } from "gatsby";
import {
  PrismicHomepage,
  PrismicHomepageDataType,
  PrismicHomepageEdge,
} from "types/declarations";
import { useSelector } from "react-redux";
import { RootState } from "utils/store/store";
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews";

export const useTechnologyData = (): PrismicHomepageDataType => {
  const { language } = useSelector((state: RootState) => state.i18n);
  const resultS = useStaticQuery(graphql`
    {
      allPrismicHomepage {
        totalCount
        edges {
          node {
            lang
            _previewable
            data {
              technology_header {
                raw
              }
              technology_subheader {
                raw
              }
              technology_points {
                technology_point_title {
                  raw
                }
                technology_point {
                  raw
                }
                technology_point_image {
                  url
                  alt
                }
                technology_point_image_mobile {
                  url
                  alt
                }
              }
            }
          }
        }
      }
    }
  `);
  const {data: result} = useMergePrismicPreviewData(resultS)
  return result.allPrismicHomepage.edges
    .map((edge: PrismicHomepageEdge) => edge.node)
    .filter((node: PrismicHomepage) => node.lang === language)
    .map((node: PrismicHomepage) => node.data)[0];
};
