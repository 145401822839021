import { RichText } from 'prismic-reactjs'
import React, { FC } from 'react'
import { Maybe, PrismicReportBodyReportBanner } from 'types/declarations'

export interface ReportBannerProps {
  content: Maybe<PrismicReportBodyReportBanner>;
}

const ReportBanner: FC<ReportBannerProps> = ({ content }: ReportBannerProps) => {
  return (
    <>
      <div>
        <img src={content?.primary?.image?.url ?? ""} alt={content?.primary?.image?.alt ?? ""} />
        <div>
          <h3>{RichText.asText(content?.primary?.title1?.raw || [])}</h3>
          <div className="report-banner__columns">
            {content?.items?.map((item, i) => <div key={i} className="report-banner__column">
              <h5>{RichText.asText(item?.column_title?.raw || [])}</h5>
              <p>{RichText.asText(item?.column_description?.raw || [])}</p>
            </div>)}
          </div>
        </div>
      </div>
    </>
  )
}

export default ReportBanner
