import { combineReducers } from "redux";
import i18nSlice from "utils/i18n/i18nSlice";
import blogSlice from "features/blog/blogSlice";
import reportsSlice from "features/reports/reportsSlice";
import notFoundSlice from "features/not-found/notFoundSlice";
import careerSlice from 'features/career/careerSlice';
import successStoriesSlice from "features/success-stories/successStoriesSlice";

export default combineReducers({
  i18n: i18nSlice,
  blog: blogSlice,
  reports: reportsSlice,
  notFound: notFoundSlice,
  recruitment: careerSlice,
  successStories: successStoriesSlice,
});
