import React, { Dispatch, SetStateAction, useCallback } from 'react'
import { colors, productColors } from 'shared/constants'
import styled, { css } from 'styled-components'
import { Maybe, PrismicProductHomeDataBodyReviewsSliceItem } from 'types/declarations'
import ChevronIcon from 'assets/arrowIcon.svg'
import { useMediaQuery } from 'utils/hooks/useMediaQuery'

const StyledNavigationDiv = styled.div(() => {
  const { desktop, laptop } = useMediaQuery()
  
  return css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: ${(desktop || laptop) ? 'flex-start' : 'space-between'};
    gap: 1rem;
    width: 100%;
  `
})

const StyledImageCirclesDiv = styled.div`
  display: flex;
`

const StyledImageCircle = styled.img<{ $active: boolean }>(({ $active = false }) => css`
  max-width: 2.5rem;
  max-height: 2.5rem;
  border-radius: 2.5rem;
  cursor: pointer;
  margin-right: 0;
  margin-left: -10px;
  border: ${`2px solid ${$active ? productColors.violet.dark : colors.white}`};
  ${$active && css`z-index: 10;`}
`)

const StyledChevronDiv = styled.div`
  display: flex;
  gap: 1rem;
`

const StyledChevronRightIcon = styled(ChevronIcon)`
  transform: rotate(90deg);
  cursor: pointer;
  padding: 0.5rem;
  width: 2rem;
  height: 2rem;

  path {
    stroke: ${productColors.violet.dark};
  }
`

const StyledChevronLeftIcon = styled(StyledChevronRightIcon)`
  transform: rotate(-90deg);
`

interface NavigationProps {
  items?: Maybe<Maybe<PrismicProductHomeDataBodyReviewsSliceItem>[]>
  selectedReview?: Maybe<PrismicProductHomeDataBodyReviewsSliceItem>
  setSelectedReview: Dispatch<SetStateAction<Maybe<PrismicProductHomeDataBodyReviewsSliceItem> | undefined>>
}

function Navigation({
  items,
  selectedReview,
  setSelectedReview,
}: NavigationProps) {
  const { desktop, laptop } = useMediaQuery()
  const isActive = useCallback((item: Maybe<PrismicProductHomeDataBodyReviewsSliceItem>) => item === selectedReview, [selectedReview])

  const getPreviousReview = useCallback(() => {
    if (!items) return

    items.map((item, index) => {
      if (item === selectedReview) {
        if (index === 0) {
          return setSelectedReview(items[items.length - 1])
        }
        setSelectedReview(items[index - 1])
      }
    })
  }, [selectedReview])

  const getNextReview = useCallback(() => {
    if (!items) return

    items.map((item, index) => {
      if (item === selectedReview) {
        if (index === items.length - 1) {
          return setSelectedReview(items[0])
        }
        setSelectedReview(items[index + 1])
      }
    })
  }, [selectedReview])

  return (
    <StyledNavigationDiv>
      {(desktop || laptop)
        ? (
          <>
            <StyledImageCirclesDiv>
            {items?.map((item, index) => (
                <StyledImageCircle
                  key={index}
                  $active={isActive(item)}
                  src={item?.employee_portrait_img?.url!}
                  onClick={() => setSelectedReview(item)}
                />
              )
            )}
            </StyledImageCirclesDiv>
            <StyledChevronDiv>
              <StyledChevronLeftIcon onClick={getPreviousReview}/>
              <StyledChevronRightIcon onClick={getNextReview}/>
            </StyledChevronDiv>
          </>
        )
        : (
          <>
            <StyledChevronDiv>
              <StyledChevronLeftIcon onClick={getPreviousReview}/>
              <StyledChevronRightIcon onClick={getNextReview}/>
            </StyledChevronDiv>
            <StyledImageCirclesDiv>
            {items?.map((item, index) => (
                <StyledImageCircle
                  key={index}
                  $active={isActive(item)}
                  src={item?.employee_portrait_img?.url!}
                  onClick={() => setSelectedReview(item)}
                />
              )
            )}
            </StyledImageCirclesDiv>
          </>
        )
      }
    </StyledNavigationDiv>
  )
}

export default Navigation
