import React, { useState } from 'react'
import { PrismicProductHomeDataBodyFaqSlice } from './FaqTypes'
import styled, { css } from 'styled-components'
import { colors } from 'shared/constants'
import { RichText } from 'prismic-reactjs'
import { useMediaQuery } from 'utils/hooks/useMediaQuery'
import Dropdown from 'components/shared-components/dropdown/Dropdown'

const Section = styled.section(() => css`
  background-color: ${colors.background};
`)

const Wrapper = styled.section(() => {
  const { tablet, mobile } = useMediaQuery();
  
  return css`
    margin: 0 auto;
    max-width: 1440px;
    padding: ${tablet ? '2rem 1.5rem' : mobile ? '1.5rem 1rem' : '4rem 5.25rem'};
  `
})

const Title = styled.h1(() => css`
  color: ${colors.main};
  font-size: 34px;
  line-height: 45px;
  margin-bottom: 3.5rem;
`)

const ColumnsWrapper = styled.div(() => {
  const { tablet, mobile } = useMediaQuery();

  return css`
    display: ${tablet || mobile ? 'block' : 'flex'};
    gap: 1.5rem;
  `
})

const Column = styled.div(() => css`
  flex: 1;
  display: flex;
  flex-direction: column;
`)

export const FaqSlice: React.FC<{ slice: PrismicProductHomeDataBodyFaqSlice }> = ({ slice }) => {
  const [selectedItem, setSelectedItem] = useState<number | null>(null)
  const handleClickDropdown = (index: number) => setSelectedItem(index === selectedItem ? null : index)

  const leftColumnItems = slice?.items?.filter((_, i) => i % 2 === 0) || []
  const rightColumnItems = slice?.items?.filter((_, i) => i % 2 !== 0) || []

  return (
    <Section>
      <Wrapper>
      <Title>{RichText.asText(slice?.primary?.title?.richText)}</Title>
        <ColumnsWrapper>
          <Column>
            {leftColumnItems.map((item, index) => (
              <Dropdown selectedItem={selectedItem} handleClickDropdown={handleClickDropdown} index={index} item={item} />
            ))}
          </Column>
          <Column>
            {rightColumnItems.map((item, index) => (
              <Dropdown selectedItem={selectedItem} handleClickDropdown={handleClickDropdown} index={index} item={item} twoColumnsVersion />
            ))}
          </Column>
        </ColumnsWrapper>
      </Wrapper>
    </Section>
  )
}

export default FaqSlice
