import React, { useState, useCallback, useMemo } from 'react'
import { postSubscriptionFormBlogPost } from '../../../blogService'
import { useSelector } from "react-redux";
import { RootState } from "utils/store/store";
import { Link } from 'gatsby'
import Close from '../../../../../../assets/close.svg'
import MessageIcon from '../../../../../../assets/message.svg'
import ErrorIcon from '../../../../../../assets/error.svg'
import { useLocale } from "locale/useLocale";
import Arrow from "assets/arrowIcon.svg";
import BtnArrowRight from "assets/arrowbtn.svg";

const NewsletterBanner = (): JSX.Element => {
  const [mobileForm, setMobileForm] = useState(false);
  const [email, setEmail] = useState("");
  const { language } = useSelector((state: RootState) => state.i18n);
  const [infoModalDisplay, setModalDisplay] = useState(false);
  const [infoModalText, setInfoModalText] = useState('');
  const locale = useLocale("NewsletterBanner");

  const formDisabled = useMemo(() => {
    return !email;
  }, [email]);

  const onEmailChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => { setEmail(e.target.value); }, []
  );

  const onSubmit = useCallback(() => {
    if (!formDisabled) {
      postSubscriptionFormBlogPost({
        email,
      }, language, setInfoModalText, setModalDisplay)
    }
  }, [email]);

  return (
    <>
      {infoModalDisplay &&
        <div className='info-modal-newsletter' >
          <span onClick={() => {
            setModalDisplay(false)
          }}>
            <Close width='20' height='20' className='close' />
          </span>
          <div className={infoModalText.includes('nieprawidłowy' || 'invalid' || 'pola') ? 'newsletter-banner-invalid' : 'newsletter-banner-correct'}>
            <p>{infoModalText}</p>
            {infoModalText.includes('nieprawidłowy' || 'invalid' || 'pola') ? <ErrorIcon width='261' height='206' className='image-info-modal' /> : <MessageIcon width='361' height='286' className='image-info-modal' />
            }
          </div>
        </div>}
      <div className={`post-body__sidebar--newsletter ${mobileForm ? 'open' : ''}`} >
      <div onClick={() => setMobileForm(!mobileForm)} className="titlebar">
          <div className='title'>
            <img src="https://carcavelos.cdn.prismic.io/carcavelos/399ac58c-98d5-435c-bc86-5455e51d369b_Group+7019.svg" alt="icon" />
            <h5>{locale?.title}</h5>
          </div>
          <div className="close-btn" onClick={() => setMobileForm(!mobileForm)}><Arrow /></div>
        </div>
        <p className='description'>{locale?.description}</p>
        <input
          type="email"
          value={email}
          onChange={onEmailChange}
          placeholder={locale?.placeholder}
          className="newsletter-banner-blog__email"
          style={{ border: email.length > 0 ? "2px solid #001D62" : "" }}
        />
        <button onClick={onSubmit} className='button'>
          {locale?.button}
          <BtnArrowRight />
        </button>
        {language === 'pl' ?
          <p className='post-policy'>Sprawdź naszą <Link className='policy-link' to={`/${language}/privacy-policy/`}>Politykę prywatności</Link> i dowiedz się, w jaki sposób przetwarzamy
            dane. W każdej chwili możesz przerwać subskrypcję newslettera bez ponoszenia kosztów.</p>
          :
          <p className='post-policy'>To learn how we process your data, visit our
            <Link to={`/${language}/privacy-policy/`} className='policy-link'> Privacy Policy</Link>.
            You can unsubscribe from the newsletter at any time without costs.</p>
        }
      </div>
    </>
  )
}

export default NewsletterBanner