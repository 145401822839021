import ProductLayout from "containers/productLayout/ProductLayout";
import Seo from "utils/seo/Seo";
import ProductBenefitsShowcase from "features/product-page/product-benefits/product-benefits-showcase/ProductBenefitsShowcase";
import ProductBenefitsIntroduction from "features/product-page/product-benefits/product-benefits-introduction/product-benefits-introduction/ProductBenefitsIntroduction";
import * as React from "react";
import { ProductPageProps, useProductHomeData } from "features/product-page/product-homepage/productHomeService";
import { FC, useState } from "react";
import { PageProps } from "gatsby";
import HubspotMeetingModal from "ui/HubspotMeetingModal/HubspotMeetingModal";
import { navigate } from 'gatsby';
import {useMediaQuery} from "utils/hooks/useMediaQuery";
import { useProductBenefitsData } from "./productBenefitsService";
import CaseStudies from "features/case-studies/CaseStudies";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "utils/store/store";
import { Language, setLanguage } from "utils/i18n/i18nSlice";
import { checkForPopup } from "components/shared-components/popups/generic-popup/checkForPopupService";
import { GenericPopup } from "components/shared-components/popups/generic-popup/GenericPopup";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
const linkResolver = require('../../../../../linkResolver.js');

function ProductBenefits (props: PageProps) {
  const name = (props.pageContext as ProductPageProps).productName;
  const data = useProductBenefitsData({ productName: (props.pageContext as ProductPageProps).productName });
  const productData = useProductHomeData(props.pageContext as ProductPageProps);
  const { language } = useSelector((state: RootState) => state.i18n);
  const dispatch = useDispatch();
  const displayPopup  = checkForPopup()?.page?.text?.split(';')?.filter(p => p !== '')?.includes('product_benefits');

  const [showMeeting, setShowMeeting] = useState(false);

  const {mobile} = useMediaQuery()

  const handleShowMeeting = () => {
    if (!showMeeting && !mobile) {
      setShowMeeting(true);
    } else {
      navigate(`/${language}/contact/`)
    }
  };
  const handleCloseMeeting = () => {
    if (showMeeting) {
      setShowMeeting(false);
    }
  };
  
  React.useEffect(() => {
    if (typeof window !== `undefined`) {
      const url = location?.pathname?.split('/').filter(item => item !== '')
  
      const findLangInUrl = (): number => url.findIndex(item => item === 'en-pl' || 'pl' || 'fr-fr' || 'de-de');
      const urlLanguage = url[findLangInUrl()] as Language
      dispatch(setLanguage(urlLanguage));
    }
  }, [])

  return (
    <ProductLayout handleHubspot={handleShowMeeting} productName={name}>
      <Seo title={productData?.seo_title?.text} description={productData?.seo_description?.text} />

      { displayPopup && <GenericPopup /> }
      
      {showMeeting && (
        <HubspotMeetingModal
          closeMeeting={handleCloseMeeting}
          left="25vw"
          show={showMeeting}
          top="8vh"
        />
      )}
      <ProductBenefitsShowcase productName={name} />
      <ProductBenefitsIntroduction handleHubspot={handleShowMeeting} productName={name} />
      {language === 'pl' && (
        <CaseStudies data={data} />
      )}
    </ProductLayout>
  );
};

export default withPrismicPreview(ProductBenefits, [
  {
    repositoryName: 'carcavelos',
    linkResolver: linkResolver.linkResolver,
  },
]);
