import * as React from "react";
import "./ProductShowcase.scss";
import { RichText } from "prismic-reactjs";
import Button from "ui/button/Button";
import {
  ProductPageProps,
  useProductHomeData,
} from "features/product-page/product-homepage/productHomeService";
import { FC, useCallback } from "react";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";
import { useMediaQuery } from "utils/hooks/useMediaQuery";

const ProductShowcase: FC<ProductPageProps> = (props: ProductPageProps) => {
  const data = useProductHomeData(props);
  const location = useLocation();
  const heroTags = RichText.asText(data?.hero_tags?.raw || [])?.split(";");
  const isComingSoon = !location.pathname.includes("placeme");
  const { mobile, tablet } = useMediaQuery();

  const onSignUpClick = useCallback(() => {
    navigate("https://app.dataplace.ai/auth/sign-in");
  }, []);

  const getColorFromLink = useCallback(() => {
    if (location.pathname?.includes("placeme")) {
      return "#362FB7";
    } else if (location.pathname.includes("target")) {
      return "#CC3C00";
    } else {
      return "#6830B2";
    }
  }, [location]);

  const Explainer = () => data?.explainer_video_url?.url
    ? <video
      className='product-showcase-video'
      width={mobile || tablet ? '468px' : '568px'}
      height='auto'
      autoPlay
      loop
      muted
      playsInline>
      <source
        src={data?.explainer_video_url?.url ?? ''}
        type='video/mp4'
      />
    </video>
    : <img
      className="product-showcase-img"
      src={data?.hero_image?.url ?? ""}
      alt="Showcase Product Location"
    />

  return (
    <section className="product-showcase">
      <div className="product-showcase__inner">
        <div className="product-showcase__inner--left">
          <div>
            <h1>{RichText.asText(data?.hero_header?.raw || [])}</h1>
            <ul className="product-showcase__inner--leftLabels">
              {heroTags?.map((tag, index) => (
                <li key={index} style={{ backgroundColor: getColorFromLink() }}>
                  {tag}
                </li>
              ))}
            </ul>
            <p className='explainer_description'>{RichText.asText(data?.hero_description?.raw || [])}</p>
            {isComingSoon && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Button
                  as="button"
                  handleButtonClick={props.handleHubspot}
                  minWidth={120}
                  margin="0 10px 0 0"
                  filled>
                  {RichText.asText(data?.hero_button?.raw || [])}
                </Button>
                {!isComingSoon && (
                  <div className="hero-button active" onClick={onSignUpClick}>
                    {RichText.asText(data?.hero_button?.raw || [])}
                  </div>
                )}
              </div>
            )}
            {!isComingSoon && (
              <div className="hero-button" onClick={onSignUpClick}>
                {RichText.asText(data?.hero_button?.raw || [])}
              </div>
            )}
          </div>
        </div>
        <div className="product-showcase__inner--right">
          <Explainer />
        </div>
      </div>
      <div className="product-showcase__inner-tablet">
        <div className="product-showcase__inner-tablet--top">
          <div>
            <h2>{RichText.asText(data?.hero_header?.raw || [])}</h2>
            <ul className="product-showcase__inner--leftLabels">
              {heroTags?.map((tag, index) => (
                <li key={index} style={{ backgroundColor: getColorFromLink() }}>
                  {tag}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="product-showcase__inner-tablet--bottom">
          <div>
            <p className='explainer_description'>{RichText.asText(data?.hero_description?.raw || [])}</p>
            <Button
              to="https://app.dataplace.ai/auth/sign-in"
              minWidth={210}
              filled>
              {RichText.asText(data?.hero_button?.raw || [])}
            </Button>
          </div>
          <Explainer />
        </div>
      </div>
    </section>
  );
};

export default ProductShowcase;
