import * as React from "react";
import "./ProductIntroduction.scss";
import { RichText } from "prismic-reactjs";
import {
  ProductPageProps,
  useProductHomeData,
} from "features/product-page/product-homepage/productHomeService";
import { FC } from "react";

const ProductIntroduction: FC<ProductPageProps> = (props: ProductPageProps) => {
  const data = useProductHomeData(props);
  return (
    <section className="product-introduction">
      <div className="product-introduction__inner">
        <span className="product-introduction__inner--label">
          {RichText.asText(data?.first_section_text?.raw || [])}
        </span>
          <ul className="product-introduction__inner--text">
          {data?.first_section_description?.raw.map( (item : {text: string}, index: number)=> 
          <li key={index}>{item.text}</li>
          )}
          </ul>
        <h3>{RichText.asText(data?.first_section_anwser?.raw || [])}</h3>
      </div>
    </section>
  );
};

export default ProductIntroduction;
