import { graphql, useStaticQuery } from "gatsby";
import {
  PrismicRecruitment,
  PrismicRecruitmentDataType,
  PrismicRecruitmentEdge,
} from "types/declarations";
import { useSelector } from "react-redux";
import { RootState } from "utils/store/store";
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews";

export const useShowcaseData = (): PrismicRecruitmentDataType => {
  const { language } = useSelector((state: RootState) => state.i18n);
  const resultS = useStaticQuery(graphql`
    {
      allPrismicRecruitment {
        totalCount
        edges {
          node {
            lang
            _previewable
            data {
              header_button {
                raw
              }
              header_image {
                url
                alt
              }
              header_subtitle {
                raw
              }
              header_title {
                raw
              }
            }
          }
        }
      }
    }
  `);
  const {data: result} = useMergePrismicPreviewData(resultS)
  return result.allPrismicRecruitment.edges
    .map((edge: PrismicRecruitmentEdge) => edge?.node)
    .filter((node: PrismicRecruitment) => node?.lang === language)
    .map((node: PrismicRecruitment) => node?.data)
    .find(
      (data: PrismicRecruitmentDataType) =>
        data?.header_title?.raw &&
        data?.header_subtitle?.raw &&
        data?.header_button?.raw
    );
};
