import { graphql, useStaticQuery } from "gatsby";
import { useSelector } from "react-redux";
import {
  PrismicProductFunctionality,
  PrismicProductFunctionalityDataType,
  PrismicProductFunctionalityEdge,
  PrismicProductHome,
} from "types/declarations";
import { RootState } from "utils/store/store";
import { ProductPageProps } from "features/product-page/product-homepage/productHomeService";
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews";

export const useProductFunctionalitiesData = ({
  productName,
}: ProductPageProps): PrismicProductFunctionalityDataType => {
  const { language } = useSelector((state: RootState) => state.i18n);
  const resultS = useStaticQuery(graphql`
    {
      allPrismicProductFunctionality {
        edges {
          node {
            lang
            tags
            _previewable
            data {
              case_studies {
                case_study_tag {
                  raw
                }
                case_study_button {
                  raw
                }
                case_study_description {
                  raw
                }
                case_study_header {
                  raw
                }
                case_study_image {
                  url
                }
                case_study_link {
                  raw
                }
              }
              case_studies_text {
                raw
              }
              case_studies_description {
                raw
              }
              hero_header {
                raw
              }
              hero_image {
                url
                alt
              }
              strengths_header {
                raw
              }
              strengths_subheader {
                raw
              }
              tools_section_header {
                raw
              }
              tools_section_subheader {
                raw
              }
              data_section_header {
                raw
              }
              data_section_subheader {
                raw
              }
              tools_sections {
                header {
                  raw
                }
                descriptions {
                  raw
                }
                image {
                  url
                  alt
                }
                subheader {
                  raw
                }
              }
              strengths_sections {
                header {
                  raw
                }
                descriptions {
                  raw
                }
                image {
                  url
                  alt
                }
                subheader {
                  raw
                }
              }
              data_sections {
                header {
                  raw
                }
                descriptions {
                  raw
                }
                image {
                  url
                  alt
                }
                subheader {
                  raw
                }
              }
            }
          }
        }
      }
    }
  `);
  const {data: result} = useMergePrismicPreviewData(resultS)
  return result.allPrismicProductFunctionality.edges
    .map((edge: PrismicProductFunctionalityEdge) => edge.node)
    .filter((node: PrismicProductFunctionality) => node.lang === language)
    .filter((node: PrismicProductHome) => node.tags.includes(productName))
    .map((node: PrismicProductFunctionality) => node.data)[0];
};
