import "./Tags.scss";
import React, { FC, useCallback } from "react";
import { Tag } from "features/success-stories/successStoriesModel";
import styled, { css } from "styled-components";
import Placeme from "assets/placemeWhite.svg";
import Target from "assets/target-white.svg";
import Source from "assets/source-white.svg";

const Span = styled.span(({ productColor }: { productColor: string }) => {
  return css`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    background: ${productColor};
    font-weight: 600;
    border-radius: 8px;
    padding: 7px 15px;
    svg {
      max-width: 120px;
    }
  `;
});

export interface TagsProps {
  tags: Tag[] | undefined;
}

const Tags: FC<TagsProps> = ({ tags }: TagsProps) => {
  const getTags = useCallback((tag: Tag) => {
    const title = tag?.value;
    if (title?.includes("placeme")) {
      return (
        <div className="success-story-post__tags--tag">
          <Span productColor="#362FB7">
            <Placeme />
          </Span>
        </div>
      );
    } else if (title?.includes("target")) {
      return (
        <div className="success-story-post__tags--tag">
          <Span productColor="#CC3C00">
            <Target />
          </Span>
        </div>
      );
    } else if (title?.includes("source")) {
      return (
        <div className="success-story-post__tags--tag">
          <Span productColor="#6830B2">
            <Source />
          </Span>
        </div>
      );
    } else {
      return (
        <div className="success-story-post__tags--tag">
          <Span productColor="#001d62">{title}</Span>
        </div>
      );
    }
  }, [tags]);

  return (
    <div className="success-story-post__tags">
      {tags?.map((tag, index) => <React.Fragment key={index}>{getTags(tag)}</React.Fragment>)}
    </div>
  );
};
export default Tags;
