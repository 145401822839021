import * as React from "react";
import ProductLayout from "containers/productLayout/ProductLayout";
import Seo from "utils/seo/Seo";
import ProductFuncShowcase from "features/product-page/product-functionalities/product-func-showcase/ProductFuncShowcase";
import ProductFuncInformation from "features/product-page/product-functionalities/product-func-information/ProductFuncInformation";
import DataSections from "features/product-page/product-functionalities/sections/DataSections";
import ToolsSections from "features/product-page/product-functionalities/sections/ToolsSections";
import StrengthsSections from "features/product-page/product-functionalities/sections/StrengthsSections";
import ProductCta from "features/product-page/product-homepage/product-cta/ProductCta";
import { useProductFunctionalitiesData } from "features/product-page/product-functionalities/productFunctionalitiesService";
import { RichText } from "prismic-reactjs";
import { ProductPageProps, useProductHomeData } from "features/product-page/product-homepage/productHomeService";
import { FC, useEffect, useState } from "react";
import { PageProps } from "gatsby";
import HubspotMeetingModal from "ui/HubspotMeetingModal/HubspotMeetingModal";
import { navigate } from 'gatsby';
import {useMediaQuery} from "utils/hooks/useMediaQuery";
import CaseStudies from "features/case-studies/CaseStudies";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "utils/store/store";
import { Language, setLanguage } from "utils/i18n/i18nSlice";
import { checkForPopup } from "components/shared-components/popups/generic-popup/checkForPopupService";
import { GenericPopup } from "components/shared-components/popups/generic-popup/GenericPopup";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
const linkResolver = require('../../../../../linkResolver.js');

function ProductFunctionalities ( props:PageProps) {
  const name = (props.pageContext as ProductPageProps).productName;
  const data = useProductFunctionalitiesData({ productName: name });
  const productData = useProductHomeData(props.pageContext as ProductPageProps);
  const { language } = useSelector((state: RootState) => state.i18n);
  const dispatch = useDispatch();
  const displayPopup  = checkForPopup()?.page?.text?.split(';')?.filter(p => p !== '')?.includes('product_functionalities');

  const [showMeeting, setShowMeeting] = useState(false);

  const {mobile} = useMediaQuery()

  const handleShowMeeting = () => {
    if (!showMeeting && !mobile) {
      setShowMeeting(true);
    } else {
      navigate(`/${language}/contact/`)
    }
  };
  const handleCloseMeeting = () => {
    if (showMeeting) {
      setShowMeeting(false);
    }
  };

  useEffect(() => {
    if (typeof window !== `undefined`) {
      const url = location?.pathname?.split('/').filter(item => item !== '')
  
      const findLangInUrl = (): number => url.findIndex(item => item === 'en-pl' || 'pl' || 'fr-fr' || 'de-de');
      const urlLanguage = url[findLangInUrl()] as Language
      dispatch(setLanguage(urlLanguage));
    }
  }, [])
  
  return (
    <ProductLayout handleHubspot={handleShowMeeting} productName={name}>
      <Seo title={productData?.seo_title?.text} description={productData?.seo_description?.text} />
      
      { displayPopup && <GenericPopup /> }

      {showMeeting && (
        <HubspotMeetingModal
          closeMeeting={handleCloseMeeting}
          left="25vw"
          show={showMeeting}
          top="8vh"
        />
      )}
      <ProductFuncShowcase productName={name} />
      <ProductFuncInformation
        bgColor="#FFFFFF"
        label={RichText.asText(data?.data_section_subheader?.raw || [])}
        text={RichText.asText(data?.data_section_header?.raw || [])}
      />
      <DataSections productName={name} />
      <ProductFuncInformation
        bgColor="#FBF7F4"
        label={RichText.asText(data?.tools_section_subheader?.raw || [])}
        text={RichText.asText(data?.tools_section_header?.raw || [])}
      />
      <ToolsSections productName={name} />
      <ProductFuncInformation
        bgColor="#FFFFFF"
        label={RichText.asText(data?.strengths_subheader?.raw || [])}
        text={RichText.asText(data?.strengths_header?.raw || [])}
      />
      <StrengthsSections productName={name} />
      <ProductCta handleHubspot={handleShowMeeting} productName={name} />
      {language === 'pl' && (
        <CaseStudies data={data} />
      )}
    </ProductLayout>
  );
};

export default withPrismicPreview(ProductFunctionalities, [
  {
    repositoryName: 'carcavelos',
    linkResolver: linkResolver.linkResolver,
  },
]);
