import * as React from 'react';
import styled, { css } from 'styled-components';
import { useMediaQuery } from 'utils/hooks/useMediaQuery';
import { PrismicHomepageDataCtaSectionCards } from 'types/declarations';
import { RichText } from 'prismic-reactjs';

const CardDiv = styled.div(() => {
  const { mobile, tablet } = useMediaQuery();
  return css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    max-width: 40rem;
    height: 100%;
    white-space: pre-wrap;
    width: 33.333%;
    ${tablet &&
    css`
      flex-direction: row;
      justify-content: space-evenly;
      max-width: 100%;
      width: 100%;
    `}
    ${mobile &&
    css`
      border-top: 1px solid white;
      margin-top: 2rem;
      width: 80%;
    `}
  `;
});

const ImageAndTitleDiv = styled.div(() => {
  const { tablet, laptop, desktop } = useMediaQuery();

  return css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h3 {
      color: white;
      font-size: 2.2rem;
      font-weight: 600;
      margin: 1rem 0;
    }
    img {
      width: 115px;
      height: 121px;
      margin: 0;
    }
    ${(laptop || desktop) &&
    css`
      h3 {
        margin-top: 1rem;
      }
    `}
    ${tablet &&
    css`
      width: 16rem;
      h3 {
        font-size: 1.5rem;
      }
    `}
  `;
});
const DescriptionAndButtonDiv = styled.div(() => {
  const { tablet, laptop, desktop } = useMediaQuery();
  return css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 27rem;
    align-items: center;
    p {
      text-align: center;
      font-size: 1.125rem;
      font-weight: 400;
      color: white;
      margin: 1rem 0 2rem 0;
    }
    ${(laptop || desktop) &&
    css`
      p {
        margin: 2rem 0 3rem 0;
      }
    `}
    ${tablet &&
    css`
      max-width: 30rem;
      p {
        font-size: 1.25rem;
      }
    `}
  `;
});

const SummaryDiv = styled.div(() => {
  return css`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid white;
    border-radius: 5px;
    width: 18rem;
    height: 4.6rem;
    padding: 1rem;
    p {
      font-size: 0.875rem;
      text-align: center;
      font-weight: 700;
      color: white;
      margin: 0;
    }
  `;
});

const DescriptionStyled = styled.p`
  width: 24rem;
`;

interface ICtaCard {
  card: PrismicHomepageDataCtaSectionCards | null;
}

function CtaCard({ card }: ICtaCard) {
  return (
    <CardDiv>
      <ImageAndTitleDiv>
        <img
          alt={card?.cta_section_card_image?.alt || undefined}
          src={card?.cta_section_card_image?.url || undefined}
        />
        <h3>{RichText.asText(card?.cta_section_card_title?.raw)}</h3>
      </ImageAndTitleDiv>
      <DescriptionAndButtonDiv>
        <DescriptionStyled>
          {RichText.asText(card?.cta_section_card_description?.raw)}
        </DescriptionStyled>
        <SummaryDiv>
          <p>{RichText.asText(card?.cta_section_card_summary?.raw)}</p>
        </SummaryDiv>
      </DescriptionAndButtonDiv>
    </CardDiv>
  );
}

export default CtaCard;
