import { RichText } from 'prismic-reactjs';
import React from 'react';
import "./CvBanner.scss";
import { useCvBannerData } from './cvService';

const CvBanner = (): JSX.Element => {
  const cv = useCvBannerData();

  return (
    <section className="cv">
    <div className="cv__inner">
      <div className="cv__inner--left">
        <div className="cv__inner--left--title">
          <h2>{RichText.asText(cv?.cv_title?.raw || [])}</h2>
        </div>
        <div className="cv__inner--left--subtitle">
        <p>{RichText.asText(cv?.cv_subtitle?.raw || [])}</p>
        </div>
        <div className="cv__inner--left--description">
          <p>{RichText.asText(cv?.cv_description?.raw || [])}</p>
        </div>
      </div>
      <div className="cv__inner--right">
        <img src={cv?.cv_image?.url ?? ""} alt={cv?.cv_image?.alt ?? ""} />
      </div>
    </div>
  </section>
  )
}
export default CvBanner;
