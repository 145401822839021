import React, { useCallback } from "react";
import "./LanguageSwitcher.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "utils/store/store";
import { Language, setLanguage } from "utils/i18n/i18nSlice";
import PL from "assets/flags/pl.svg";
import EN from "assets/flags/gb.svg";
import { navigate } from "gatsby";

const LanguageSwitcher = (): JSX.Element => {
  const dispatch = useDispatch();
  const { language } = useSelector((state: RootState) => state.i18n);
  const url = location?.pathname?.split('/').filter(item => item !== '');

  const findLangInUrl = (): number => url.findIndex(item => item === 'en-pl' || 'pl');

  const setLangBasedOnUrl = (lang: string) => {
    url.splice(findLangInUrl(), 1, lang);
    navigate(`/${url.join('/')}`);
  }

  const onLanguageClick = useCallback((language: Language) => {
    dispatch(setLanguage(language));
    setLangBasedOnUrl(language);
  }, []);

  const checkIfRestrictedPage = (language: string) => {
    if (language === "en") {
      return (
        location?.pathname?.includes("career") ||
        location?.pathname?.includes("report")
      );
    } else return null;
  };

  return (
    <div className="switcher-container">
      {checkIfRestrictedPage("pl") ? null : (
        <>
          <div
            onClick={() => onLanguageClick("pl")}
            className={`switcher-language
          ${language === "pl" ? "switcher-language__selected" : ""}`}>
            <span className="lang-icon">
              <PL /> PL
            </span>
          </div>
        </>
      )}
      {checkIfRestrictedPage("en") ? null : (
        <>
          <div
            onClick={() => onLanguageClick("en-pl")}
            className={`switcher-language
          ${language === "en-pl" ? "switcher-language__selected" : ""}`}>
            <span className="lang-icon">
              <EN /> EN
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export default LanguageSwitcher;
