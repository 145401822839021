export function getLinkFromTitle(title: string): string {
  if (title.includes("placeme")) {
    return "placeme";
  } else if (title.includes("target")) {
    return "target";
  } else {
    return "source";
  }
}

export function getLinkFromProductName(title: string): string {
  if (title.includes("placeme")) {
    return "placeme";
  } else if (title.includes("target")) {
    return "target";
  } else {
    return "source";
  }
}

export function getColorFromTitle(title: string): string {
  if (title.includes("placeme")) {
    return "#362FB7";
  } else if (title.includes("target")) {
    return "#CC3C00";
  } else if (title.includes("source")) {
    return "#6830B2";
  } else {
    return "#362FB7";
  }
}

export function getNameFromTagValue(title: string): string {
  if (title.includes("placeme")) {
    return "placeme";
  } else if (title.includes("target")) {
    return "target";
  } else {
    return "source";
  }
}

export const getProductName = (name: string): string => {
  if(name.includes("placeme")){
    return "Placeme"
  } else if (name.includes("target")){
    return "Target"
  } else if (name.includes("source")){
    return "Source"
  }
  return ""
}