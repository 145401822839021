import { configureStore, Action } from "@reduxjs/toolkit";
import { ThunkAction } from "redux-thunk";
import reducers from "utils/store/reducers";

const store = configureStore({
  reducer: reducers,
});

export type RootState = ReturnType<typeof reducers>;
export type Dispatch = typeof store.dispatch;
export type Thunk = ThunkAction<void, RootState, null, Action<string>>;

export default store;
