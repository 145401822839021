import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import RecruitmentService from "features/career/careerService";
import { Maybe } from "types/declarations";
import { RootState } from "utils/store/store";
import { GetRecruitmentPosts, RecruitmentPost } from "./careerModel";

export interface RecruitmentPostState {
  allRecruitmentPosts: Array<RecruitmentPost>;
  recruitmentPost?: Maybe<RecruitmentPost>;
}

const initialState = {
  allRecruitmentPosts: []
} as RecruitmentPostState;


export const getRecruitmentPost = createAsyncThunk(
  "recruitment/getRecruitmentPostsByLink",
  async (props: GetRecruitmentPosts, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const posts = state.recruitment.allRecruitmentPosts;
    return RecruitmentService.getRecruitmentPost({
      ...props,
      allRecruitmentPosts: posts as Array<RecruitmentPost>,
    });
  }
);

const careerSlice = createSlice({
  name: "recruitment",
  initialState,
  reducers: {
    setAllRecruitmentPosts(state, action: PayloadAction<Array<RecruitmentPost>>) {
      state.allRecruitmentPosts = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getRecruitmentPost.fulfilled, (state, action) => {
      state.recruitmentPost = action.payload;
    });
  },
});

export const {
  setAllRecruitmentPosts
} = careerSlice.actions;
export default careerSlice.reducer;
