import * as React from 'react';
import FooterBox from 'features/footer/footer-box/FooterBox';
import styled, { css } from 'styled-components';
import { useMediaQuery } from 'utils/hooks/useMediaQuery';

const FooterGridDiv = styled.div(() => {
  const { tablet } = useMediaQuery()

  return css`
    display: flex;
    flex-wrap: ${tablet ? 'nowrap' : 'wrap'};
    gap: 1rem;
  `;
});

export interface FooterGridBox {
  isBig: boolean;
}

function FooterGridBox({ isBig }: FooterGridBox) {
  return (
    <FooterGridDiv>
      <FooterBox
        isBig={isBig}
        title='placeme'
        href='/placeme'
        bgColor='#362FB7'
        iconLocation
      />
      <FooterBox
        isBig={isBig}
        title='source'
        href='/source'
        bgColor='#6830B2'
        iconData
      />
      <FooterBox
        isBig={isBig}
        title='target'
        href='/target'
        bgColor='#CC3C00'
        iconNavigator
      />
    </FooterGridDiv>
  );
}

export default FooterGridBox;
