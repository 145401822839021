import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { languages } from 'shared/constants'
import { RootState } from 'utils/store/store'

function useLanguage() {
  const { language: i18nlang } = useSelector((state: RootState) => state.i18n)

  const language = useMemo(() => {
    if (i18nlang === languages.en) return "en" // TODO: remove after 'en-pl' is changed to 'en'
    return i18nlang
  }, [i18nlang])

  return { language }
}

export default useLanguage
