import * as React from "react";
import styled from 'styled-components';
import "./ProductPurposes.scss";
import { Link } from "gatsby";
import BtnArrowRight from "assets/arrowbtn.svg";
import Tag from 'ui/Tag/Tag'
import {
  Maybe,
  PrismicProductHomeDataUsageExample,
} from "types/declarations";
import { FC } from "react";
import { RichText } from "prismic-reactjs";
import { useSelector } from "react-redux";
import { RootState } from "utils/store/store";

const TagsWrapper = styled.div`
  display:flex;
  align-items:center;
  width:100%;
  justify-content: flex-start;
  margin-bottom: 35px;
  flex-wrap: wrap;

  div{
    margin-right: 8px;
  }

  @media (max-width: 450px){
    justify-content: center;
    margin-bottom: 20px;
    div{
      margin-top: 5px;
    }
  }
`

export interface ProductPurposesItemProps {
  item: Maybe<PrismicProductHomeDataUsageExample>;
}

const ProductPurposesItem: FC<ProductPurposesItemProps> = ({
  item,
}: ProductPurposesItemProps) => {
  const tags = RichText.asText(item?.example_tags?.raw || []).split(";");
  const { language } = useSelector((state: RootState) => state.i18n);
  
  return (
    <div className="product-purposes__inner--item">
      <div className="left">
        <div>
          <img src={item?.example_brand_image?.url || ''} alt="brand" />
          <div className='head'><RichText render={item?.header?.raw || []} /></div>
          <div className='description'><RichText render={item?.description?.raw || []}/></div>
        </div>
      </div>
      <div className="right">
        {tags && <TagsWrapper>
          {tags.map((tag,index)=> tag.length > 0 && <Tag key={index} textTransform='uppercase' borderColor='#EFEAEB' color='#FBF7F4' fontColor='#002163' fontSize='12px' fontWeight='600'>{tag}</Tag>)}
        </TagsWrapper>
        }
          <div className='description'><RichText render={item?.example_header?.raw || []}/></div>
        <>
        <div className='description_second'><RichText render={item?.example_brand_text?.raw || []}/></div>
        </>
        <Link to={'/' + language + RichText.asText(item?.example_link?.raw || []) + '/'}>
          {RichText.asText(item?.example_button?.raw || [])} <BtnArrowRight />
        </Link>
      </div>
    </div>
  );
};

export default ProductPurposesItem;
