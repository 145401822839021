import * as React from "react";
import "./SuccessStoriesLayout.scss";
import { FC } from "react";
import Footer from "features/footer/Footer";
import RootContainer from "containers/RootContainer";
import Header from "components/shared-components/header/Header";

export interface SuccessStoriesLayoutProps {
  children: React.ReactNode;
}

const SuccessStoriesLayout: FC<SuccessStoriesLayoutProps> = ({
  children,
}: SuccessStoriesLayoutProps) => {
  return (
    <RootContainer>
      <Header />
      <main className="main-success-stories">{children}</main>
      <Footer />
    </RootContainer>
  );
};

export default SuccessStoriesLayout;
